import React, { useState, useRef } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import './ResetPassword.css'; // Optionally, style your reset password page

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const recaptchaRef = useRef(null); // Reference for reCAPTCHA
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    // Validate email format
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Execute reCAPTCHA and get token
    const recaptchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    if (!recaptchaToken) {
      setError('ReCAPTCHA verification failed. Please try again.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('If an account exists with this email, a password reset email has been sent.');
      setError(''); // Clear any previous error
    } catch (error) {
      setError('If an account exists with this email, a password reset email has been sent.');
      setMessage(''); // Clear any previous message
    }
  };

  return (
    <div className="reset-password-container">
      <h1>Reset Password</h1>
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleResetPassword}>
        <div className="input-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email address"
            maxLength="70" // Character limit for the email input field
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
        <ReCAPTCHA
          sitekey="6Lfr8W0qAAAAABQY145UoWluq1Ap6kr0jOXxQfWz"
          size="compact"
          ref={recaptchaRef}
          style={{ transform: 'scale(0.85)', transformOrigin: '0 0' }}
        />
      </div>
        <button type="submit" className="reset-button">Send Reset Email</button>
      </form>
    </div>
  );
};

export default ResetPassword;
