import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const SocialEngineeringQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "What is social engineering?",
      options: [
        "A method to secure data",
        "A manipulation tactic used to deceive individuals",
        "A type of firewall",
        "A tool for managing social media"
      ],
      correctAnswer: "A manipulation tactic used to deceive individuals"
    },
    {
      question: "Which of the following is an example of pretexting?",
      options: [
        "Offering free software in exchange for information",
        "Creating a fake scenario to obtain information",
        "Following someone into a secure building",
        "Asking for login credentials in a phishing email"
      ],
      correctAnswer: "Creating a fake scenario to obtain information"
    },
    {
      question: "What should you be wary of to identify social engineering attempts?",
      options: [
        "Unsolicited requests for sensitive information",
        "Regular updates from your service provider",
        "Scheduled maintenance notifications",
        "Friendly advice from a colleague"
      ],
      correctAnswer: "Unsolicited requests for sensitive information"
    },
    {
      question: "How does tailgating work as a social engineering tactic?",
      options: [
        "Sending a fake invoice to gain payment information",
        "Offering something enticing in exchange for access",
        "Gaining physical access by following authorised personnel",
        "Pretending to be a trusted colleague over the phone"
      ],
      correctAnswer: "Gaining physical access by following authorised personnel"
    },
    {
      question: "What is the purpose of baiting in social engineering?",
      options: [
        "To physically follow someone into a secure area",
        "To create a fabricated scenario for obtaining information",
        "To offer something enticing to gain access to sensitive information",
        "To request verification codes sent via SMS"
      ],
      correctAnswer: "To offer something enticing to gain access to sensitive information"
    },
    {
      question: "Which of these is a key sign of a social engineering attack?",
      options: [
        "A request for information from a trusted source",
        "Receiving a reward for participating in a survey",
        "Someone trying to gain your trust too quickly",
        "A familiar vendor offering a discount"
      ],
      correctAnswer: "Someone trying to gain your trust too quickly"
    },
    {
      question: "How can you avoid falling victim to social engineering?",
      options: [
        "Share personal information only with known contacts",
        "Verify the identity of the requester before providing information",
        "Respond quickly to urgent requests",
        "Trust all requests that appear to come from a manager"
      ],
      correctAnswer: "Verify the identity of the requester before providing information"
    },
    {
      question: "What should you do if you encounter a suspicious request?",
      options: [
        "Provide the requested information",
        "Report it to the security team",
        "Ignore the request",
        "Share the request with your colleagues"
      ],
      correctAnswer: "Report it to the security team"
    },
    {
      question: "Which of the following describes quid pro quo?",
      options: [
        "Creating a fake scenario to gain information",
        "Offering something in return for information",
        "Following someone into a restricted area",
        "Impersonating a senior executive"
      ],
      correctAnswer: "Offering something in return for information"
    },
    {
      question: "Why is it important to recognise unusual or inappropriate questions?",
      options: [
        "They could be a normal part of business operations",
        "They might be signs of a social engineering attempt",
        "They help build trust with new contacts",
        "They can be ignored without consequence"
      ],
      correctAnswer: "They might be signs of a social engineering attempt"
    }
  ];


  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 7) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module2_quiz3': true, // Update Firestore to mark the quiz of module 2 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/moduletwo/insider-threats'); // Replace this with the actual link when available
  };

 

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Recognising Social Engineering Attempts Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              {score >= 7 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of recognising social engineering attempts.</p>
              ) : (
                <p>You need to score at least 7 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 7 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default SocialEngineeringQuiz;
