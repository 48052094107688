import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, deleteDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { firestore } from '../firebase';
import './SettingsStandard.css';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';

// Sanitize input by escaping special characters
const sanitizeInput = (input) => {
  return input.replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/\"/g, "&quot;")
              .replace(/'/g, "&#039;");
};

const SettingsStandard = () => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [linkRequests, setLinkRequests] = useState([]);
  const [managerName, setManagerName] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser({ uid: user.uid, ...userData });
          setLinkRequests(userData.linkRequests || []);

          // If user is linked to a manager, fetch the manager's details
          if (userData.profile?.managerId) {
            const managerDocRef = doc(firestore, 'users', userData.profile.managerId);
            const managerDocSnap = await getDoc(managerDocRef);
            if (managerDocSnap.exists()) {
              setManagerName(managerDocSnap.data().profile?.name || 'Unknown');
            }
          }
        }
      }
      setLoading(false);
    };
    fetchUserData();
  }, [auth]);

  const handleDeleteAccount = async () => {
    try {
      const userDocRef = doc(firestore, 'users', user.uid);
      await deleteDoc(userDocRef);
      await auth.currentUser.delete();
      alert('Account deleted successfully.');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  // Accept a manager link request
  const acceptLinkRequest = async (request) => {
    try {
      await updateDoc(doc(firestore, 'users', user.uid), {
        'profile.managerId': request.userId,
        linkRequests: arrayRemove(request),
      });

      setLinkRequests((prevRequests) =>
        prevRequests.filter((r) => r.userId !== request.userId)
      );

      alert('Link request accepted.');
    } catch (error) {
      console.error('Error accepting link request:', error);
      alert('Error accepting link request.');
    }
  };

  // Reject a manager link request
  const rejectLinkRequest = async (request) => {
    try {
      await updateDoc(doc(firestore, 'users', user.uid), {
        linkRequests: arrayRemove(request),
      });

      setLinkRequests((prevRequests) =>
        prevRequests.filter((r) => r.userId !== request.userId)
      );

      alert('Link request rejected.');
    } catch (error) {
      console.error('Error rejecting link request:', error);
      alert('Error rejecting link request.');
    }
  };

  // Conditionally render the "Report Threat" button for standard users and admins
  const renderReportThreatButton = () => {
    if (user?.profile?.accountType === 'standard' || user?.profile?.accountType === 'admin') {
      return (
        <button
          className="report-threat-button"
          style={{ backgroundColor: 'red', color: 'white' }}
          onClick={() => navigate('/standard-report')}
        >
          Report Threat
        </button>
      );
    }
    return null;
  };

  const handleBackClick = () => {
    navigate('/profile'); // Navigate back to the profile page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="settings-container">
        <button className="back-button-standardSettings" onClick={handleBackClick} style={{ alignSelf: 'flex-start', marginBottom: '1rem' }}>
          ← Back to Profile
        </button>

        <h1 className="settings-title">Settings</h1>
        {user?.profile && (
          <>
            <div className="settings-card">
              <h2 className="settings-subheading">User Details</h2>
              <p className="settings-text"><strong>Name:</strong> {user.profile.name || 'Unknown'}</p>
              <p className="settings-text"><strong>Email:</strong> {user.profile.email || 'Not Available'}</p>
              {managerName && (
                <p className="settings-text"><strong>Linked Manager:</strong> {managerName}</p>
              )}
            </div>

            {renderReportThreatButton()} {/* Render the report threat button conditionally */}

            {/* Section for pending link requests */}
            <div className="settings-card settings-section">
              <h2 className="settings-subheading">Pending Link Requests</h2>
              {linkRequests.length === 0 ? (
                <p className="settings-text">No pending requests.</p>
              ) : (
                linkRequests.map((request) => (
                  <div className="settings-card link-request-card" key={request.userId}>
                    <p><strong>Manager Email:</strong> {request.userEmail}</p>
                    <div className="settings-button-group">
                      <button className="settings-button" onClick={() => acceptLinkRequest(request)}>
                        Accept
                      </button>
                      <button className="settings-danger-button" onClick={() => rejectLinkRequest(request)}>
                        Reject
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="settings-card">
              <h2 className="settings-subheading">Delete Account</h2>
              <button className="settings-danger-button" onClick={handleDeleteAccount}>Delete Account</button>
            </div>

            <div className="settings-card">
              <h2 className="settings-subheading">Download Your Data</h2>
              <p className="settings-text">If you would like to request a copy of your personal data, please email <a href="mailto:contact@becyberaware.co.uk">contact@becyberaware.co.uk</a>.</p>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SettingsStandard;
