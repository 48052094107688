import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported
import './IntroductionPartTwo.css'; // Ensure the CSS file is imported

const IntroductionPartTwo = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false); // Hide the warning once the video ends
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleStartClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module2/what-is-cyber-security');
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="introduction-container-intro2">
      <section className="story-introduction">
        <h2>The Cybersecurity Detective</h2>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add logo overlay component */}
          <video
            ref={videoRef}
            src="/assets/videos/IntroductionModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture
            playsInline
          >
            <track
              src="/assets/videos/Introduction.vtt"
              kind="captions"
              srcLang="en"
              label="English"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="start-button-container-2">
          <button className="start-button-course2" onClick={handleStartClick}>
            Start
          </button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>
      
    </div>
  );
};

export default IntroductionPartTwo;
