import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './EmailSecurity.css'; // Ensure this file exists

const WhatIsCyberSecurityQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(8).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(8).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(8).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is the primary goal of cybersecurity?",
      options: [
        "To protect systems, networks, and programs from digital attacks",
        "To develop new software applications",
        "To increase internet speeds in order to download faster"
      ],
      correctAnswer: "To protect systems, networks, and programs from digital attacks"
    },
    {
      question: "What does cybersecurity encompass?",
      options: [
        "Only antivirus software",
        "A range of technologies, processes, and practices",
        "Just network security as this is most important"
      ],
      correctAnswer: "A range of technologies, processes, and practices"
    },
    {
      question: "Which of the following best describes the layers of protection in cybersecurity?",
      options: [
        "They are designed to safeguard only financial transactions.",
        "They focus on protecting computers, networks, programs, and data.",
        "They are used solely for physical security."
      ],
      correctAnswer: "They focus on protecting computers, networks, programs, and data."
    },
    {
      question: "Cybersecurity aims to safeguard computers, servers, and networks from what?",
      options: [
        "Unauthorised access and malicious attacks",
        "Physical damage to hardware caused by malicious activity",
        "Software updates"
      ],
      correctAnswer: "Unauthorised access and malicious attacks"
    },
    {
      question: "What are the layers of protection in cybersecurity designed to protect?",
      options: [
        "Only financial transactions as these are vital",
        "Computers, networks, programs, or data",
        "Social media profiles"
      ],
      correctAnswer: "Computers, networks, programs, or data"
    },
    {
      question: "What must complement one another to create an effective defense from cyber attacks in an organisation?",
      options: [
        "People, processes, and technology",
        "Marketing, sales, and IT",
        "Hardware, software, and furniture"
      ],
      correctAnswer: "People, processes, and technology"
    },
    {
      question: "What is cybersecurity designed to prevent?",
      options: [
        "Physical damage to servers from intruders",
        "Malicious attacks, damage, or unauthorised access",
        "Network upgrades"
      ],
      correctAnswer: "Malicious attacks, damage, or unauthorised access"
    },
    {
      question: "Which of the following best describes cybersecurity?",
      options: [
        "A one-time setup of antivirus software",
        "An ongoing practice of protecting systems and data",
        "A process limited to IT professionals"
      ],
      correctAnswer: "An ongoing practice of protecting systems and data"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (newScore >= 6 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course2.module1': true // Update Firestore to mark module1 as completed for course2
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(8).fill(null));
    setFeedback(Array(8).fill(null));
    setCorrectAnswers(Array(8).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/what-is-cyber-security');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Cybersecurity Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex].question}</p>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label>
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      {option}
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 8</p>
              {score >= 6 ? (
                <p>Congratulations! You passed the quiz.</p>
              ) : (
                <p>You need to score at least 6 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 6 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button className="back-button" onClick={goBack}>Exit Quiz</button>
        </div>
      </section>
    </div>
  );
};

export default WhatIsCyberSecurityQuiz;
