import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthProvider'; // Ensure this is correct

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId="390466569984-u88p23ihtua5n5h6j6hmbtu69nv9al97.apps.googleusercontent.com">
    <BrowserRouter>
      <AuthProvider> {/* Make sure AuthProvider is correctly wrapping */}
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AuthProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);
