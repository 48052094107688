import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './CybersecurityBasics.css';


const CyberSecurityQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(2).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [feedback, setFeedback] = useState(Array(2).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(2).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is Cybersecurity?",
      options: [
        "Developing software for various purposes",
        "Designing and creating websites",
        "Protecting systems and networks from attacks"
      ],
      correctAnswer: "Protecting systems and networks from attacks"
    },
    {
      question: "Why is Cybersecurity Important?",
      options: [
        "To protect sensitive information",
        "To design better websites",
        "To develop new software"
      ],
      correctAnswer: "To protect sensitive information"
    }
  ];

  // Scroll to top after component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []); // Empty dependency array to ensure this runs once on mount

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (answers[0] === questions[0].correctAnswer && answers[1] === questions[1].correctAnswer && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module1': true // Update Firestore to mark module1 as completed for course1
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(2).fill(null));
    setFeedback(Array(2).fill(null));
    setCorrectAnswers(Array(2).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page3/recognizing-threats');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page2/cybersecurity-basics');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    
    <div className="cybersecurity-basics-container">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
  <br></br>
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Cybersecurity Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex].question}</p>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              {answers[0] === questions[0].correctAnswer && answers[1] === questions[1].correctAnswer ? (
                <div>
                  <p>Congratulations! You answered both questions correctly.</p>
                  <button className="next-button" onClick={goToNext}>Next</button>
                </div>
              ) : (
                <div>
                  <p>You need to answer both questions correctly to proceed. Please retake the quiz.</p>
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                </div>
              )}
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default CyberSecurityQuiz;
