import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Ensure this file exists

const PhishingQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is phishing?",
      options: [
        "Malware that affects computers",
        "A scam to steal personal details",
        "A basic software update",
        "A type of network setup"
      ],
      correctAnswer: "A scam to steal personal details"
    },
    {
      question: "Which of the following can be a common characteristic of a phishing email?",
      options: [
        "May Contain spelling and grammar mistakes",
        "Is sent from your own email address",
        "Is a newsletter you subscribed to",
        "Is sent from a colleague you know"
      ],
      correctAnswer: "May Contain spelling and grammar mistakes"
    },
    {
      question: "What should you do if you receive an email asking for personal information?",
    options: [
      "Reply directly with the details",
      "Click on the link to respond",
      "Verify the sender before acting",
      "Forward the email to others"
    ],
    correctAnswer: "Verify the sender before acting"
    },
    {
      question: "Which of the following is a sign that an email might be a phishing attempt?",
      options: [
        "The email is from a known sender",
        "The email requests urgent action",
        "The email is about an upcoming event you know about",
        "The email contains a company logo"
      ],
      correctAnswer: "The email requests urgent action"
    },
    {
      question: "How can you protect yourself from phishing attacks?",
    options: [
      "Ignore all your emails completely",
      "Open emails only from trusted contacts",
      "Use filters and stay cautious with emails",
      "Respond quickly to every email"
    ],
    correctAnswer: "Use filters and stay cautious with emails"
    },
    {
      question: "What is the best action to take if you suspect an email is phishing?",
      options: [
        "Reply to the email asking for more information",
        "Click the links to check their validity",
        "Delete the email without clicking any links or replying",
        "Forward the email to everyone in your contact list"
      ],
      correctAnswer: "Delete the email without clicking any links or replying"
    },
    {
      question: "What kind of information do phishing scams typically seek?",
      options: [
        "Your favorite color or movie",
        "Usernames and passwords",
        "The name of your pet or friend",
        "Your last vacation destination"
      ],
      correctAnswer: "Usernames and passwords"
    },
    {
      question: "How can you verify the authenticity of an email from a financial institution?",
      options: [
        "Click on the link in the email to check the website",
        "Contact institution directly using official number or website",
        "Reply to the email asking for verification",
        "Ignore the email"
      ],
      correctAnswer: "Contact institution directly using official number or website"
    },
    {
      question: "What should you do if you have provided personal information in response to a phishing email?",
      options: [
        "Ignore the email and move on",
        "Change password and monitor account, ensure 2FA is activated",
        "message them back asking to delete your personal information",
        "Share this incident on social media"
      ],
      correctAnswer: "Change password and monitor account, ensure 2FA is activated"
    },
    {
      question: "Why is it important to report phishing emails?",
    options: [
      "To get a reward for reporting it",
      "To assist authorities in stopping scammers",
      "To let your friends know about scams",
      "To increase overall email traffic"
    ],
    correctAnswer: "To assist authorities in stopping scammers"
    }
  ];

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (newScore >= 7 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course2.module3_quiz2': true // Update Firestore to mark quiz2 of module3 in course2 as completed
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module3/ransomware');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/phishing');
  };

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Phishing Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{questions[currentQuestionIndex].question}</p>
                {questions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < questions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              {score >= 7 ? (
                <p>Congratulations! You passed the quiz and successfully learnt about phishing attacks.</p>
              ) : (
                <p>You need to score at least 7 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 7 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button className="back-button" onClick={goBack}>Exit Quiz</button>
        </div>
      </section>
    </div>
  );
};

export default PhishingQuiz;
