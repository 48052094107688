import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './SidebarFour.css'; // Import SidebarFour styles

// SidebarFour component
const SidebarFour = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility on small screens
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to handle link click and hide the sidebar on small screens
  const handleLinkClick = () => {
    if (window.innerWidth < 769) {
      setSidebarVisible(false); // Hide sidebar when a link is clicked on small screens
    }
  };

  // Ensure sidebar is always visible on larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        setSidebarVisible(true); // Always show the sidebar on larger screens
      } else {
        setSidebarVisible(false); // Hide sidebar initially on smaller screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Sidebar Toggle Button (only visible on small screens) */}
      <div className="sidebar4-toggle-btn" onClick={toggleSidebar}>
        Contents
      </div>

      {/* Sidebar (conditionally apply show/hide class based on screen size) */}
      <div className={`sidebar4 ${sidebarVisible ? 'show' : 'sidebar4-hidden'}`}>
        <ul className="sidebar4-ul">
          <li className="sidebar4-li">
            <NavLink 
              to="/managersportal"
              className={({ isActive }) => isActive ? 'active4' : ''}
              onClick={handleLinkClick}
            >
              Managers Portal
            </NavLink>
          </li>
          <li className="sidebar4-li">
            <NavLink 
              to="/progress"
              className={({ isActive }) => isActive ? 'active4' : ''}
              onClick={handleLinkClick}
            >
              Employee Progress
            </NavLink>
          </li>
          <li className="sidebar4-li">
            <NavLink 
              to="/add-report"
              className={({ isActive }) => isActive ? 'active4' : ''}
              onClick={handleLinkClick}
            >
              Report a Threat
            </NavLink>
          </li>
          <li className="sidebar4-li">
            <NavLink 
              to="/threat-reports"
              className={({ isActive }) => isActive ? 'active4' : ''}
              onClick={handleLinkClick}
            >
              Threat Reports
            </NavLink>
          </li>
          <li className="sidebar4-li">
            <NavLink 
              to="/threat-stats"
              className={({ isActive }) => isActive ? 'active4' : ''}
              onClick={handleLinkClick}
            >
              Statistics
            </NavLink>
          </li>
          <li className="sidebar4-li">
            <NavLink 
              to="/settings"
              className={({ isActive }) => isActive ? 'active4' : ''}
              onClick={handleLinkClick}
            >
              Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarFour;
