import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Introduction.css';

import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';


const Introduction = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goBack = () => {
    navigate('/');
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
    setProgress(100);
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourse/page2/cybersecurity-basics#video-container');
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    
    <div className="introduction-container2">
      <section className="story-introduction">
        <div className="BasicsTitle">The Cybersecurity Detective</div>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/Introduction.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="start-button-container-intro">
          <button className="start-button-intro1" onClick={handleNextClick}>
            Start
          </button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>
     
    </div>
    
  
  );
};

export default Introduction;
