import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Ensure this file exists

const MalwareQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is malware?",
      options: [
        "A type of hardware",
        "Malicious software",
        "A computer game",
        "An operating system"
      ],
      correctAnswer: "Malicious software"
    },
    {
      question: "Which of the following is a common way malware can infect a computer?",
      options: [
        "Through installing new hardware",
        "Via phishing emails with malicious attachments or links",
        "By downloading software updates",
        "By turning off the computer"
      ],
      correctAnswer: "Via phishing emails with malicious attachments or links"
    },
    {
      question: "What is a key sign that a computer might be infected with malware?",
      options: [
        "Faster performance",
        "Normal operation",
        "Strange pop-ups and slow performance",
        "Increased battery life"
      ],
      correctAnswer: "Strange pop-ups and slow performance"
    },
    {
      question: "What should you do first if you suspect a computer is infected with malware?",
      options: [
        "Install more software",
        "Disconnect from the internet",
        "Print documents",
        "Restart the computer repeatedly"
      ],
      correctAnswer: "Disconnect from the internet"
    },
    {
      question: "Which of the following tools can be used to remove malware?",
      options: [
        "Microsoft Word",
        "Adobe Photoshop",
        "Anti-Virus Software",
        "Google Chrome"
      ],
      correctAnswer: "Anti-Virus Software"
    },
    {
      question: "Why is it important to enter Safe Mode when removing malware?",
      options: [
        "To enhance the overall display and graphics settings",
        "To limit the programs and services running, helping to prevent malware from launching",
        "To improve your internet speed and connectivity",
        "To enable the installation and setup of software applications"
      ],
      correctAnswer: "To limit the programs and services running, helping to prevent malware from launching"
      
    },
    {
      question: "What is the purpose of running a full system scan with antivirus software?",
      options: [
        "To clean up temporary files",
        "To detect and remove malware",
        "To improve internet speed",
        "To install new features"
      ],
      correctAnswer: "To detect and remove malware"
    },
    {
      question: "Which of the following should be avoided to prevent malware infections?",
      options: [
        "Updating your operating system",
        "Downloading music from untrustworthy websites",
        "Using antivirus software",
        "Backing up important files"
      ],
      correctAnswer: "Downloading music from untrustworthy websites"
    },
    {
      question: "After removing malware, what is a recommended practice?",
      options: [
        "Ignore future updates",
        "Change passwords for important accounts",
        "Uninstall all software",
        "Delete all emails"
      ],
      correctAnswer: "Change passwords for important accounts"
    },
    {
      question: "Why should you avoid downloading songs, movies or software from unofficial websites?",
options: [
  "There is no reason not to, It is free saving you money",
  "It may contain malware",
  "The quality is not as good"
  
],
correctAnswer: "It may contain malware"

    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (newScore >= 7 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course2.module3_quiz1': true // Update Firestore to mark quiz1 of module3 in course2 as completed
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module3/phishing');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Cybersecurity Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex].question}</p>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              {score >= 7 ? (
                <p>Congratulations! You passed the quiz and successfully learnt about malware.</p>
              ) : (
                <p>You need to score at least 7 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 7 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button className="back-button" onClick={goBack}>Exit Quiz</button>
        </div>
      </section>
    </div>
  );
};

export default MalwareQuiz;
