import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import safeurl from '../../assets/safeurl.png';
import '../Page3/RecognizingThreats.css'; // Adjusted path


const SafeBrowsing = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page5/safe-browsing-part-two'); // Navigate to the next page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth-part-two'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <div className="BasicsTitle">Safe Browsing</div>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/PageFive.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture // Disable Picture-in-Picture
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2>Using Secure Connections</h2>
        </section>

        <section style={{ textAlign: 'center', backgroundColor: 'rgb(255, 192, 185)', border: '2px solid black', padding: '1rem', borderRadius: '8px' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={safeurl} alt="Safe URL example" className="thumbnail" />
          </div>
        </section>
        <br />

        <div className="bordered-container">
          <p>When online, look for <strong><span style={{ color: 'salmon' }}>"HTTPS"</span></strong> in the website's address like shown in the example above. The <strong><span style={{ color: 'salmon' }}>"S"</span></strong> stands for <strong><span style={{ color: 'salmon' }}>secure</span></strong> and helps protect your data from hackers.</p>
          <p><strong><span style={{ color: 'salmon' }}>Tip:</span></strong> Check for the padlock icon next to the URL. It shows that your connection is encrypted and secure.</p>
          <p><strong><span style={{ color: 'salmon' }}>However</span></strong> HTTPS alone doesn't guarantee safety. Scammers, phishers, and malware sites can also use HTTPS. It's just one part of staying safe online. Always use your best judgment and consider other factors like the website's legitimacy and source.</p>
        </div>

        <div className="button-container">
          <button className="next-button" onClick={goToNext}>Next</button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>

       
      </section>
    </div>
 
  );
};

export default SafeBrowsing;
