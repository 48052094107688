import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Make sure this path points to your Firebase config
import './AdminDashboard.css'; // Make sure you create and style this CSS file

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [courseCompletionCount, setCourseCompletionCount] = useState(0);
  const [recentLogins, setRecentLogins] = useState([]);

  useEffect(() => {
    // Fetch total number of users
    const fetchUserCount = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'users'));
      setUserCount(querySnapshot.size);
    };

    // Fetch active users (assuming activity is tracked by a 'lastLogin' field)
    const fetchActiveUserCount = async () => {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1); // Active users within the last month
      const activeUsersQuery = query(collection(firestore, 'users'), where('lastLogin', '>=', oneMonthAgo));
      const querySnapshot = await getDocs(activeUsersQuery);
      setActiveUserCount(querySnapshot.size);
    };

    // Fetch completed courses (assuming course completions are tracked by a 'progress' field)
    const fetchCourseCompletionCount = async () => {
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      let totalCompleted = 0;
      usersSnapshot.forEach(doc => {
        const progress = doc.data().progress;
        if (progress) {
          // Count completed modules across all courses
          totalCompleted += Object.values(progress).filter(completed => completed === true).length;
        }
      });
      setCourseCompletionCount(totalCompleted);
    };

    // Fetch recent logins (last 7 days)
    const fetchRecentLogins = async () => {
      const recentLoginsQuery = query(
        collection(firestore, 'users'),
        where('lastLogin', '>=', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
      ); // logins from last 7 days
      const querySnapshot = await getDocs(recentLoginsQuery);
      const logins = querySnapshot.docs.map(doc => ({
        name: doc.data().profile.name,
        email: doc.data().profile.email,
        lastLogin: doc.data().lastLogin.toDate(),
      }));
      setRecentLogins(logins);
    };

    fetchUserCount();
    fetchActiveUserCount();
    fetchCourseCompletionCount();
    fetchRecentLogins();
  }, []);

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      
      <div className="dashboard-stats">
        {/* Total Users */}
        <div className="stat-card">
          <h2>Total Users</h2>
          <p>{userCount}</p>
        </div>

        {/* Active Users */}
        <div className="stat-card">
          <h2>Active Users (Last 30 Days)</h2>
          <p>{activeUserCount}</p>
        </div>

        {/* Course Completions */}
        <div className="stat-card">
          <h2>Total Completed Courses</h2>
          <p>{courseCompletionCount}</p>
        </div>
      </div>

      <div className="portal-boxes">
        {/* Manage Users */}
        <div className="portal-box">
          <h2>Manage Users</h2>
          <p>Admin can manage user accounts here. You can add, remove, or update user roles.</p>
          <Link to="/admindashboard/manage-users">
            <button className="portal-button">Go to Manage Users</button>
          </Link>
        </div>

        {/* Grouped Users */}
        <div className="portal-box">
          <h2>Grouped Users</h2>
          <p>Managers and linked employee groups</p>
          <Link to="/admindashboard/view-groups">
            <button className="portal-button">View Groups</button>
          </Link>
        </div>
      </div>

      {/* Recent Logins */}
      <div className="recent-logins">
        <h2>Recent Logins (Last 7 Days)</h2>
        <ul>
          {recentLogins.length > 0 ? (
            recentLogins.map((login, index) => (
              <li key={index}>
                {login.name} ({login.email}) - Last login: {login.lastLogin.toLocaleString()}
              </li>
            ))
          ) : (
            <li>No recent logins available.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AdminDashboard;
