import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const Intro = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false);   // Hide the warning
    setProgress(100);        // Set progress to 100%
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    // Prevent skipping ahead in the video
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }

    // Update progress bar
    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);  // Show warning if video is not fully watched
    } else {
      navigate('/businessacademy/moduleone/what-is-cybersecurity-two'); // Navigate to the next page
    }
  };

  const goBack = () => {
    navigate('/'); // Navigate to the previous page
  };

  return (
    <div className="cybersecurity-basics-container">
      <section className="module-section">
        <div className="BasicsTitle">Business Academy</div>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/IntroBusiness.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="start-button-container-intro">
          <button className="start-button-intro1" onClick={handleNextClick}>
            Next
          </button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

     
    </div>
  );
};

export default Intro;
