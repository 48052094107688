import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FinishersPageOne.css'; // Import the updated CSS file
import Confetti from 'react-confetti';

const FinisherPage = () => {
  const navigate = useNavigate();
  const [recycleConfetti, setRecycleConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRecycleConfetti(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="FinishersPageOne-introduction-container">
      <Confetti 
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={recycleConfetti}
        numberOfPieces={2000}
      />
      <section className="FinishersPageOne-story-introduction">
  <br />
  <h2>🎉 Congratulations, You Completed CyberShield Basics! 🎉</h2>
  <div className="FinishersPageOne-introduction-content">
    <div className="FinishersPageOne-introduction-text">
      <p>
        You've successfully empowered yourself and the residents of Brambleton with vital cyber security knowledge, making the community more resilient to cyber attacks. 
        <br /><br />
        But the journey doesn't end here! There's still much more to learn and share. Ready to continue? Move on to the next course CyberShield Intermediate and deepen your expertise! 
      </p>
      <button className="FinishersPageOne-start-button-home" onClick={() => window.location.href = '/'}>
        🏠 Home
      </button>
    </div>
  </div>
</section>

    </div>
  );
};

export default FinisherPage;
