import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported
import './WhatIsCyberSecurity.css'; // Updated path for the CSS file

const WhatIsCyberSecurity = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning once the video ends
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleQuizClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module2/what-is-cyber-security-quiz'); // Navigate to the quiz page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/introduction/introduction-part-two'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <h2>What is Cybersecurity</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add logo overlay component */}
          <video
            ref={videoRef}
            src="/assets/videos/ModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>What is Cybersecurity?</h3>
            <p>Cybersecurity is the practice of protecting systems, networks, and programs from digital attacks. It encompasses a range of technologies, processes, and practices designed to safeguard computers, servers, mobile devices, electronic systems, networks, and data from malicious attacks, damage, or unauthorized access.</p>
            <p>Cybersecurity involves various layers of protection spread across computers, networks, programs, or data that one intends to keep safe. For an organisation, the people, processes, and technology must all complement one another to create an effective defense from cyber attacks.</p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button" onClick={handleQuizClick}>
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

      
    </div>
  );
};

export default WhatIsCyberSecurity;
