import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const HomeNetwork = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module5/home-network-quiz'); // Navigate to the quiz page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module4/poster'); // Replace with the actual previous page route
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Protect Your Home Network</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add logo overlay */}
              <video
                ref={videoRef}
                src="/assets/videos/HomeNetwork.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <p>
              When Jason set up his wireless router, he overlooked changing the default settings, including the SSID (wireless network name) and the network password. This oversight presents a considerable security risk, particularly because the router's admin page is still accessible using the default credentials, "admin/password." While many routers conveniently have these default details printed on the back of the device, it also means that anyone with physical access to the router can easily gain access to the network. This is precisely how his neighbour’s son, who often visited during social events, managed to connect to Jason's network. Fortunately, this wasn't an attack by Mayhem, which could have led to far more serious consequences.
            </p>
          </div>

          <div className="cybersecurity-basics-header">
            <h2>Securing the Network</h2>
          </div>

          <div className="bordered-container alt-color">
            <p>
              On the router's admin page, you can configure the router's settings. You should change your Wi-Fi credentials for your router and admin page: Update your passwords to something strong and unique. Ensure you reconnect all your legitimate devices with the new password. It's also advisable to change the SSID from the default name, as it can reveal the brand of the router, providing attackers with valuable information.
            </p>
          </div>

          <div className="bordered-container">
            <p>
              On the admin page, you can review the devices currently connected to the network. Jason identifies his PC and mobile phone, but there are several unrecognised devices, likely old gadgets or unauthorised users, such as neighbours. You promptly disconnect all suspicious devices, securing Jason's Wi-Fi.
            </p>
          </div>

          <div className="bordered-container alt-color">
            <p>
              Enable Network Encryption: Ensure your network is using WPA3 or WPA2 encryption for added security. These types of encryption provide a higher level of security, protecting your network from unauthorised access and ensuring that data transmitted over the network is secure. If you have replaced your Wi-Fi box within the last few years, it should have this encryption by default. You can easily check what level of encryption your router has on the router's admin page.
            </p>
          </div>

          <div className="bordered-container">
            <p>
              Wi-Fi Protected Setup (WPS) is a feature that simplifies the process of connecting devices to your Wi-Fi network by using a PIN or a push-button method. While convenient, WPS can allow users who have access to your router box easy access. You can disable this feature if necessary on the router's admin page.
            </p>
          </div>

          <div className="bordered-container alt-color">
            <p>
              Update Router Firmware: Ensure your router's firmware is up to date to protect against known vulnerabilities. This is usually done automatically, but you can check your latest firmware update on the router's admin page, ensuring it is the latest one.
            </p>
          </div>

          <div className="bordered-container">
            <p>
              Setting up a guest network on your router provides a separate, secure connection for visitors, keeping your main network and personal devices protected.
            </p>
          </div>

          <div className="bordered-container alt-color">
            <p>
              Make sure your router is from a reputable brand and ensure it is no more than four years old. This ensures it receives the latest security updates as old routers may stop receiving security updates leaving you vulnerable.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Start Quiz</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

     
    </div>
  );
};

export default HomeNetwork;
