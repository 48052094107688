import { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, query, orderBy, updateDoc, arrayRemove } from 'firebase/firestore';
import { firestore } from '../../firebase';  // Ensure correct path to your firebase config
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './ManageUsers.css'; // Ensure correct path to your CSS

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Initialize navigate for back button

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'users'));
        const usersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data().profile,
          createdAt: doc.data().profile?.createdAt || new Date(),
          managerId: doc.data().profile?.managerId || null, // Add managerId to track the relationship
        }));
        setUsers(usersList);
        setFilteredUsers(usersList); // Initialize filteredUsers with the full list
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setFilteredUsers(users.filter(user => user.email.toLowerCase().includes(searchTerm)));
  };

  // Function to handle user deletion
  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteDoc(doc(firestore, 'users', userId));

        // If linked to a manager, remove the user from the manager's employee array
        const user = users.find(u => u.id === userId);
        if (user && user.managerId) {
          const managerDocRef = doc(firestore, 'users', user.managerId);
          await updateDoc(managerDocRef, {
            employees: arrayRemove(userId), // Remove the user ID from the manager's employees list
          });
        }

        // Update UI after deletion
        setFilteredUsers(filteredUsers.filter(user => user.id !== userId));
        alert('User deleted successfully.');
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user.');
      }
    }
  };

  // Navigate back to the admin dashboard
  const handleBackClick = () => {
    navigate('/admindashboard');
  };

  return (
    <div className="manage-users-container">
      {/* Back button at the top-left */}
      <button onClick={handleBackClick} className="back-button-manageusers">Back to Admin Dashboard</button>

      <h1>Manage Users</h1>
      <input
        type="text"
        placeholder="Search by email"
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />

<table className="users-table">
  <thead>
    <tr>
      <th>UID</th>
      <th>Name</th>
      <th>Email</th>
      <th>Account Type</th>
      <th>Date Created</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredUsers.length > 0 ? (
      filteredUsers.map(user => (
        <tr key={user.id}>
          <td data-label="UID">{user.id}</td>
          <td data-label="Name">{user.name}</td>
          <td data-label="Email">{user.email}</td>
          <td data-label="Account Type">{user.accountType}</td>
          <td data-label="Date Created">{new Date(user.createdAt.seconds * 1000).toLocaleDateString()}</td>
          <td data-label="Actions">
            <button
              className="delete-user-button"
              onClick={() => handleDeleteUser(user.id)}
            >
              🗑️
            </button>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="6">No users found</td>
      </tr>
    )}
  </tbody>
</table>

    </div>
  );
};

export default ManageUsers;
