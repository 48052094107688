import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signOut } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA v2
import './signUp.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Constants for character limits
const MAX_NAME_LENGTH = 100;
const MAX_EMAIL_LENGTH = 100;
const MAX_PASSWORD_LENGTH = 50;

// Function to sanitize user input
const sanitizeInput = (input) => {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/`/g, '&#96;'); 
};

// Function to validate password strength
const validatePassword = (password) => {
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return strongPasswordRegex.test(password);
};

const FreeUserSignUpForm = ({ setUser, setProfile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!agreeToTerms) {
      setError('You must agree to the User Agreement and Privacy Policy.');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long, and include one uppercase letter, one lowercase letter, one number, and one special character.');
      return;
    }

    if (!recaptchaToken) {
      setError('Please complete the ReCAPTCHA challenge.');
      return;
    }

    const sanitizedEmail = sanitizeInput(email);
    const sanitizedPassword = sanitizeInput(password);
    const sanitizedName = sanitizeInput(name);

    try {
      // Create the user in Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, sanitizedEmail, sanitizedPassword);
      const user = userCredential.user;

      // Update the display name in Firebase
      await updateProfile(user, { displayName: sanitizedName });

      // Send email verification
      await sendEmailVerification(user);

      // Notify the user to check their email with green text
      setSuccessMessage('A verification email has been sent to your email address. Please verify your account. Please log in after verifying your email.');
      setError(''); // Clear any existing error messages

      // Store user profile in Firestore
      const signUpDate = new Date();
      const userDocRef = doc(firestore, 'users', user.uid);
      await setDoc(userDocRef, {
        userId: user.uid, // Add userId field
        profile: {
          name: user.displayName,
          email: user.email,
          accountType: 'FreeUser',
          createdAt: signUpDate,
        },
        progress: {
          course1: {
            module1: false,
            module2: false,
          },
        },
        agreedToTerms: true, // Store user acceptance of terms
        termsAcceptedAt: signUpDate, // Timestamp for when terms were accepted
        verificationSentAt: signUpDate, // Track when the verification email was sent
        isEmailVerified: false, // Initially set email verification status to false
        employees: [], // Initialize employees array
        linkRequests: [], // Initialize linkRequests array
      });
      

      // Automatically sign out the user after a few seconds
      setTimeout(async () => {
        await signOut(auth);
        navigate('/login');
      }, 5000); // 5-second delay before signing out and redirecting

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('This email address is already in use. Please use a different email address or log in.');
      } else {
        setError('Failed to create an account. Please try again.');
      }
      setSuccessMessage(''); // Clear success message on error
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <h1>Free Account Sign Up</h1>
        <br></br>
        {successMessage && <p className="success-text">{successMessage}</p>}
        {error && <p className="error-text">{error}</p>}
        <form onSubmit={handleSignUp}>
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value.slice(0, MAX_NAME_LENGTH))} // Enforce character limit
              required
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.slice(0, MAX_EMAIL_LENGTH))} // Enforce character limit
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <div className="password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value.slice(0, MAX_PASSWORD_LENGTH))}
                required
              />
              <span onClick={togglePasswordVisibility} className="password-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="input-group-signup">
            <label>
              <input
                type="checkbox"
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              {' '}
              I agree to the <a href="/user-agreement" target="_blank">User Agreement</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
            </label>
          </div>

          {/* ReCAPTCHA v2 Component */}
          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey="6Lfr8W0qAAAAABQY145UoWluq1Ap6kr0jOXxQfWz" // Replace with your actual ReCAPTCHA site key
              onChange={onRecaptchaChange}
            />
          </div>

          <button type="submit" className="login-button" disabled={!agreeToTerms || !recaptchaToken}>
            Sign Up
          </button>
        </form>
      
      </div>
    </div>
  );
};

export default FreeUserSignUpForm;