import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const InsiderThreatsQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "What is an insider threat?",
      options: [
        "A threat from outside the organisation",
        "A threat from someone with inside knowledge",
        "A threat from a competing organisation",
        "A threat from outdated software systems"
      ],
      correctAnswer: "A threat from someone with inside knowledge"
    },
    {
      question: "Which of the following is a type of insider threat?",
      options: [
        "Malicious Insiders",
        "Phishing Attacks",
        "Man-in-the-middle Attacks",
        "Denial of Service"
      ],
      correctAnswer: "Malicious Insiders"
    },
    {
      question: "What are negligent insiders?",
      options: [
        "Employees who intentionally harm the organisation",
        "Employees who unintentionally cause harm through careless actions",
        "Employees who are unaware of security policies",
        "Employees who report suspicious activities"
      ],
      correctAnswer: "Employees who unintentionally cause harm through careless actions"
    },
    {
      question: "What is a sign of a potential insider threat?",
      options: [
        "Excessive downloading or copying of data",
        "Following company protocols strictly",
        "Attending all company meetings",
        "Requesting more training"
      ],
      correctAnswer: "Excessive downloading or copying of data"
    },
    {
      question: "How can compromised insiders be described?",
      options: [
        "Employees whose credentials were stolen and used by attackers",
        "Employees who intentionally cause harm to the organisation",
        "Employees who have recently quit the company",
        "Employees who strictly follow all security protocols"
      ],
      correctAnswer: "Employees whose credentials were stolen and used by attackers"
    },
    {
      question: "Which of the following is NOT a way to prevent insider threats?",
      options: [
        "Implementing strict access controls",
        "Conducting regular security awareness training",
        "Ignoring unusual behaviour",
        "Monitoring network activity for unusual behaviour"
      ],
      correctAnswer: "Ignoring unusual behaviour"
    },
    {
      question: "What should you do if you notice unusual or unauthorised access to sensitive data?",
      options: [
        "Report it immediately to the security team",
        "Ignore it",
        "Discuss it with a colleague",
        "Wait to see if it happens again"
      ],
      correctAnswer: "Report it immediately to the security team"
    },
    {
      question: "Why is the principle of least privilege important in preventing insider threats?",
      options: [
        "It limits access to only what's necessary for job duties",
        "It grants everyone unrestricted access to all data",
        "It simplifies the process of sharing information freely",
        "It eliminates the need for extensive security training"
      ],
      correctAnswer: "It limits access to only what's necessary for job duties"
    },
    {
      question: "What role does regular security awareness training play in preventing insider threats?",
      options: [
        "It educates employees about risks and signs of insider threats",
        "It makes employees less likely to follow security rules",
        "It encourages employees to share passwords safely with others",
        "It reduces the overall need for doing background checks"
      ],
      correctAnswer: "It educates employees about risks and signs of insider threats"
    },
    {
      question: "What is a compromised insider?",
      options: [
        "An employee whose credentials were stolen and used by attackers",
        "An employee who frequently violates company policies",
        "An employee who decides to quit without giving notice",
        "An employee who consistently follows all security protocols"
      ],
      correctAnswer: "An employee whose credentials were stolen and used by attackers"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 8) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module2_quiz4': true, // Update Firestore to mark the quiz of module 2 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/modulethree/securing-work-devices'); // Replace this with the actual link when available
  };

 

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Recognising Insider Threats Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              {score >= 8 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of recognising insider threats.</p>
              ) : (
                <p>You need to score at least 8 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 8 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default InsiderThreatsQuiz;
