import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path

const SocialMediaSafetyPartTwo = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page6/social-media-drop-and-drag-game'); // Navigate to the next page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Game" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div>
          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Task 2: Review App Permissions</h2>
          </section>

          <div className="bordered-container alt-color">
            <div className="grid-item text">
              <div className="task">
                <p><strong className='#005f73'>Objective:</strong> Ensure third-party apps connected to your social media account have appropriate access.</p>
                <p><strong className="#005f73">Action:</strong> You check the apps connected to your account and remove any that you no longer use or trust. For the remaining apps, you limit their access to only the necessary information.</p>
                <p><strong className="#005f73">Outcome:</strong> Your social media account is now more secure, with limited access to your personal information.</p>
              </div>
            </div>
          </div>

          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Task 3: Be Cautious About Personal Information</h2>
          </section>

          <div className="bordered-container">
            <div className="grid-item text">
              <div className="task">
                <p><strong className="salmon-text">Objective:</strong> Avoid sharing sensitive personal information that could be exploited.</p>
                <p><strong className="salmon-text">Action:</strong> Before posting, you think twice about sharing your home address, phone number, or travel plans. Instead, you share photos of your recent trip after you return, avoiding real-time updates that could expose your location.</p>
                <p><strong className="salmon-text">Outcome:</strong> By sharing wisely, you protect yourself from potential risks and maintain a positive online presence.</p>
              </div>
            </div>
          </div>

          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay /> {/* Add the logo overlay */}
            <video
              ref={videoRef}
              src="/assets/videos/PageSixPartTwo.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
              disablePictureInPicture // Disable Picture-in-Picture
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="button-container">
          <button className="next-button" onClick={goToNext}>Start Game</button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>

       
      </section>
    </div>
   
  );
};

export default SocialMediaSafetyPartTwo;
