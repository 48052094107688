import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const RemoteWorkQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(7).fill(null)); // Updated array length
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(7).fill(null)); // Updated array length
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(7).fill(null)); // Updated array length
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "Why is it mandatory to use a VPN when accessing company resources remotely?",
      options: [
        "To improve internet speed",
        "To encrypt your connection",
        "To bypass network restrictions",
        "To allow for faster downloads"
      ],
      correctAnswer: "To encrypt your connection"
    },
    {
      question: "How often should you update your VPN client?",
      options: [
        "Once a year",
        "Whenever you feel like it",
        "Only if it stops working",
        "Regularly"
      ],
      correctAnswer: "Regularly"
    },
    {
      question: "What does Multi-Factor Authentication (MFA) add to your login process?",
      options: [
        "Just another password",
        "An extra layer of security",
        "A faster login process",
        "The ability to skip passwords"
      ],
      correctAnswer: "An extra layer of security"
    },
    {
      question: "What is a best practice for password management?",
      options: [
        "Use the same password for all accounts",
        "Write your passwords on a sticky note",
        "Use strong, unique passwords",
        "Share your passwords with a trusted colleague"
      ],
      correctAnswer: "Use strong, unique passwords"
    },
    {
      question: "Why should you log off from company systems when not in use?",
      options: [
        "To save electricity",
        "To improve system performance",
        "To prevent unauthorised access",
        "To make room for other users"
      ],
      correctAnswer: "To prevent unauthorised access"
    },
    {
      question: "What is the principle of 'least privilege'?",
      options: [
        "Only access what is necessary for your work",
        "Granting yourself all possible permissions",
        "Accessing as much data as possible",
        "Never logging out of systems"
      ],
      correctAnswer: "Only access what is necessary for your work"
    },
    {
      question: "How often is it recommended to change your router?",
      options: [
        "Replace every year",
        "Replace every two years",
        "Replace every three years",
        "Replace every four years"
      ],
      correctAnswer: "Replace every four years"
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 5) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module5_quiz1': true, // Update Firestore to mark the quiz of module 5 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(7).fill(null)); // Updated array length
    setFeedback(Array(7).fill(null)); // Updated array length
    setCorrectAnswers(Array(7).fill(null)); // Updated array length
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/modulefive/collaboration-tools'); 
  };

 

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Remote Work Security Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 7</p>
              {score >= 5 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of remote work security.</p>
              ) : (
                <p>You need to score at least 5 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 5 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        
      </section>
    </div>
  );
};

export default RemoteWorkQuiz;
