import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; 
import './WhatIsCyberSecurity.css'; // Ensure this file exists

const EmployeeRoleQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "Why is it important to use strong, unique passwords?",
      options: [
        "Because it is company policy",
        "To reduce the risk of password guessing and hacking",
        "To remember passwords easily",
        "To update software quickly"
      ],
      correctAnswer: "To reduce the risk of password guessing and hacking"
    },
    {
      question: "What should you do if you identify a phishing attempt?",
      options: [
        "Ignore it",
        "Report it to the IT department immediately",
        "Click on the link to investigate as it may be important",
        "Delete the email without reporting"
      ],
      correctAnswer: "Report it to the IT department immediately"
    },
    {
      question: "Why is it essential to keep devices and software up-to-date?",
      options: [
        "To improve battery life, making the device more efficient",
        "To protect against vulnerabilities",
        "To increase internet speed",
        "To save storage space"
      ],
      correctAnswer: "To protect against vulnerabilities"
    },
    {
      question: "What is your responsibility regarding company cybersecurity policies?",
      options: [
        "Ignore them",
        "Follow them strictly",
        "Only follow them when convenient",
        "Delegate them to others"
      ],
      correctAnswer: "Follow them strictly"
    },
    {
      question: "What should you do if you notice unusual system behaviour?",
      options: [
        "Restart the computer",
        "Report it immediately to the IT department",
        "Ask colleagues who have more experience for advice",
        "Try to fix it yourself"
      ],
      correctAnswer: "Report it immediately to the IT department"
    },
    {
      question: "Why is quick reporting of security incidents important?",
      options: [
        "To contain and mitigate the impact of a breach",
        "So that the company can hide the breach and maintain its reputation",
        "So all computers can be shut down to avoid any further incidents",
      ],
      correctAnswer: "To contain and mitigate the impact of a breach"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module1_quiz2': true, // Correctly updating for module1.quiz2
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null));
    setFeedback(Array(6).fill(null));
    setCorrectAnswers(Array(6).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/moduletwo/recognizing-cyber-threats'); // Replace this with the actual link when available
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/employee-role');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Employee's Role in Cybersecurity Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 6</p>
              {score >= 4 ? (
                <p>Congratulations! You’ve passed the quiz and now have a clear understanding of your role in maintaining cybersecurity as an employee.</p>
              ) : (
                <p>You need to score at least 4 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default EmployeeRoleQuiz;
