import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path


const StrongPasswordsPartThree = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page4/password-game'); // Navigate to the new page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Task" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-two'); // Navigate to the previous page
  };

  return (
    
    <div className="introduction-container2">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <div className="BasicsTitle">Strong Password Creation</div>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/pageFourPartThree.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture // Disable Picture-in-Picture
          >
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Content Sections */}
        <section className="module-section2">
          <h2>Strong Passwords</h2>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item-text2">
                  <p><strong><span style={{ color: 'salmon' }}>Length:</span></strong> Aim for at least 12-16 characters. Longer passwords are generally more secure. Don't worry we will provide a technique to make this easier!</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item-text2">
                  <p><strong><span style={{ color: 'salmon' }}>Complexity:</span></strong> Use a mix of uppercase and lowercase letters, numbers, and special characters (e.g., !, @, #, $).</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item-text2">
                  <p><strong><span style={{ color: 'salmon' }}>Avoid Common Words and Patterns:</span></strong> Avoid using common words, phrases, or easily guessable patterns like "password123" or "qwerty."</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item-text2">
                  <p><strong><span style={{ color: 'salmon' }}>Randomness:</span></strong> Create passwords that are not based on personal information such as birthdays, names, or easily obtainable information.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section3">
          <div className="module-content3">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item-text2">
                  <p><strong><span style={{ color: 'salmon' }}>Use Passphrases:</span></strong> Combine unrelated words into a passphrase, such as "BlueHorse$7Coffee*" or "SunnyDay!Mountain8."</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Techniques Section */}
        <section className="module-section">
          <h2>Techniques for Creating Strong Passwords</h2>
          <section className="module-section3">
            <div className="module-content3">
              <div className="bordered-container3">
                <div className="grid-container3">
                  <div className="grid-item text3">
                    <p><strong>Passphrase Method: </strong> Use a series of random words strung together. For example, "CorrectHorseBatteryStaple."</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="module-section3">
            <div className="module-content3">
              <div className="bordered-container3">
                <div className="grid-container3">
                  <div className="grid-item text3">
                    <p><strong>Acronym Method: </strong> Create an acronym from a phrase or a sentence. For instance, "I love to travel around the world in 80 days!" becomes "IltTatWi80d!"</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="module-section3">
            <div className="module-content3">
              <div className="bordered-container3">
                <div className="grid-container3">
                  <div className="grid-item text3">
                    <p><strong>Sentence Method: </strong> Turn a sentence into a password by using the first letter of each word, including punctuation. For example, "My favorite movie is Jurassic Park!" becomes "MfmijP!"</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="module-section3">
            <div className="module-content3">
              <div className="bordered-container3">
                <div className="grid-container3">
                  <div className="grid-item text3">
                    <p>Alternatively you can <strong>Install and Set Up a Password Manager: </strong> Choose a reputable password manager and set it up to store all your passwords securely.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* Navigation Buttons */}
        <div className="button-container">
          <button className="next-button" onClick={goToNext}>Start Task</button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>

      
      </section>
    </div>
    
  );
};

export default StrongPasswordsPartThree;
