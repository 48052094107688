import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Adjust path accordingly
import './CookieConsent.css';

// Set cookie with an expiration date
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// Delete cookie by setting expiration date in the past
const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

// Get cookie value by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

// Dynamically load Google Analytics script based on consent
const loadAnalyticsScript = () => {
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-797QFYTS6H'; // Replace with your Google Analytics ID
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-797QFYTS6H'); // Replace with your Google Analytics tracking ID
  };
};

// Apply cookie preferences based on user consent
const applyCookiePreferences = (consent) => {
  // Necessary cookies are always allowed
  setCookie('necessary_cookie', 'value', 365);

  // Set functional cookies if consent is granted
  if (consent.functional) {
    setCookie('functional_cookie', 'value', 365);
    loadAnalyticsScript(); // Load functional/analytics script if consent is given
  } else {
    deleteCookie('functional_cookie'); // Remove functional cookies if consent is revoked
  }
};

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const [user, setUser] = useState(null); // Track user state
  const [consent, setConsent] = useState({
    necessary: true,
    functional: true,
    analytics: true,
    marketing: false,
  });

  const auth = getAuth();

  useEffect(() => {
    // Check if consent is already stored in cookies
    const necessary = getCookie('necessary_cookie') === 'value';
    const functional = getCookie('functional_cookie') === 'value';

    if (!necessary) {
      // Show the cookie consent banner if the necessary cookie is not set
      setIsVisible(true);
    } else {
      // Apply stored consent preferences if already provided
      applyCookiePreferences({ necessary, functional });
    }

    // Track user authentication state
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Check if the user has already given consent
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const consentHistory = userData.consentHistory;

          if (consentHistory) {
            // Update local cookies based on Firestore data
            applyCookiePreferences(consentHistory);
          } else {
            // Save current local consent to Firestore if not already saved
            await saveConsentToFirestore(currentUser.uid, consent);
          }
        }
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth]);

  const handleAcceptAll = async () => {
    const consentData = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };
    setIsVisible(false);
    applyCookiePreferences(consentData);

    // Save consent locally
    setCookie('necessary_cookie', 'value', 365);
    setCookie('functional_cookie', 'value', 365);

    // If user is logged in, save consent to Firestore
    if (user) {
      await saveConsentToFirestore(user.uid, consentData);
    }
  };

  const handleRejectAll = async () => {
    const consentData = {
      necessary: true,
      functional: false,
      analytics: false,
      marketing: false,
    };
    setIsVisible(false);
    applyCookiePreferences(consentData);

    // Save consent locally
    setCookie('necessary_cookie', 'value', 365);
    deleteCookie('functional_cookie');

    // If user is logged in, save consent to Firestore
    if (user) {
      await saveConsentToFirestore(user.uid, consentData);
    }
  };

  const handleSavePreferences = async () => {
    setIsVisible(false);
    setShowCustomize(false);
    applyCookiePreferences(consent);

    // Save consent locally
    setCookie('necessary_cookie', 'value', 365);
    if (consent.functional) {
      setCookie('functional_cookie', 'value', 365);
    } else {
      deleteCookie('functional_cookie');
    }

    // If user is logged in, save consent to Firestore
    if (user) {
      await saveConsentToFirestore(user.uid, consent);
    }
  };

  // Save consent to Firestore
  const saveConsentToFirestore = async (userId, consentData) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      await updateDoc(userDocRef, { consentHistory: consentData });
    } catch (error) {
      console.error('Error saving consent to Firestore:', error);
    }
  };

  const handleCustomizeClick = () => {
    setShowCustomize(true);
  };

  const toggleConsent = (category) => {
    setConsent((prevConsent) => ({
      ...prevConsent,
      [category]: !prevConsent[category],
    }));
  };

  return (
    <>
      {isVisible && (
        <div className="cookie-consent-banner">
          <p>
            We use cookies for proper functioning and marketing. By accepting, you agree to their use as outlined in our
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
          </p>
          <div className="cookie-buttons">
            <button onClick={handleCustomizeClick}>Customize</button>
            <button onClick={handleRejectAll}>Reject All</button>
            <button onClick={handleAcceptAll}>Accept All</button>
          </div>
        </div>
      )}

      {/* Customize modal */}
      {showCustomize && (
        <div className="cookie-consent-modal">
          <div className="modal-content">
            <h3>Customize Consent Preferences</h3>
            <p>
              We use cookies to help you navigate efficiently and perform certain functions.
              You can customize which types of cookies you allow.
            </p>
            <div className="cookie-preferences">
              <div className="preference-item">
                <h4>Necessary</h4>
                <p>These cookies are necessary for the website to function and cannot be switched off.</p>
                <label>Always Active</label>
              </div>
              <div className="preference-item">
                <h4>Functional</h4>
                <p>Functional cookies help enhance your experience on the website.</p>
                <label>
                  <input
                    type="checkbox"
                    checked={consent.functional}
                    onChange={() => toggleConsent('functional')}
                  />
                  Enabled
                </label>
              </div>
              <div className="preference-item">
                <h4>Analytics</h4>
                <p>Analytics cookies help us understand how users interact with the site.</p>
                <label>
                  <input
                    type="checkbox"
                    checked={consent.analytics}
                    onChange={() => toggleConsent('analytics')}
                  />
                  Enabled
                </label>
              </div>
              <div className="preference-item">
                <h4>Marketing</h4>
                <p>Marketing cookies help us show you personalized ads.</p>
                <label>
                  <input
                    type="checkbox"
                    checked={consent.marketing}
                    onChange={() => toggleConsent('marketing')}
                  />
                  Enabled
                </label>
              </div>
            </div>
            <div className="modal-buttons">
              <button onClick={handleRejectAll}>Reject All</button>
              <button onClick={handleSavePreferences}>Save My Preferences</button>
              <button onClick={() => setShowCustomize(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
