import React from 'react';
import './LegalDocuments.css'; // Optional: Add CSS for styling
import Footer from '../Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="legal-documents-body">
      <div className="legal-privacy-policy-container">
        <h1 className="legal-h1">Privacy Policy</h1>
        <p className="legal-p">Effective Date: 14/10/2024</p>
        <p className="legal-p">
          At Be Cyber Aware, we are committed to safeguarding your privacy. This Privacy Policy outlines the types of personal data we collect, how we use, store, and share this information, and your rights concerning that data.
        </p>

        <h2 className="legal-h2">1. Information We Collect</h2>
        <p className="legal-p">
          We collect and process the following types of personal data:
        </p>
        <ul className="legal-ul">
          <li className="legal-li"><strong>Account Information:</strong> When you create a <strong>Personal Plan</strong> or <strong>Team Plan</strong> account, we collect your name, email address, and password. Personal Plan users can optionally provide their manager's email to link accounts providing their manager has a Team Plan. We also collect the date and time of account creation and your acceptance of terms and conditions.</li>
          <li className="legal-li"><strong>Course Progress:</strong> We collect data on your course progress, including completed modules, to enhance your learning experience and allow managers wh are on the Team Plan to track the progress of linked employees.</li>
          <li className="legal-li"><strong>Manager and Employee Data:</strong> If a Personal Plan user links their account to a Manager who has a Team Plan, we collect and store the manager's email address. Managers can view their linked employees' course progress and submit or receive threat reports.</li>
          <li className="legal-li"><strong>Threat Reports:</strong> Employees linked to a manager who has a Team Plan can submit threat reports that include a title, description, and timestamp. Linked managers can view these reports and submit their own reports, which also include timestamps.</li>
          <li className="legal-li"><strong>Payment Information:</strong> For <strong>Team plan</strong>, we collect payment details during the subscription process to manage billing and services.</li>
          <li className="legal-li"><strong>Payment Information:</strong> For <strong>Personal Plan users</strong>, payment information is collected when upgrading to the full course for enhanced learning features.</li>
          <li className="legal-li"><strong>Usage Data:</strong> We collect general usage data, including the last activity timestamp, to manage inactive accounts and optimise your experience with the platform.</li>
          <li className="legal-li"><strong>Login Attempts and Security:</strong> We log failed login attempts and track account lockout status to prevent unauthorised access. After five failed attempts, your account may be temporarily locked. The time and number of failed login attempts are stored.</li>
          <li className="legal-li"><strong>Two-Factor Authentication (2FA):</strong> If you enable 2FA, we collect your phone number to send verification codes and enhance account security.</li>
        </ul>

        <h2 className="legal-h2">2. How We Use Your Information</h2>
        <p className="legal-p">
          We use your information to:
        </p>
        <ul className="legal-ul">
          <li className="legal-li">Provide access to cybersecurity training courses.</li>
          <li className="legal-li">Track and manage your course progress.</li>
          <li className="legal-li">Allow managers who have the Team Plan and linked to the employee to monitor employees' progress and receive threat reports.</li>
          <li className="legal-li">Facilitate payments for users who have Team Plan accounts and subscription renewals.</li>
          <li className="legal-li">Facilitate payments for users who ave Personal Plan accounts.</li>
          <li className="legal-li">Communicate with you about your account, including reminders, updates, and promotional offers.</li>
          <li className="legal-li">Ensure compliance with our policies and improve our services.</li>
        </ul>

        <h2 className="legal-h2">3. Cookies and Tracking Technologies</h2>
        <p className="legal-p">
          We use cookies and similar tracking technologies to enhance your experience on our platform, understand usage patterns, and manage account preferences. By using our website, you agree to our use of cookies as outlined in this section. You can manage your cookie preferences through our <a href="/cookie-policy" target="_blank">Cookie Policy</a>.
        </p>
        <ul className="legal-ul">
          <li className="legal-li"><strong>Necessary Cookies:</strong> These cookies are essential for the website to function properly and cannot be switched off in our systems. They include session cookies and those required to store your consent preferences.</li>
          <li className="legal-li"><strong>Functional Cookies:</strong> These cookies enable enhanced functionality and personalisation, such as storing your preferences. If you do not allow these cookies, some features of the site may not function correctly.</li>
          <li className="legal-li"><strong>Analytics Cookies:</strong> We use Google Analytics to collect information about how users interact with the website. This data helps us improve the user experience and track the effectiveness of our services. You can opt out of analytics cookies at any time.</li>
          <li className="legal-li"><strong>Marketing Cookies:</strong> These cookies may be set by third-party advertisers to build a profile of your interests and show you relevant advertisements on other websites. If you do not allow these cookies, you will not experience targeted advertising.</li>
        </ul>

        <h2 className="legal-h2">4. Third-Party Services</h2>
        <p className="legal-p">
          We use third-party services to enhance security, analyse usage, and optimise the performance of the platform. These services collect and process data in accordance with their privacy policies.
        </p>
        <ul className="legal-ul">
          <li className="legal-li"><strong>Google Analytics:</strong> We use Google Analytics to track and report website traffic. Google Analytics collects data such as IP address, browser type, and interactions on the platform. This information helps us understand user behavior and improve the platform. You can opt out of Google Analytics by <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">installing the Google Analytics Opt-Out Browser Add-on</a>.</li>
          <li className="legal-li"><strong>Google reCAPTCHA:</strong> We use Google reCAPTCHA to protect against spam and abuse. reCAPTCHA collects hardware and software information, such as device and application data, to perform its service. This data is subject to Google's <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a>.</li>
        </ul>

        <h2 className="legal-h2">5. Data Retention</h2>
        <p className="legal-p">
          We retain personal data only for as long as necessary to fulfill the purposes outlined in this policy. For example, we keep account information while your account is active. We may also retain certain data for legal or regulatory purposes.
        </p>
        <ul className="legal-ul">
          <li className="legal-li">Login attempts and security data are retained for up to one year to ensure account safety.</li>
          <li className="legal-li">Course progress data is retained as long as your account is active to maintain continuity in your learning experience.</li>
        </ul>

        <h2 className="legal-h2">6. Sharing of Information</h2>
        <p className="legal-p">
          We do not sell or share your personal data with third parties except to:
        </p>
        <ul className="legal-ul">
          <li className="legal-li">Comply with legal obligations.</li>
          <li className="legal-li">Provide essential services through trusted third-party providers.</li>
        </ul>

        <h2 className="legal-h2">7. Security of Your Information</h2>
        <p className="legal-p">
          We implement technical and organisational measures to protect your data from unauthorised access, disclosure, or alteration. However, no method of internet transmission is 100% secure.
        </p>

        <h2 className="legal-h2">8. User Rights</h2>
        <p className="legal-p">
          You have the right to access, update, or delete your personal data. You may also unlink your account from a linked manager who has a Team Plan account or opt out of communications.
        </p>

        <h2 className="legal-h2">9. Changes to the Privacy Policy</h2>
        <p className="legal-p">
          We may update this policy. You will be notified of any significant changes through email or our website.
        </p>

        <h2 className="legal-h2">10. Contact Us</h2>
        <p className="legal-p">
          If you have any questions, please contact us at contact@becyberaware.co.uk.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
