import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const CollaborationToolsQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null)); // Adjusted array length for number of questions
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null)); // Adjusted array length for number of questions
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null)); // Adjusted array length for number of questions
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "Why should you password-protect online meetings?",
      options: [
        "To prevent unauthorised participants from joining",
        "To improve audio quality",
        "To make the meeting more enjoyable",
        "To keep track of participants"
      ],
      correctAnswer: "To prevent unauthorised participants from joining"
    },
    {
      question: "What is a good practice for setting permissions in online meetings?",
      options: [
        "Allow everyone to share their screen",
        "Set appropriate permissions based on roles",
        "Let anyone invite new participants",
        "Enable file sharing for all, this makes the meting more efficient"
      ],
      correctAnswer: "Set appropriate permissions based on roles"
    },
    {
      question: "Why is it important to keep collaboration tools up-to-date?",
      options: [
        "To access new features",
        "To ensure compatibility with older devices",
        "To protect against known vulnerabilities",
        "To change the interface layout"
      ],
      correctAnswer: "To protect against known vulnerabilities"
    },
    {
      question: "What should you do when sharing files through collaboration tools?",
      options: [
        "Use encrypted services or secure sharing features",
        "Send files through public links",
        "Upload files without any security measures",
        "Share files only in public channels"
      ],
      correctAnswer: "Use encrypted services or secure sharing features"
    },
    {
      question: "How should meeting recordings be handled?",
      options: [
        "Record all meetings and share them publicly",
        "Store recordings securely and only allow necessary access",
        "Keep recordings on unsecured personal devices",
        "Share recordings through unsecured emails"
      ],
      correctAnswer: "Store recordings securely and only allow necessary access"
    },
    {
      question: "What should you verify before clicking on links shared during meetings?",
      options: [
        "The number of participants in the meeting",
        "The legitimacy of the links",
        "The sender's email signature",
        "The file size of the link"
      ],
      correctAnswer: "The legitimacy of the links"
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module5_quiz2': true, // Update Firestore to mark the quiz of module 5 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null)); // Adjusted array length for number of questions
    setFeedback(Array(6).fill(null)); // Adjusted array length for number of questions
    setCorrectAnswers(Array(6).fill(null)); // Adjusted array length for number of questions
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/modulesix/data-protection'); 
  };

  

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Collaboration Tools Security Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 6</p>
              {score >= 4 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of collaboration tools security.</p>
              ) : (
                <p>You need to score at least 4 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default CollaborationToolsQuiz;
