import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore'; // Firestore methods
import { firestore } from '../firebase'; // Firestore instance
import ReCAPTCHA from 'react-google-recaptcha';
import './login.css'; // Updated CSS file

const MAX_EMAIL_LENGTH = 100; // Maximum character limit for email
const MAX_PASSWORD_LENGTH = 50; // Maximum character limit for password
const MAX_FAILED_ATTEMPTS = 5; // Maximum failed attempts before lockout
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes lockout duration in milliseconds
const RATE_LIMIT_DURATION = 30 * 1000; // 30 seconds rate-limiting duration in milliseconds

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [timeToRetry, setTimeToRetry] = useState(null); // To track when the user can try again
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add a state to track login status
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        navigate('/');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Function to encode email for Firestore document ID
  const encodeEmail = (email) => {
    return email.replace('@', '__AT__').replace(/\./g, '__DOT__');
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = getAuth();
  
    if (!recaptchaToken) {
      setError('reCAPTCHA verification failed. Please try again.');
      return;
    }
  
    try {
      // Set session-based persistence to ensure logout on browser close
      await setPersistence(auth, browserSessionPersistence);
  
      // Check if the account is locked
      const isAccountLocked = await checkAccountLock(email);
      if (isAccountLocked) {
        const localLockedUntil = new Date(isAccountLocked.lockedUntil).toLocaleTimeString('en-US', { timeZone: 'Europe/London' }); // Adjust the time zone accordingly
        setError(`Login attempt failed. Try again after ${localLockedUntil}.`);
        return;
      }
  
      // Attempt to sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the user has verified their email
      if (!user.emailVerified) {
        console.error('Email not verified.');
        setError('Please verify your email before logging in.');
        await auth.signOut(); // Sign out the user if not verified
        return;
      }
  
      // Use the user's UID to reference their Firestore document
      const userDocRef = doc(firestore, 'users', user.uid);
  
      // Fetch user profile data
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
  
      // If email is verified, update Firestore to reflect the change
      if (!userData.isEmailVerified) {
        await updateDoc(userDocRef, { isEmailVerified: true });
      }
  
      // Update Firestore to update last login time
      await updateDoc(userDocRef, {
        lastLogin: new Date().toISOString(), // Store in UTC format
      });
  
      // Clear failed attempts after a successful login
      await resetLoginAttempts(email);
  
      // Redirect to the home page or dashboard
      setError(null); // Reset error
      setTimeToRetry(null); // Reset the rate-limiting timer if they log in successfully
      setIsLoggedIn(true); // Set login state to true
      navigate('/');
    } catch (error) {
      console.error("Login error:", error);
      // Failed to log in, log failed attempt with rate limiting
      const rateLimitInfo = await logFailedAttempt(email);
      if (rateLimitInfo.isRateLimited) {
        setError(`Too many attempts. Please wait ${Math.ceil(rateLimitInfo.retryIn / 1000)} seconds before trying again.`);
        setTimeToRetry(Date.now() + rateLimitInfo.retryIn);
      } else {
        setError('Login failed. Please try again.');
      }
    }
  };
  

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  // Function to log failed login attempts in Firestore with rate limiting
  const logFailedAttempt = async (email) => {
    try {
      const encodedEmail = encodeEmail(email);
      const loginAttemptsRef = doc(firestore, 'loginAttempts', encodedEmail);

      const loginAttemptsDoc = await getDoc(loginAttemptsRef);

      let failedAttempts = 0;
      let lockedUntil = null;
      let lastFailedAt = null;

      if (loginAttemptsDoc.exists()) {
        const data = loginAttemptsDoc.data();
        failedAttempts = data.failedAttempts || 0;
        lockedUntil = data.lockedUntil || null;
        lastFailedAt = data.lastFailedAt || null;
      }

      const now = new Date().getTime(); // Use UTC time

      // Rate limit if last attempt was recent
      if (lastFailedAt && now - new Date(lastFailedAt).getTime() < RATE_LIMIT_DURATION) {
        const retryIn = RATE_LIMIT_DURATION - (now - new Date(lastFailedAt).getTime());
        return { isRateLimited: true, retryIn };
      }

      failedAttempts += 1;

      if (failedAttempts >= MAX_FAILED_ATTEMPTS) {
        lockedUntil = new Date(now + LOCKOUT_DURATION).toISOString(); // Lock until specific UTC time
        await setDoc(loginAttemptsRef, {
          failedAttempts,
          lastFailedAt: new Date().toISOString(), // Store in UTC format
          isLocked: true,
          lockedUntil,
        });
      } else {
        await setDoc(loginAttemptsRef, {
          failedAttempts,
          lastFailedAt: new Date().toISOString(), // Store in UTC format
          isLocked: false,
        });
      }

      return { isRateLimited: false };
    } catch (error) {
      console.error("Failed to log the login attempt:", error);
      throw new Error('Failed to log the login attempt.');
    }
  };

  // Function to reset failed login attempts after a successful login
  const resetLoginAttempts = async (email) => {
    try {
      const encodedEmail = encodeEmail(email);
      const loginAttemptsRef = doc(firestore, 'loginAttempts', encodedEmail);

      await setDoc(loginAttemptsRef, {
        failedAttempts: 0,
        isLocked: false,
        lockedUntil: null,
      });
    } catch (error) {
      console.error("Failed to reset login attempts:", error);
      throw new Error('Failed to reset login attempts.');
    }
  };

  // Function to check if the account is locked
  const checkAccountLock = async (email) => {
    try {
      const encodedEmail = encodeEmail(email);
      const loginAttemptsRef = doc(firestore, 'loginAttempts', encodedEmail);

      const loginAttemptsDoc = await getDoc(loginAttemptsRef);

      if (loginAttemptsDoc.exists()) {
        const data = loginAttemptsDoc.data();
        const isLocked = data.isLocked || false;
        const lockedUntil = data.lockedUntil || null;

        if (isLocked && new Date(lockedUntil).getTime() > new Date().getTime()) {
          return { isLocked: true, lockedUntil };
        } else if (isLocked && new Date(lockedUntil).getTime() <= new Date().getTime()) {
          await resetLoginAttempts(email); // Reset lock if time has passed
        }
      }
      return false;
    } catch (error) {
      console.error("Failed to check account lock:", error);
      throw new Error('Failed to check account lock.');
    }
  };

  return (
    <div className="login-containerLogin">
      <div className="auth-boxLogin">
        <button className="back-buttonLogin" onClick={() => navigate('/')}>Home</button>
        <h1>Login</h1>
        {error && (
          <div className="error-containerLogin">
            <p className="error-textLogin">{error}</p>
            <button className="reset-password-buttonLogin" onClick={() => navigate('/reset-password')}>
              Reset Password
            </button>
          </div>
        )}
        <form onSubmit={handleLogin}>
          <div className="input-groupLogin">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.slice(0, MAX_EMAIL_LENGTH))} // Enforce email character limit
              required
            />
          </div>
          <div className="input-groupLogin">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value.slice(0, MAX_PASSWORD_LENGTH))} // Enforce password character limit
              required
            />
          </div>

          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey="6Lfr8W0qAAAAABQY145UoWluq1Ap6kr0jOXxQfWz"
              onChange={onRecaptchaChange}
            />
          </div>

          <button
            type="submit"
            className="login-buttonLogin"
            disabled={timeToRetry && timeToRetry > Date.now()}
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
