import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the corresponding CSS file

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">

        {/* Sitemap Section */}
        <nav className="footer-section sitemap" aria-label="Footer Navigation">
          <ul className="sitemap-links">
            <li><Link to="/" className="sitemap-link1">Home</Link></li>
            <br></br>
            <li><Link to="/courses-two" className="sitemap-link2">Courses</Link></li>
            <br></br>
            <li><Link to="/about" className="sitemap-link3">About</Link></li>
          </ul>
        </nav>

        {/* Separator */}
        <div className="footer-separator"></div>

        {/* Legal Section */}
        <div className="footer-section legal">
          <div className="footer-links">
            <Link to="/user-agreement" className="footer-link">User Agreement</Link>
            <span> | </span>
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            <span> | </span>
            <Link to="/cookie-policy" className="footer-link">Cookie Policy</Link>
          </div>
          <p className="footer-legal-text">
            Be Cyber Secure is designed to enhance learning and training experiences. To aid comprehension, examples may be simplified. Our tutorials, references, and examples undergo regular reviews to minimise errors, although we cannot guarantee complete accuracy. By using Cyber Secure, you acknowledge that you have read and accepted our terms of use as well as our cookie and privacy policies.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
