import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WhatIsCyberSecurity.css'; // Adjusted path

const ImportanceCyber = () => {
  const navigate = useNavigate();

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber-quiz'); // Navigate to the new page
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/what-is-cyber-security'); // Navigate to the CybersecurityBasics.js page
  };

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
      
        <div className="cybersecurity-basics-header">
        <h2>Why is Cybersecurity Important for Individuals?</h2>
            </div>
        <div className="module-content">
          <div className="bordered-container alt-color">
            <h3>Protection of Personal Information</h3>
            <p>Personal information such as, credit card details, medical records, and personal communications are valuable to cybercriminals. Protecting this information helps prevent identity theft, financial fraud, and privacy invasions.</p>
          </div>

          <div className="bordered-container">
            <h3>Financial Security</h3>
            <p>Cyberattacks can lead to significant financial loss for individuals. Phishing scams, online banking fraud, and unauthorised transactions can drain bank accounts and damage credit scores. Cybersecurity measures help protect financial assets and transactions.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>Preventing Identity Theft</h3>
            <p>Identity theft occurs when someone uses another person's personal information without permission to commit fraud or other crimes. This can result in long-term damage to the victim's credit and financial standing. Effective cybersecurity practices help protect against identity theft.</p>
          </div>

          <div className="bordered-container">
            <h3>Safeguarding Digital Assets</h3>
            <p>Photos, documents, and other digital assets stored on personal devices are often irreplaceable. Cybersecurity measures such as regular backups and anti-malware protection ensure these assets are protected from loss or damage.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>Maintaining Privacy</h3>
            <p>In an era where personal data is frequently collected and shared, maintaining privacy is increasingly challenging. Cybersecurity helps individuals control their personal information and protect their privacy from unauthorised access and misuse.</p>
          </div>

          <div className="bordered-container">
            <h3>Protecting Personal Devices</h3>
            <p>Malware and viruses can cause significant damage to personal devices, leading to data loss, reduced functionality, or complete device failure. Ensuring cybersecurity helps keep personal devices running smoothly and securely.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>Ensuring Safe Online Experiences</h3>
            <p>From social media to online shopping, individuals engage in numerous online activities that can be targets for cyberattacks. Cybersecurity measures help ensure these activities are conducted safely, protecting individuals from scams, cyberbullying, and other online threats.</p>
          </div>

          <div className="bordered-container">
            <h3>Avoiding Extortion and Blackmail</h3>
            <p>Cybercriminals may use ransomware to lock individuals out of their devices or threaten to release personal information unless a ransom is paid. Cybersecurity practices help prevent such scenarios by safeguarding data and systems.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>Securing Home Networks</h3>
            <p>With the increasing number of smart devices in homes, securing home networks is essential to protect against unauthorised access and potential breaches.</p>
          </div>
        </div>

        <button className="next-button" onClick={goToNext}>Start Quiz</button>
        
        
      </section>
    </div>
  );
};

export default ImportanceCyber;
