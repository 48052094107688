import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../page5/SafeBrowsingGame.css';

const scenarios = [
  {
    text: "What domain does this link end at?: https://bit.ly/3MrqW9y (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: false },
      { text: "www.facebook.com", isCorrect: false },
      { text: "www.bbc.com", isCorrect: true }
    ],
    feedback: [
      "Incorrect.",
      "Incorrect.",
      "Correct!"
    ]
  },
  {
    text: "What domain does this link end at?: https://bit.ly/3Xq6OLn (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: false },
      { text: "www.ncsc.gov.uk", isCorrect: true },
      { text: "www.bbc.com", isCorrect: false }
    ],
    feedback: [
      "Incorrect.",
      "Correct!",
      "Incorrect."
    ]
  },
  {
    text: "What domain does this link end at?: https://bit.ly/3TeDQM2 (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: true },
      { text: "www.facebook.com", isCorrect: false },
      { text: "www.bbc.com", isCorrect: false }
    ],
    feedback: [
      "Correct!",
      "Incorrect.",
      "Incorrect."
    ]
  }
];

const CheckingURLsQuiz = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [hasAnswered, setHasAnswered] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false); // State to control star animation

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login'); // Redirect to login if the user is not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleOptionClick = (isCorrect, feedback) => {
    if (!hasAnswered) {
      setFeedback({ message: feedback, isCorrect });
      setHasAnswered(true);
      if (isCorrect) {
        setScore(score + 1);
      }
      setTimeout(() => {
        setFeedback(null);
        setHasAnswered(false);
        setCurrentScenario(currentScenario + 1);
      }, 3000); // Show feedback for 3 seconds
    }
  };

  useEffect(() => {
    if (currentScenario >= scenarios.length) {
      if (score === scenarios.length) {
        // If the user got all questions correct, update the profile
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          updateDoc(userDocRef, {
            'progress.course1.module55': true // Update Firestore to mark module4 as completed for course1
          }).then(() => {
            setShowStar(true); // Trigger the star animation
            console.log('User profile updated with quiz completion.');
          }).catch(error => {
            console.error('Error updating user profile: ', error);
          });
        }
      }
    }
  }, [currentScenario, score, user]);

  const resetGame = () => {
    setCurrentScenario(0);
    setScore(0);
    setFeedback(null);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page6/social-media-safety'); // Replace with the actual next page route
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5.5/checking-urls'); // Replace with the actual previous page route
  };

  if (currentScenario >= scenarios.length) {
    const hasPassed = score === scenarios.length;

    return (
      <div className="safe-browsing-game-container-unique11">
        <button className="back-arrow-button-unique11" onClick={goBack}>
          ←
        </button>
        {showStar && hasPassed && <div className="falling-star"></div>} {/* Star animation */}
        <h2>{hasPassed ? 'Congratulations! You passed the quiz and are now more cyber secure!' : 'You did not pass the quiz. Try again to improve your cyber security knowledge.'}</h2>
        <p>You scored {score} out of {scenarios.length}</p>
        <button onClick={resetGame}>Try Again</button>
        {hasPassed && <button onClick={goToNext}>Next</button>}
      </div>
    );
  }

  const { text, options, feedback: scenarioFeedback } = scenarios[currentScenario];

  return (
    
    <div className="safe-browsing-game-container-unique11">
      <button className="back-arrow-button-unique11" onClick={goBack}>
        ←
      </button>
      <h2>{text}</h2>
      {options.map((option, index) => (
        <button
          key={index}
          className={feedback && feedback.message === scenarioFeedback[option.isCorrect ? 2 : index] ? (option.isCorrect ? 'correct-unique11' : 'incorrect-unique11') : ''}
          onClick={() => handleOptionClick(option.isCorrect, scenarioFeedback[index])}
          disabled={hasAnswered} // Disable the button if an answer has been chosen
        >
          {option.text}
        </button>
      ))}
      {feedback && <p className={`feedback-unique11 ${feedback.isCorrect ? 'correct-feedback-unique11' : 'incorrect-feedback-unique11'}`}>{feedback.message}</p>}
    </div>
    
  );
};

export default CheckingURLsQuiz;
