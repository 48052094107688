import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Adjust the path for the CSS file

const ReportingProcedures = () => {
  const navigate = useNavigate();

  const goToQuiz = () => {
    navigate('/businessacademy/modulefour/reporting-procedures-quiz'); // Navigate to the quiz page (if applicable)
  };

  const goBack = () => {
    navigate('/businessacademy/modulefour/incidents'); // Navigate to the previous page
  };

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Reporting Procedures</h2>
        <br />
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>How to Report Potential Security Incidents and Why Timely Reporting is Critical</h3> <br />
          <p>
            Understanding how to report potential security incidents and the importance of timely reporting is essential for safeguarding the organisation.
          </p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Reporting Channels</h3> <br />
          
            <ul>
              <li><strong style={{ color: 'salmon' }}>Designated Contacts:</strong> Employees should know the specific person or department to contact when they suspect or identify a security incident, such as the IT or Security teams.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Incident Reporting Tools:</strong> Many organisations have dedicated tools or platforms for reporting security incidents. Ensure employees are familiar with these tools and know how to use them.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Confidentiality:</strong> Encourage employees to report incidents confidentially if necessary, especially if they involve insider threats or sensitive information.</li>
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Why Timely Reporting is Critical</h3> <br />
          
            <ul>
              <li><strong style={{ color: 'salmon' }}>Early Containment:</strong> Prompt reporting allows the organisation to contain the incident quickly, preventing further damage or data loss.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Minimising Impact:</strong> The sooner an incident is reported, the faster the response, which can significantly reduce the impact on the organisation's operations and reputation.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Compliance:</strong> Many regulatory frameworks, such as GDPR, require organisations to report certain types of security incidents within a specific timeframe. Timely internal reporting ensures the organisation can meet these obligations.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Preventing Recurrence:</strong> Early detection and reporting can help identify the root cause of the incident, enabling the organisation to address vulnerabilities and prevent similar incidents in the future.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Incident Investigation:</strong> Reporting incidents promptly provides the security team with the necessary time and information to conduct a thorough investigation, ensuring all aspects of the incident are understood and addressed.</li>
            </ul>
         
        </div>
      </div>

      <button className="start-quiz-button" onClick={goToQuiz}>Start Quiz</button>

      
    </div>
  );
};

export default ReportingProcedures;
