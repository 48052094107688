import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const EmployeeRole = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark the video as fully watched
    setShowWarning(false); // Hide any warning message
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduleone/employee-role-quiz'); // Navigate to the quiz page
    } else {
      setShowWarning(true); // Show a warning if the video is not fully watched
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/statistics'); // Replace with the actual previous page route
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
          <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br /> <br />
        <h2>Employee's Role in Protecting the Organisation</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/EmployeesRole.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <h2>Employee's Responsibilities</h2>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Importance of Using Strong, Unique Passwords</h3>  <br />
            <p>
              You should create passwords that are complex and unique for each account, incorporating a mix of letters, numbers, and special characters or using a password manager. This reduces the risk of password guessing and hacking.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Recognising and Reporting Phishing Attempts</h3> <br />
            <p>
              This training will give you the knowledge to help identify phishing emails, such as those with suspicious links, attachments, or urgent requests for personal information. You should know how to report these attempts to the IT department immediately.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Securing Devices and Ensuring Software is Up-to-Date</h3> <br />
            <p>
              You should regularly update your device and software to protect against vulnerabilities. This includes operating systems, applications, and antivirus software.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Following the Company's Cybersecurity Policies and Guidelines</h3> <br />
            <p>
              It's essential for you to adhere to established cybersecurity policies, including acceptable use policies, data handling procedures, and guidelines for remote work.
            </p>
          </div>
        </div>

        <h2>Incident Reporting</h2>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Identifying Potential Security Incidents</h3>  <br />
            <p>
              If you recognise signs of a security breach, such as unusual system behaviour, unexpected software installations, or unauthorised access alerts, you should report it immediately to the IT department or relevant parties.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Reporting Incidents</h3>  <br />
            <p>
              You need to know the specific steps for reporting incidents, including whom to contact and what information to provide. Quick reporting can help contain and mitigate the impact of a breach.
            </p>
          </div>
        </div>

        <button className="start-quiz-button" onClick={handleNextClick}>
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </section>

     
    </div>
  );
};

export default EmployeeRole;
