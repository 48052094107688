import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const ManagerRoute = ({ children }) => {
  const [isManager, setIsManager] = useState(false);
  const [loading, setLoading] = useState(true); // To handle the loading state
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const checkManagerAccess = async () => {
      try {
        if (user) {
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();

            // Ensure only 'manager' or 'admin-manager' or 'admin' roles get access
            const roles = userData?.profile?.accountType.split(',').map(role => role.trim());

            if (roles?.includes('manager') || roles?.includes('admin')) {
              setIsManager(true);
            } else {
              navigate('/403'); // Redirect non-manager users to 403 Forbidden
            }
          } else {
            navigate('/login'); // Redirect if no user data found
          }
        } else {
          navigate('/login'); // Redirect unauthenticated users to login
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/403'); // Redirect in case of error
      } finally {
        setLoading(false); // Ensure loading stops
      }
    };

    checkManagerAccess();
  }, [user, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Add a loading state while checking access
  }

  return isManager ? children : null;
};

export default ManagerRoute;
