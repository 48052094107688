import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const Compliance = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      goToQuiz(); // Navigate to the quiz page
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulesix/compliance-quiz'); // Navigate to the Compliance Quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/modulesix/privacy'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Compliance for Businesses</h2>
      </div>
      <br />

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/ComplianceModuleSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Understanding Organisational Cybersecurity Policies</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Overview of Company Policies:</strong> The organisation's cybersecurity policies are designed to protect its assets, data, and reputation. These policies include guidelines on acceptable use, password management, incident reporting, and more.</li>
              
              <br />
            
                <li><strong style={{ color: 'salmon' }}>Employee Responsibilities:</strong> Compliance with these policies is mandatory for all employees. This includes adhering to guidelines for secure data handling, reporting any suspicious activities, and following best practices for online security.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Adhering to Compliance Requirements</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Legal and Regulatory Compliance:</strong> Adhering to legal requirements, such as GDPR, is critical for protecting the organisation from legal risks and maintaining customer trust.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Policy Implementation:</strong> Ensure that all organisational policies are aligned with legal requirements and communicated clearly to employees.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Third-Party Compliance:</strong> Verify that third-party vendors comply with the same standards as your organisation.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Continuous Training and Policy Updates</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Ongoing Cybersecurity Training:</strong> Continuous training ensures that employees remain aware of the latest cybersecurity threats and how to protect the organisation against them.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Staying Updated with Policy Changes:</strong> Regularly review and update organisational policies to address emerging threats and changes in the regulatory environment.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Employee Involvement:</strong> Encourage employees to provide feedback on policies and suggest improvements.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="start-quiz-button-container">
        <button
          className="start-quiz-button"
          onClick={handleNextClick}
        >
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </div>

      
    </div>
  );
};

export default Compliance;
