import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const Phishing = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module3/phishing-quiz'); // Navigate to the quiz page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/malware'); // Replace with the actual previous page route
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Phishing</h2>
            </div>

            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add logo overlay */}
              <video
                ref={videoRef}
                src="/assets/videos/Phishing.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>

            
          </div>
        </div>
        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Email Security</h2>
          </section>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if the email is legitimate or illegitimate.</span></strong></p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> support@brambletonbank.com</p>
              <br />
              <p><strong>Subject:</strong> Important: Verify Your Account Information</p>
              <br />
              <p>Dear Barbera,</p>
              <br />
              <p>
                We have detected unusual activity on your Brambleton Bank account. For your security, please <strong><span className="tooltip-link"><strong><span style={{ color: 'blue' }}>Click Here</span></strong><span className="tooltip-text">https://brambletonbank-verify.com</span></span></strong> to verify your account information immediately. Failure to verify your information may result in a temporary hold on your account.
              </p>
              <br />
              <p>Thank you for your prompt attention to this matter.</p>
              <br />
              <p>Sincerely,</p>
              <p>Brambleton Bank Support Team</p>
              <br />
            </div>
            <div className="answer-buttons">
              <button onClick={() => handleAnswer('legitimate')}>Legitimate</button>
              <button onClick={() => handleAnswer('illegitimate')}>Illegitimate</button>
            </div>
            {isAnswered && (
              <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
                {isCorrect ? 'Well done! This is a Phishing Email' : 'Wrong answer.'}
              </div>
            )}
          </div>

          {isAnswered && (
            <div className="phishing-signs">
              <h4>What is a Phishing Email?</h4>
              <p>A phishing email is a scam where cybercriminals pose as legitimate organisations, like banks or online stores, to steal personal information such as passwords or credit card details. These emails often look convincing but usually contain signs like unusual sender addresses, urgent threats, and suspicious links or attachments. While some use generic greetings like 'Dear Customer,' more targeted attacks, known as spear phishing, use specific names, such as Barbera's in the example above. Always verify the sender and avoid clicking on links or providing personal information via email, as legitimate organisations will never ask for sensitive information this way.</p>
              <h4>Signs of a Phishing Attempt:</h4>
              <ul>
                <li><strong>Email Address:</strong> The sender's email address (support@brambletonbank.com) may appear legitimate, but always verify the actual domain carefully.</li>
                <br />
                <li><strong>Urgency:</strong> The email creates a sense of urgency, pressuring you to act quickly.</li>
                <br />
                <li><strong>Suspicious Link:</strong> The link provided does not lead to the official Brambleton Bank website.</li>
                <br />
                <li><strong>Generic Greeting:</strong> The email may use a generic greeting instead of your actual name. However if the hacker has more information and the attack is more targeted they may use your actual name, this is called spear phishing. </li>
                <br />
                <li><strong>HTTPS:</strong> The link in the email uses HTTPS, which might give the impression that it's legitimate. However, as demonstrated in the email above, HTTPS alone does not guarantee that the website you are visiting is authentic.</li>

              </ul>
            </div>
          )}
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>Different types of phishing</h3>
            <p>Phishing can take various forms, such as phishing emails, smishing (SMS phishing), and vishing (voice phishing). Signs of phishing include unsolicited messages, urgent requests for personal information, and suspicious links or attachments. You advise Barbara not to respond to the email and to mark the sender as junk or spam and delete it.</p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Start Quiz</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

      
    </div>
  );
};

export default Phishing;
