import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Adjust the import path as needed
import './WhatIsCyberSecurity.css'; // Ensure this file exists

const SocialMediaSafetyQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(12).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(12).fill(null));
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const questions = [
    {
      question: "What should you set your profile visibility to for better privacy?",
      options: [
        "Public",
        "Friends only",
        "Friends of friends",
      ],
      correctAnswer: "Friends only"
    },
    {
      question: "Is it a good idea to post your home address publicly on social media?",
      options: [
        "Yes, it's safe",
        "No, it's risky",
        "Only if you trust your followers",
        "It's fine during the day"
      ],
      correctAnswer: "No, it's risky"
    },
    {
      question: "What is one benefit of enabling two-factor authentication?",
      options: [
        "Easier to remember passwords",
        "Increases account security",
        "Allows more friends to find you",
        "Automatically updates your status"
      ],
      correctAnswer: "Increases account security"
    },
    {
      question: "Why should you avoid using weak passwords?",
      options: [
        "They are hard to remember",
        "They can be easily guessed",
        "They take up more space",
        "They look unprofessional"
      ],
      correctAnswer: "They can be easily guessed"
    },
    {
      question: "What should you do before opening email links?",
      options: [
        "Check the sender's address",
        "Forward the email to friends",
        "Delete the email",
        "Reply to the email"
      ],
      correctAnswer: "Check the sender's address"
    },
    {
      question: "Is it safe to share your travel plans in real-time on social media?",
      options: [
        "Yes, it's exciting",
        "No, it can expose your location",
        "Only with close friends",
      ],
      correctAnswer: "No, it can expose your location"
    },
    {
      question: "Why should you update your privacy settings to hide personal details?",
      options: [
        "To reduce spam",
        "To protect your personal information",
        "To save storage space as it's limited",
        "To make new friends"
      ],
      correctAnswer: "To protect your personal information"
    },
    {
      question: "Should you accept friend requests from unknown people?",
      options: [
        "Yes, to make new friends as you want to be more popular",
        "It is advised not too without confirmation on who the are",
        "Only if they have mutual friends",
        "It's fine if they look trustworthy"
      ],
      correctAnswer: "It is advised not too without confirmation on who the are"
    },
    {
      question: "Is clicking on links in the comment section safe?",
      options: [
        "Yes, as it is on social media it is fine",
        "No, it can be risky",
        "Only if the comment is positive",
        "It's fine on verified accounts"
      ],
      correctAnswer: "No, it can be risky"
    },
    {
      question: "Why should you avoid sharing sensitive personal information publicly?",
      options: [
        "It's too much information",
        "It can be exploited",
        "It looks unprofessional",
        "It takes up too much space"
      ],
      correctAnswer: "It can be exploited"
    },
    {
      question: "What should you do before posting on social media?",
      options: [
        "Think twice",
        "Post immediately",
        "Ask a friend",
        "Ignore the content"
      ],
      correctAnswer: "Think twice"
    },
    {
      question: "How can verifying the sender's address help you?",
      options: [
        "By confirming the legitimacy of the email",
        "By reducing the number of emails",
        "By increasing your email storage",
        "By automatically replying to the email"
      ],
      correctAnswer: "By confirming the legitimacy of the email"
    }
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login'); // Redirect to login if the user is not authenticated
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile and show the star
    if (newScore >= 9 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module6': true // Field to track quiz completion
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(12).fill(null));
    setFeedback(Array(12).fill(null));
    setCorrectAnswers(Array(12).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
    setScore(0);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security'); // Replace this with the actual link when available
  };

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety-part-two');
  };

  if (!authChecked || shuffledQuestions.length === 0) {
    return <div>Loading...</div>; // Show loading until auth is checked or questions are shuffled
  }

  return (
    <div className="cybersecurity-basics-container">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button> <br></br>
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Social Media Safety Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex].question}</p>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 12</p>
              {score >= 9 ? (
                <p>Congratulations! You passed the quiz and successfully learned about social media safety.</p>
              ) : (
                <p>You need to score at least 9 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 9 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        
      </section>
    </div>
  );
};

export default SocialMediaSafetyQuiz;
