import React, { useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './CoursesTwo.css'; // Import the CSS file
import coursetwo from '../assets/coursetwopic.webp'; // Import the image
import courseone from '../assets/courseoneimage.webp'; // Import the image
import businesspicture from '../assets/businesspicture.webp'; // Import the image
import Footer from './Footer/Footer';

const CoursesTwo = () => {
  const videoRef = useRef(null);
  const { hash } = useLocation(); // Get the current URL hash

  useEffect(() => {
    const videoElement = videoRef.current;

    // Event listener to stop the video 2 seconds before it ends
    const handleTimeUpdate = () => {
      if (videoElement.duration - videoElement.currentTime <= 1) {
        videoElement.pause();
      }
    };

    if (videoElement) {
      // Add event listener for time update
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Cleanup: remove event listener when component is unmounted
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  // Effect to handle scroll to section based on URL hash
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
      {/* First Section */}
<section className="section-courses">
  <div className="content-wrapper-courses">
    <h2 className="section-title-courses">Explore Our Cyber Shield Courses</h2>
    
    {/* New Container for Section Subtitle */}
    <div className="section-subtitle-container">
      <p className="section-subtitle-courses">
        Explore our expertly designed courses, crafted by industry professionals to equip you 
        with essential cybersecurity knowledge. The introductory course Cyber Shield provides 
        a foundational understanding of cybersecurity principles, while subsequent courses 
        expand and deepen your expertise, preparing you to navigate and protect against 
        digital threats with confidence.
      </p>
    </div>
    
  </div>
  
</section>


      {/* Course 1 */}
      <section id="section-2-courses" className="section-2-courses">
        <div className="content-wrapper-course-one">
          <div className="image-container-course-one">
            <img src={courseone} alt="CyberShield Basics" className="tutorial-image-course-one" />
          </div>
          <div className="content-container-course-one">
            <h2 className="section-title-course-one">CyberShield Basics</h2>
            <p className="section-subtitle-course-one">
               This course offers <strong style={{ color: '#FF6F61', fontWeight: 'bold' }}>foundational</strong> training for anyone looking to enhance their cybersecurity skills. Step into the role of a cyber detective and safeguard the town of Brambleton and its residents from cybercrime!
           </p>


           <ul className="course-summary" style={{ listStyle: 'none', lineHeight: '2', paddingLeft: '0', fontSize: '20px' }}>
  {[
    { text: 'Essential cybersecurity concepts', bold: true },
    { text: 'Recognise threats and identify malware' },
    { text: 'Create strong passwords for better security', bold: true },
    { text: 'Ensure social media and email safety' },
    { text: 'Learn about two-factor authentication (2FA)', bold: true },
    { text: 'Practice safe browsing techniques' },
    { text: 'Understand how to verify links for safety', bold: true },
  ].map((item, index) => (
    <li key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
      <span style={{ marginTop: '5px' }}>✔️</span>
      <span style={{ marginLeft: '10px' }}>
        {item.bold ? <strong>{item.text}</strong> : item.text}
      </span>
    </li>
  ))}
</ul>


            <Link to="/beginnerscourse/page1/introduction">
              <button className="begin-button-course-one">Begin Course</button>
            </Link>
          </div>
        </div>
      </section>

      

      {/* Course 2 */}
      <section id="section-3-courses" className="section-3-courses">
        <div className="content-wrapper-course-two">
          <div className="content-container-course-two">
            <h2 className="section-title-course-two">CyberShield Intermediate</h2>
            <p className="section-subtitle-course-two">
                This course builds upon the previous material, focusing on the continued defense of Brambleton which is now being targeted by the hacking group 'Mayhem.''
            </p>

            <ul className="course-summary" style={{ listStyle: 'none', lineHeight: '2', paddingLeft: '0', fontSize: '20px' }}>
  {[
    { text: 'Understand the role of cybersecurity', bold: true },
    { text: 'Learn about Malware, Phishing, and Ransomware' },
    { text: 'Secure mobile devices and home networks', bold: true },
    { text: 'Practice safe use of Public Wi-Fi and data backup' },
    { text: 'Protect your credit profile from cyber threats', bold: true },
    { text: 'Learn how to secure your home network' },
    { text: 'Understand the importance of backing up your data', bold: true },
  ].map((item, index) => (
    <li key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
      <span style={{ marginTop: '5px' }}>✅</span>
      <span style={{ marginLeft: '10px' }}>
        {item.bold ? <strong>{item.text}</strong> : item.text}
      </span>
    </li>
  ))}
</ul>





            <Link to="/beginnerscourseparttwo/introduction/introduction-part-two">
              <button className="begin-button-course-two">Begin Course</button>
            </Link>
          </div>
          <div className="image-container-course-two">
            <img src={coursetwo} alt="CyberShield Intermediate" className="tutorial-image-course-two" />
          </div>
        </div>
      </section>

      

      {/* Course 3 */}
      <section id="section-4-courses" className="section-4-courses">
        <div className="content-wrapper-course-three">
          <div className="image-container-course-three">
            <img src={businesspicture} alt="CyberShield Business Academy" className="tutorial-image-course-three" />
          </div>
          <div className="content-container-course-three">
            <h2 className="section-title-course-three">CyberShield: Business Academy</h2>
             <p className="section-subtitle-course-three">
               CyberShield Business Academy provides <span className="bold-salmon">cybersecurity training tailored for employees</span>. This course builds upon previous material, focusing on specific challenges faced by employees and organisations, enhancing workplace security awareness, and teaching best practices to protect both personal and professional digital environments.
             </p>

                 <ul className="course-summary" style={{ listStyle: 'none', lineHeight: '2', paddingLeft: '0', fontSize: '20px' }}>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>✔️<span style={{ marginLeft: '10px' }}><strong>Understand the importance of  cybersecurity in the workplace</strong></span></li>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>✔️<span style={{ marginLeft: '10px' }}>Learn to identify and defend against cyber threats such as phishing, smishing, and vishing</span></li>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>✔️<span style={{ marginLeft: '10px' }}><strong>Develop skills to secure work devices and data</strong></span></li>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>✔️<span style={{ marginLeft: '10px' }}>Apply best practices for remote work and collaboration tools</span></li>
                    <li style={{ display: 'flex', alignItems: 'flex-start' }}>✔️<span style={{ marginLeft: '10px' }}><strong>Foster a security-first mindset to protect sensitive information</strong></span></li>
                    </ul>


            <Link to="/businessacademy/moduleone/intro">
              <button className="begin-button-course-three">Begin Course</button>
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default CoursesTwo;
