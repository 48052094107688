import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Adjusted path

const DataBackupPartTwo = () => {
  const navigate = useNavigate();

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup-quiz'); 
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup'); 
  };

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <h2>Implementing a Basic Backup Plan</h2>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>Step 1: Identify Important Data:</strong> Determine which files and folders are crucial to back up (e.g., documents, photos, videos, emails).</p>
                </div>
              </div>
            </div>
          </div>
        </section>

    

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>Step 2: Select Backup Tools and Services:</strong></p>
                  <p><strong>Local Backup Tools:</strong> External hard drives, USB flash drives, Network Attached Storage (NAS)</p>
                  <p><strong>Cloud Backup Services:</strong> Google Drive, Dropbox, OneDrive, iCloud</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>Step 3: Schedule Regular Backups:</strong> Automate backups to occur at regular intervals (e.g., daily, weekly).</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>Step 4: Verify Backups:</strong> Regularly check your backups to ensure data is being saved correctly and can be restored.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <button className="next-button" onClick={goToNext}>Start Quiz</button>
        
        
      </section>
    </div>
  );
};

export default DataBackupPartTwo;
