import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const WhatIsCyberSecurityTwo = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduleone/intro-quiz'); // Navigate to the quiz page
    } else {
      setShowWarning(true); // Show warning if the video is not fully watched
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/intro'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <h2>Business Academy</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/WhatisCybersecurity.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
  <div className="highlighted-section">
    <h3>What is Cybersecurity and Why it is Critical for Businesses</h3> <br></br>
    <ul>
      <li>Protecting sensitive data (customer information, intellectual property, financial data).</li>
      <li>Maintaining business continuity and avoiding operational disruptions.</li>
      <li>Safeguarding the company's reputation and trustworthiness.</li>
      <li>Compliance with legal and regulatory requirements (e.g., GDPR).</li>
    </ul>
  </div>
</div>

<div className="module-content">
  <div className="highlighted-section">
    <h3>Consequences of Poor Cybersecurity</h3><br></br>
    <ul>
      <li>Data breaches and financial loss.</li>
      <li>Legal ramifications and fines.</li>
      <li>Damage to brand reputation.</li>
      <li>Loss of customer trust.</li>
    </ul>
  </div>
</div>


        <button className="start-quiz-button" onClick={handleNextClick}>
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </section>

     
    </div>
  );
};

export default WhatIsCyberSecurityTwo;
