import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Adjust the path for the CSS file

const SecuringWorkDevices = () => {
  const navigate = useNavigate();

  const goToQuiz = () => {
    navigate('/businessacademy/modulethree/securing-work-devices-quiz'); // Navigate to the quiz page (if applicable)
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/insider-threats'); // Navigate to the previous page
  };

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button><br></br>
      <div className="cybersecurity-basics-header">
        <h2>Securing Work Devices</h2>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
        
          <p>
            Work devices such as laptops, smartphones, and tablets are integral to daily operations in any organisation. However, they also represent a significant security risk if not properly secured. This section will focus on practical steps that employees can take to safeguard these devices against potential threats.
          </p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Key Points</h3>
          <p>
          <ul>
              <br />
              <li><strong style={{ color: 'salmon' }}>Password Protection:</strong> Ensure that all devices are secured with strong, unique passwords. Utilise biometric authentication (e.g., fingerprint or facial recognition) where available.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Encryption:</strong> Use encryption tools to protect sensitive data stored on devices. This ensures that even if a device is lost or stolen, the data remains inaccessible.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Regular Updates:</strong> Keep operating systems, applications, and antivirus software up to date to protect against the latest vulnerabilities.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Device Locking:</strong> Always lock your device when left unattended. Configure devices to automatically lock after a short period of inactivity. This can help prevent unauthorised access.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Remote Wiping Capabilities:</strong> Enable remote wipe functionality on mobile devices to erase data if the device is lost or stolen.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Physical Security:</strong> Avoid leaving devices unattended in public places and always store them securely when not in use.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Avoid Personal Use:</strong> Minimise personal use of work devices to reduce the risk of inadvertently introducing security risks.</li>
              <br />
              <li><strong style={{ color: 'salmon' }}>Avoid plugging personal flash drives into work devices: </strong> They can carry malware or viruses, posing cybersecurity risks. This can lead to data breaches, unauthorised access, and disruption of your organisation's systems.</li>
             </ul>
             


          </p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Why It Matters</h3>
          <p>
            In the UK, data protection laws such as GDPR require organisations to take adequate measures to protect personal and sensitive data. Failure to secure work devices can lead to data breaches, resulting in legal penalties and damage to the organisation’s reputation.
          </p>
        </div>
      </div>

      <button className="start-quiz-button" onClick={goToQuiz}>Start Quiz</button>

      
    </div>
  );
};

export default SecuringWorkDevices;
