import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import './WhatIsCyberSecurity.css'; // Ensure this file exists

const IntroQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(8).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(8).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(8).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "Why is cybersecurity critical for businesses?",
      options: [
        "To protect sensitive data",
        "To install games",
        "To update social media",
        "To free up memory"
      ],
      correctAnswer: "To protect sensitive data"
    },
    {
      question: "What is one of the consequences of poor cybersecurity?",
      options: [
        "Increased speed",
        "Data breaches and financial loss",
        "Free internet access",
        "Longer battery life"
      ],
      correctAnswer: "Data breaches and financial loss"
    },
    {
      question: "Why is it important to maintain business continuity?",
      options: [
        "To avoid operational disruptions",
        "To save data",
        "To increase battery life",
        "To make your device look better"
      ],
      correctAnswer: "To avoid operational disruptions"
    },
    {
      question: "How does safeguarding the company's reputation help?",
      options: [
        "By providing free internet",
        "By maintaining trustworthiness",
        "By speeding up your device",
        "By disabling firewalls"
      ],
      correctAnswer: "By maintaining trustworthiness"
    },
    {
      question: "What is GDPR?",
      options: [
        "A data protection regulation",
        "A type of malware",
        "An antivirus software",
        "A social media platform"
      ],
      correctAnswer: "A data protection regulation"
    },
    {
      question: "What can result from legal non-compliance?",
      options: [
        "Increased speed",
        "Legal ramifications and fines",
        "Free internet access",
        "Longer battery life"
      ],
      correctAnswer: "Legal ramifications and fines"
    },
    {
      question: "What can damage the brand reputation?",
      options: [
        "Good customer service",
        "Cybersecurity breaches",
        "High-quality products",
        "Efficient operations"
      ],
      correctAnswer: "Cybersecurity breaches"
    },
    {
      question: "Why is customer trust important?",
      options: [
        "To prevent unauthorised access",
        "To connect faster",
        "To free up memory",
        "To increase battery life"
      ],
      correctAnswer: "To prevent unauthorised access"
    }
  ];


  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
if (newScore >= 6 && user) {
  try {
    const userDocRef = doc(firestore, 'users', user.uid);
    await updateDoc(userDocRef, {
      'progress.course3.module1_quiz1': true // Update Firestore to mark the module 1 quiz 1 in course 3 as completed
    });
    console.log('User profile updated with quiz completion.');
    setShowStar(true); // Trigger the star animation
  } catch (error) {
    console.error('Error updating user profile: ', error);
  }
}

  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(8).fill(null));
    setFeedback(Array(8).fill(null));
    setCorrectAnswers(Array(8).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/moduleone/statistics'); // Replace this with the actual link when available
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/intro');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Understanding Organisational Cybersecurity Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 8</p>
              {score >= 6 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of organisational cybersecurity.</p>
              ) : (
                <p>You need to score at least 6 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 6 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default IntroQuiz;
