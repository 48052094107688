import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const Smishing = () => {
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState([false, false, false, false]);
  const [isCorrect, setIsCorrect] = useState([null, null, null, null]);

  const messages = [
    {
      id: 1,
      from: '07700 900123',
      message: `Bank Alert: Your account has been temporarily suspended due to suspicious activity. Please verify your account immediately by clicking on this link: <span class="tooltip">click here<span class="tooltiptext">https://0nline-bank.com</span></span>`,
      isLegitimate: false,
    },
    {
      id: 2,
      from: '03457740740',
      message: `Your Royal Mail package has been shipped. Track your delivery by clicking on this link: <span class="tooltip">click here<span class="tooltiptext">https://www.royalmail.com</span></span>`,
      isLegitimate: true,
    },
    {
      id: 3,
      from: '0808 060 0802',
      message: `Vodafone: Your bill is ready. View your bill by clicking on this link: <span class="tooltip">click here<span class="tooltiptext">https://www.vodafone.co.uk/</span></span>`,
      isLegitimate: true,
    },
    {
      id: 4,
      from: '07700 900456',
      message: `You've won a free gift card! Claim your prize now by clicking on this link: <span class="tooltip">click here<span class="tooltiptext">http://allfr33gifts.com</span></span>`,
      isLegitimate: false,
    },
  ];

  const handleAnswer = (event, index, answer) => {
    event.preventDefault(); // Prevent any default behavior
    const newIsAnswered = [...isAnswered];
    const newIsCorrect = [...isCorrect];
    newIsAnswered[index] = true;
    newIsCorrect[index] = answer === (messages[index].isLegitimate ? 'legitimate' : 'illegitimate');
    setIsAnswered(newIsAnswered);
    setIsCorrect(newIsCorrect);
  };

  const goToQuiz = (event) => {
    event.preventDefault();
    navigate('/businessacademy/moduletwo/vishing'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/spear-phishing-task-two'); // Navigate to the previous page
  };

  return (
    <div className="cybersecurity-basics-container">
          <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Smishing</h2>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
          </section>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if each SMS is legitimate or illegitimate.</span></strong></p>
              </div>
            </div>
          </div>

          {messages.map((message, index) => (
            <div className="sms-example-box white-background-container" key={message.id}>
              <h3>Example SMS {index + 1}</h3>
              <div className="sms-content">
                <p><strong>From:</strong> {message.from}</p>
                <br />
                <p><strong>Message:</strong> <br /><span dangerouslySetInnerHTML={{ __html: message.message }}></span></p>
                <br />
              </div>
              <div className="answer-buttons">
                <button onClick={(event) => handleAnswer(event, index, 'legitimate')} disabled={isAnswered[index]}>Legitimate</button>
                <button onClick={(event) => handleAnswer(event, index, 'illegitimate')} disabled={isAnswered[index]}>Illegitimate</button>
              </div>
              {isAnswered[index] && (
                <div className={`feedback ${isCorrect[index] ? 'correct' : 'incorrect'}`}>
                  {isCorrect[index] ? 'Well done!' : 'Wrong answer.'}
                </div>
              )}
              {isAnswered[index] && (
                <div className="sms-legitimate">
                  <h4>{message.isLegitimate ? 'Why is this SMS legitimate?' : 'Why is this SMS a smishing attempt?'}</h4>
                  <ul>
                    {message.isLegitimate ? (
                      <>
                        <li><strong>Sender's Number:</strong> While the sender's number is recognised and associated with a legitimate service, keep in mind that numbers can be spoofed. This tactic is used to deceive the recipient into trusting the call or message.</li>
                        <li><strong>Content:</strong> The message provides clear information about a service you are expecting.</li>
                        <li><strong>Link:</strong> The link provided leads to a legitimate website.</li>
                      </>
                    ) : (
                      <>
                        <li><strong>Sender's Number:</strong> The sender's number is not associated with a legitimate contact.</li>
                        <li><strong>Urgency:</strong> The message creates a false sense of urgency or excitement.</li>
                        <li><strong>Suspicious Link:</strong> The link provided does not lead to an official website but rather to a suspicious URL.</li>
                        <li><strong>Unusual Request:</strong> Legitimate organisations typically do not request sensitive information via SMS.</li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        <button className="start-quiz-button" onClick={goToQuiz}>Next</button>
      </section>

      
    </div>
  );
};

export default Smishing;
