import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Adjusted path

const PublicWifiPartTwo = () => {
  const navigate = useNavigate();

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi-quiz'); 
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi'); 
  };

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button> <br></br>
      <section className="module-section">
        <h2>Best Practices for Safe Use of Public Wi-Fi</h2>
        
        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>1. Verify the Network:</strong> Always confirm the network name with staff before connecting. Avoid networks with generic names like “Free Wi-Fi” or “Public Wi-Fi.”</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>2. Use a VPN (Virtual Private Network):</strong> A VPN encrypts your internet connection, providing a secure tunnel for data transmission. Choose reputable VPN services such as NordVPN, ExpressVPN, or CyberGhost.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>3. Use HTTPS Websites:</strong> Ensure websites you visit use HTTPS, indicated by a padlock icon in the browser's address bar, to encrypt data transmitted between your browser and the website.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>4. Keep Software Updated:</strong> Regularly update your operating system, browser, and security software to protect against vulnerabilities.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>5. Avoid Sensitive Transactions:</strong> Refrain from accessing sensitive accounts (e.g., banking, email) and conducting financial transactions on public Wi-Fi.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item text2">
                  <p><strong style={{ color: 'salmon' }}>6. Log Out After Use:</strong> Always log out of websites and services when you are done using them on public Wi-Fi.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <button className="next-button" onClick={goToNext}>Next</button>
       
      </section>
    </div>
  );
};

export default PublicWifiPartTwo;
