import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Updated path for the CSS file



const MobileSecurity = () => {
  const navigate = useNavigate();
  

 

  const goToQuiz = () => {
    navigate('/beginnerscourseparttwo/module4/poster'); 
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/ransomware'); // Replace with the actual previous page route
  };

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Mobile Device Security </h2>
            </div>
            <div className="bordered-content">
              <p>Mobile devices are essential tools in the daily lives of the residents of Brambleton. Storing vast amounts of personal and sensitive information. Ensuring their security is crucial to protect against data breaches, financial loss, and privacy invasions. Please read the poster I have created on the importance of mobile device security. 
              </p>
              
            </div>
          </div>
        </div>

        <button className="start-quiz-button" onClick={goToQuiz}>Poster</button>
      </section>

      
    </div>
  );
};

export default MobileSecurity;
