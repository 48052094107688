import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAuth, applyActionCode } from 'firebase/auth';
import './EmailVerification.css';


const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('Verifying your email, please wait...');
  const auth = getAuth();

  useEffect(() => {
    const oobCode = searchParams.get('oobCode');
    const mode = searchParams.get('mode');

    if (mode === 'verifyEmail' && oobCode) {
      const verifyEmail = async () => {
        try {
          await applyActionCode(auth, oobCode);
          setMessage('Your email has been successfully verified. You can now log in.');
        } catch (error) {
          console.error('Email verification error:', error);
          setMessage('Error verifying email. The link may have expired or is invalid.');
        }
      };

      verifyEmail();
    } else {
      setMessage('Invalid or missing verification code.');
    }
  }, [auth, searchParams]);

  return (
    <div className="verification-container">
      <h1>Email Verification</h1>
      <p>{message}</p>
    </div>
  );
};

export default EmailVerification;
