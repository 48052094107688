import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const RemoteWorkSecurity = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark the video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      goToQuiz(); // Navigate to the quiz page
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulefive/remote-work-security-quiz'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/modulefour/reporting-procedures'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Remote Work Security</h2>
      </div>
      <br />

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/RemoteWorkSecurityModuleFive.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Secure Remote Access</h3> <br />
          <p>Best Practices for Accessing Company Resources Remotely:</p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Use of Virtual Private Networks (VPNs)</h3> <br />
          
            <ul>
              <li><strong style={{ color: 'salmon' }}>Mandatory VPN Use:</strong> Always connect to the organisation's VPN when accessing company resources from a remote location. A VPN encrypts your internet connection, ensuring that data transmitted between your device and the company network is secure from potential eavesdroppers.</li>  <br />
              <li><strong style={{ color: 'salmon' }}>Regular VPN Updates:</strong> Ensure that your VPN client is always up-to-date with the latest patches and security updates provided by the IT department.</li>
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Multi-Factor Authentication (MFA)</h3> <br />
         
            <ul>
              <li><strong style={{ color: 'salmon' }}>Enable MFA:</strong> Use multi-factor authentication for all remote access points. MFA adds an extra layer of security by requiring not just a password, but also a second form of verification, such as a code sent to your mobile device.</li> <br />
              <li><strong style={{ color: 'salmon' }}>Consistent Use:</strong> Make MFA a consistent part of your login routine, whether accessing email, cloud storage, or any other company resource.</li>
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Secure Password Management</h3> <br />
         
            <ul>
              <li><strong style={{ color: 'salmon' }}>Strong Passwords:</strong> Use strong, unique passwords for all accounts, especially those used for accessing company resources. Consider using a password manager to keep track of complex passwords.</li>  <br />
              <li><strong style={{ color: 'salmon' }}>Regular Password Changes:</strong> Change your passwords regularly, particularly for accounts that access sensitive company information. Using a password manager makes this process much easier.</li>
            </ul>
         
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Access Control</h3> <br />
         
            <ul>
              <li><strong style={{ color: 'salmon' }}>Least Privilege Principle:</strong> Only access the information and systems necessary for your work. Avoid accessing or downloading sensitive data unless absolutely required for your tasks.</li> <br />
             <li><strong style={{ color: 'salmon' }}>Log Off When Not in Use:</strong> Always log off from company systems when you are not actively using them, especially if you are in a shared or public space.</li>
            </ul>
         
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Router</h3> <br />
          
            Make sure to replace your router at least every four years to stay up-to-date with the latest technology and security features. Regularly upgrading your router helps protect your network from emerging threats, ensures compatibility with new devices, and provides better overall performance. By keeping your router current, you reduce the risk of vulnerabilities and maintain a secure, reliable connection for all your online activities.
          
        </div>
      </div>

      <div className="start-quiz-button-container">
        <button
          className="start-quiz-button"
          onClick={handleNextClick}
        >
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </div>

   
    </div>
  );
};

export default RemoteWorkSecurity;
