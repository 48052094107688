import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Firebase Firestore instance
import './TopBar.css';

const TopBar = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out successfully');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userProfileDoc = await getDoc(doc(firestore, 'users', user.uid));
          const userProfileData = userProfileDoc.data();
          setUser({ name: user.displayName || 'Anonymous', photoURL: user.photoURL || 'default-profile-pic-url.jpg' });

          let accountTypeArray = [];
          if (userProfileData?.profile?.accountType) {
            accountTypeArray = userProfileData.profile.accountType.split(',').map((role) => role.trim());
          }

          setProfile({
            name: userProfileData?.profile?.name || user.displayName || 'Anonymous',
            accountType: accountTypeArray || ['standard'],
            managerId: userProfileData?.profile?.managerId || null,
          });

          if (accountTypeArray.includes('manager') && userProfileData?.profile?.logoURL) {
            setLogoUrl(userProfileData.profile.logoURL);
          } else if (userProfileData?.profile?.managerId) {
            const managerProfileDoc = await getDoc(doc(firestore, 'users', userProfileData.profile.managerId));
            const managerProfileData = managerProfileDoc.data();
            if (managerProfileData?.profile?.logoURL) {
              setLogoUrl(managerProfileData.profile.logoURL);
            }
          }

        } catch (error) {
          console.error('Error fetching user or manager profile data:', error);
        }
      } else {
        setUser(null);
        setProfile(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="topbar-new">
        <NavLink to="/" className="home-link" onClick={closeMenu}>
          <h1>Be Cyber Aware</h1>
        </NavLink>

        {logoUrl && (
          <div className="logo-container">
            <img src={logoUrl} alt="Manager Logo" className="manager-logo" />
          </div>
        )}

        {/* Auth Buttons for large screens */}
        <div className="auth-buttons-new">
          {user ? (
            <>
              {profile && (
                <div className="user-info-new">
                  <NavLink to="/profile" className="user-name-link" onClick={closeMenu}>
                    <span>{profile.name}</span>
                  </NavLink>
                </div>
              )}
              <button className="logout-button-new" onClick={handleLogout}>Log Out</button>
            </>
          ) : (
            <>
              <button className="signup-button-new" onClick={() => navigate('/free-user-signup')}>Sign Up</button>
              <button className="login-button-new" onClick={() => navigate('/login')}>Log In</button>
            </>
          )}
        </div>

        <div className="burger-icon" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </header>

      <nav className={`navigation-new ${isMenuOpen ? 'menu-open' : ''}`}>
        <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>Home</NavLink>
        <NavLink to="/courses-two" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>Courses</NavLink>
        <NavLink to="/about-page" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>About</NavLink>
        <NavLink to="/account-type" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>Plans & Pricing</NavLink>

       

        {user && (
          <>
            <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>Profile</NavLink>
            {profile && profile.accountType.includes('manager') && (
              <NavLink to="/managersportal" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>Managers Portal</NavLink>
            )}
            {profile && profile.accountType.includes('admin') && (
              <NavLink to="/admindashboard" className={({ isActive }) => (isActive ? 'active-link' : '')} onClick={closeMenu}>Admin Dashboard</NavLink>
            )}
          </>
        )}

        {/* Auth Links for small screens (inside menu) */}
        <div className="auth-links">
          {!user ? (
            <>
              <NavLink to="/free-user-signup" onClick={closeMenu}>Sign Up</NavLink>
              <NavLink to="/login" onClick={closeMenu}>Log In</NavLink>
            </>
          ) : (
            <NavLink to="/" onClick={() => { closeMenu(); handleLogout(); }}>Log Out</NavLink>
          )}
        </div>
      </nav>
    </>
  );
};

export default TopBar;
