import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path


const SocialMediaSafety = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page6/social-media-safety-part-two'); // Navigate to the next page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page5.5/checking-urls'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <div className="BasicsTitle">Social Media Safety</div>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/PageSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture // Disable Picture-in-Picture
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2>Mission: Adjusting Privacy Settings</h2>
        </section>

        <div className="bordered-container">
          <div className="grid-item text">
            <div className="task">
              <div className="salmon-text">Task 1: Control Who Can See Your Information</div>
              <br></br>
              <p>
                <strong className="salmon-text">Objective:</strong> Check your social media privacy settings to see if your accounts are private or public. It's important to make your accounts private to protect your personal information from strangers and potential cyber threats. By restricting who can see your posts and details, you reduce the risk of identity theft, online harassment, and unauthorized access to your data. This ensures that only trusted individuals can view your content, helping to maintain your safety and privacy online. If you choose to keep it public, think twice before you post.
              </p>
              <p>
                <strong className="salmon-text">Action:</strong> Check out and become familiar with the privacy settings on your social media accounts. There are useful features such as setting your profile to be visible only to friends and restrict public access. You also can adjust the settings to hide your phone number and email address and other personal data from public view.
              </p>
              <p>
                <strong className="salmon-text">Outcome:</strong> Your social media accounts are more secure, with limited public access to your personal information.
              </p>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button className="next-button" onClick={goToNext}>Next</button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>

       
      </section>
    </div>
    
  );
};

export default SocialMediaSafety;
