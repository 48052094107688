import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './WhatIsCyberSecurity.css'; // Ensure this file exists

const ImportanceCyberQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(12).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(12).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "Why is personal cybersecurity crucial in the digital age?",
      options: [
        "To develop new software applications quickly and efficiently",
        "To enhance online shopping experiences to make it convenient for the user",
        "To protect communication, entertainment, work, and financial transactions"
      ],
      correctAnswer: "To protect communication, entertainment, work, and financial transactions"
    },
    {
      question: "What type of personal information is valuable to cybercriminals?",
      options: [
        "Credit card details",
        "Favorite movies and books",
        "Holiday destinations"
      ],
      correctAnswer: "Credit card details"
    },
    {
      question: "How can cybersecurity measures help with financial security?",
      options: [
        "By providing online shopping discounts",
        "By protecting financial assets and transactions from cyber attacks",
        "By offering financial advice"
      ],
      correctAnswer: "By protecting financial assets and transactions from cyber attacks"
    },
    {
      question: "What is identity theft?",
      options: [
        "When someone uses another person's personal information without permission to commit fraud",
        "When someone sets up a fake social media account pretending to be someone else",
        "When someone hacks your social media account and changes the passwords locking you out"
      ],
      correctAnswer: "When someone uses another person's personal information without permission to commit fraud"
    },
    {
      question: "Why is safeguarding digital assets important?",
      options: [
        "Because they are often irreplaceable",
        "Because they are always backed up",
        "Because they are not important"
      ],
      correctAnswer: "Because they are often irreplaceable"
    },
    {
      question: "How does cybersecurity help maintain privacy?",
      options: [
        "By sharing personal information it makes it easier to find connections",
        "By controlling personal information and protecting it from unauthorised access",
        "By keeping everything public"
      ],
      correctAnswer: "By controlling personal information and protecting it from unauthorised access"
    },
    {
      question: "What can malware and viruses do to personal devices?",
      options: [
        "Improve their performance, making them more efficient",
        "Cause significant damage, data loss, and reduced functionality",
        "Enables the user to play top end games"
      ],
      correctAnswer: "Cause significant damage, data loss, and reduced functionality"
    },
    {
      question: "Why is ensuring safe online experiences important?",
      options: [
        "To avoid boredom",
        "To protect individuals from online threats",
        "To enhance social media engagement"
      ],
      correctAnswer: "To protect individuals from online threats"
    },
    {
      question: "What is malware?",
      options: [
        "Software that speeds up your device",
        "Malicious software",
        "An antivirus program"
      ],
      correctAnswer: "Malicious software"
    },
    {
      question: "Why is securing home networks important?",
      options: [
        "A secure home network can boost internet speed making it more efficient",
        "To protect against unauthorised access and potential breaches",
        "To allow guests to use the network"
      ],
      correctAnswer: "To protect against unauthorised access and potential breaches"
    },
    {
      question: "How can cybersecurity practices help with extortion and blackmail?",
      options: [
        "By making data public",
        "By safeguarding data and systems to prevent such scenarios",
        "By securing computers so only authorised people have access"
      ],
      correctAnswer: "By safeguarding data and systems to prevent such scenarios"
    },
    {
      question: "What can regular backups and anti-malware protection do?",
      options: [
        "Ensure digital assets are protected from loss or damage",
        "Deletes unnecessary files making the computer more efficient",
        "Slow down the device as it is using up lots of space"
      ],
      correctAnswer: "Ensure digital assets are protected from loss or damage"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (newScore >= 9 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course2.module1_quiz2': true // Update Firestore to mark quiz2 of module1 in course2 as completed
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(12).fill(null));
    setFeedback(Array(12).fill(null));
    setCorrectAnswers(Array(12).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module3/cyber-threats');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber');
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Cybersecurity Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex].question}</p>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 12</p>
              {score >= 9 ? (
                <p>Congratulations! You passed the quiz and successfully taught the residents why cybersecurity is important.</p>
              ) : (
                <p>You need to score at least 9 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 9 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button className="back-button" onClick={goBack}>Exit Quiz</button>
        </div>
      </section>
    </div>
  );
};

export default ImportanceCyberQuiz;
