import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const IncidentsQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null)); // Updated array length
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null)); // Updated array length
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null)); // Updated array length
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "What is the primary objective of incident response and reporting?",
      options: [
        "To punish employees who make mistakes to ensure they do not make the mistake again",
        "To ensure employees know how to handle security incidents",
        "To improve software performance",
        "To increase employee satisfaction"
      ],
      correctAnswer: "To ensure employees know how to handle security incidents"
    },
    {
      question: "Which of the following best defines a security incident?",
      options: [
        "An event that improves system performance",
        "An event that threatens data security",
        "A minor inconvenience to employees",
        "A routine system update"
      ],
      correctAnswer: "An event that threatens data security"
    },
    {
      question: "What is an example of unauthorised access?",
      options: [
        "Accessing personal emails on a work device during work hours",
        "Attempts to access data by unauthorised individuals",
        "Logging in with the correct credentials",
        "Using a work device for personal tasks"
      ],
      correctAnswer: "Attempts to access data by unauthorised individuals"
    },
    {
      question: "What should you be wary of in a phishing attack?",
      options: [
        "Unexpected emails asking for sensitive information",
        "Emails from known contacts",
        "Messages asking to update software",
        "Advertisements in your inbox"
      ],
      correctAnswer: "Unexpected emails asking for sensitive information"
    },
    {
      question: "What can be signs of a malware infection?",
      options: [
        "The device runs faster",
        "The device becomes more secure",
        "Unusual pop ups and slow performance",
        "Increased battery life"
      ],
      correctAnswer: "Unusual pop ups and slow performance"
    },
    {
      question: "Which of the following is considered a data breach?",
      options: [
        "Unauthorised access to confidential information",
        "Sending a non related work email to a colleague",
        "Having a weak password",
        "Accessing public information online"
      ],
      correctAnswer: "Unauthorised access to confidential information"
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module4_quiz1': true, // Update Firestore to mark the quiz of module 4 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null)); // Updated array length
    setFeedback(Array(6).fill(null)); // Updated array length
    setCorrectAnswers(Array(6).fill(null)); // Updated array length
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/modulefour/reporting-procedures'); 
  };



  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Incident Response and Reporting Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 6</p>
              {score >= 4 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of incident response and reporting.</p>
              ) : (
                <p>You need to score at least 4 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
    
      </section>
    </div>
  );
};

export default IncidentsQuiz;
