import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const Privacy = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      goToQuiz(); // Navigate to the quiz page
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulesix/privacy-quiz'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/modulesix/data-protection'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Privacy</h2>
      </div>
      <br />

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/PrivacyModuleSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Privacy in Business Context</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Understanding Privacy:</strong> Privacy involves protecting personal information from unauthorised access and misuse. It's not only a legal requirement but also a cornerstone of trust between the organisation and its clients, customers, and employees.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>GDPR and Privacy:</strong> GDPR emphasises the importance of privacy by giving individuals greater control over their personal data. This includes the right to access, correct, delete, and restrict the processing of their data.</li>
              
              <br />
            
                <li><strong style={{ color: 'salmon' }}>Impact on Businesses:</strong> Protecting privacy helps maintain customer trust, enhances the organisation's reputation, and reduces the risk of legal penalties.</li>
              
              <br />
            
                <li><strong style={{ color: 'salmon' }}>Employee Responsibility:</strong> All employees must handle personal data with care, ensuring that they follow best practices for data privacy at all times.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Data Encryption for Privacy Protection</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Importance of Encryption:</strong> Encryption is essential for protecting sensitive data from unauthorised access. It transforms data into a secure format that can only be accessed by individuals with the appropriate decryption key.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>When to Use Encryption:</strong> Encrypt data when storing it on devices, during transmission (e.g., via email or file transfers), and when archiving sensitive information.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="start-quiz-button-container">
        <button
          className="start-quiz-button"
          onClick={handleNextClick}
        >
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </div>

      
    </div>
  );
};

export default Privacy;
