import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const DataProtection = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      goToQuiz(); // Navigate to the quiz page
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulesix/data-protection-quiz'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/modulefive/collaboration-tools'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Data Protection</h2>
      </div>
      <br />

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/DataProtectionModuleSix.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Understanding Data Protection Regulations</h3> <br />
          
            Overview of GDPR:
            <br />
            <br />GDPR (General Data Protection Regulation) is a key regulation that governs how organisations must handle personal data. It applies to all organisations operating within the EU and the UK GDPR post-Brexit.
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Key Principles</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Lawfulness, Fairness, and Transparency:</strong> Data must be processed legally and transparently.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Purpose Limitation:</strong> Data should only be collected for specific, legitimate purposes.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Data Minimisation:</strong> Only the necessary amount of data should be collected.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Accuracy:</strong> Data must be accurate and kept up to date.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Storage Limitation:</strong> Data should only be retained as long as necessary.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Integrity and Confidentiality:</strong> Data must be protected from unauthorised access and breaches.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Business Implications</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Consent:</strong> Obtain explicit consent before processing personal data.</li>
              
              <br />
            
                <li><strong style={{ color: 'salmon' }}>Rights of Data Subjects:</strong> Ensure that individuals can access, correct, and delete their data.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Breach Notification:</strong> Report data breaches to the Information Commissioner's Office (ICO) within 72 hours if required.</li>
              
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Penalties for Non-Compliance:</strong> Non-compliance can result in significant fines.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Handling and Protecting Business Data</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'salmon' }}>Secure Storage:</strong></li>
              
              <br />
              <ul>
                
                  <li><strong style={{ color: 'salmon' }}>Encryption:</strong> Encrypt sensitive data both at rest and in transit.</li>
                
                <br />
                
                  <li><strong style={{ color: 'salmon' }}>Access Control:</strong> Implement strict access controls to limit data access to authorised personnel.</li>
                
                <br />
                
                  <li><strong style={{ color: 'salmon' }}>Physical Security:</strong> Secure physical storage of sensitive documents and backup drives.</li>
                
              </ul>
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Secure Sharing:</strong></li>
              
              <br />
              <ul>
                
                  <li><strong style={{ color: 'salmon' }}>Email Encryption:</strong> Use encrypted email services for sending sensitive information.</li>
                
                <br />
                
                  <li><strong style={{ color: 'salmon' }}>Secure File Transfer:</strong> Utilise secure file transfer protocols (SFTP) and encrypted cloud services.</li>
                
              </ul>
              <br />
              
                <li><strong style={{ color: 'salmon' }}>Data Disposal:</strong></li>
              
              <br />
              <ul>
                
                  <li><strong style={{ color: 'salmon' }}>Data Destruction:</strong> Securely destroy data that is no longer needed.</li>
                
                <br />
                
                  <li><strong style={{ color: 'salmon' }}>Retention Policies:</strong> Follow data retention policies for timely and secure disposal of data.</li>
                
              </ul>
            </ul>
          
        </div>
      </div>

      <div className="start-quiz-button-container">
        <button
          className="start-quiz-button"
          onClick={handleNextClick}
        >
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </div>

      
    </div>
  );
};

export default DataProtection;
