import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ThreatLandscape.css'; // Adjusted path
import breachesImage from '../assets/Breaches.png'; // Adjusted import path
import disruptive from '../assets/Distruptive.png'; // Adjusted import path
import typesofbreachesbreachesImage from '../assets/TypesOfBreaches.png'; // Adjusted import path
import Footer from '../components/Footer/Footer';

const DividerThreatLandscape = () => (
  <div className="divider-threat-landscape" />
);

const ThreatLandscape = () => {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate('/'); // Navigate to the next page
  };

  return (
    <div className="cybersecurity-basics-container-threat-landscape">
      <section className="module-section-threat-landscape">
        
        <div className="inline-content-threat-landscape">
          <h2>Statistics From Cyber Security Breaches Survey 2024</h2>
          <h4>Published 9 April 2024 - GOV.UK</h4>
        </div>

        <h4 className="figure-heading-threat-landscape">Figure 1 - Types of Breaches or Attacks in the Last 12 Months</h4>
        <img src={typesofbreachesbreachesImage} alt="Cybersecurity Breaches Statistics" className="small-image-threat-landscape" />
        <p className="image-source-threat-landscape">
          Source: <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">Cyber Security Breaches Survey 2024</a>
        </p>

        <div className="bordered-container-threat-landscape">
          <ul>
            <li><strong>Phishing:</strong> The most predominant issue, impacting <strong>84%</strong> of businesses and <strong>83%</strong> of charities.</li>
            <li><strong>Impersonation:</strong> The second most common issue, affecting <strong>35%</strong> of businesses and <strong>37%</strong> of charities.</li>
            <li><strong>Viruses or Other Malware:</strong> Reported by <strong>17%</strong> of businesses and <strong>14%</strong> of charities.</li>
          </ul>
        </div>

        <DividerThreatLandscape />

        <h4 className="figure-heading-threat-landscape">Figure 2 - Percentage of Organisations That Have Identified Breaches or Attacks in the Last 12 Months</h4>
        <img src={breachesImage} alt="Cybersecurity Breaches Statistics" className="small-image-threat-landscape" />
        <p className="image-source-threat-landscape">
          Source: <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">Cyber Security Breaches Survey 2024</a>
        </p>

        <div className="bordered-container-threat-landscape">
          <ul>
            <li>Over the past 12 months, half of all businesses <strong>(50%)</strong> have reported experiencing some form of cyber security breach or attack.</li>
            <li>Approximately one-third of charities <strong>(32%)</strong> have reported experiencing some form of cyber security breach or attack.</li>
            <li>This translates to an estimated <strong>718,000</strong> businesses and <strong>65,000</strong> registered charities affected.</li>
          </ul>
        </div>

        <DividerThreatLandscape />

        <h4 className="figure-heading-threat-landscape">Figure 3 - Most Disruptive Breaches in the Last 12 Months</h4>
        <img src={disruptive} alt="Cybersecurity Breaches Statistics" className="small-image-threat-landscape" />
        <p className="image-source-threat-landscape">
          Source: <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">Cyber Security Breaches Survey 2024</a>
        </p>

        <div className="bordered-container-threat-landscape">
          <ul>
            <li><strong>Figure 3</strong> highlights that phishing attacks were identified as the most disruptive type of attack.</li>
            <li>Phishing attacks were reported by <strong>61%</strong> of businesses and <strong>56%</strong> of charities.</li>
            <li>Additionally, impersonation attacks are the second most common and disruptive form of breach or attack.</li>
          </ul>
        </div>

        <div className="centered-link-threat-landscape">
          <p>For more information, visit the <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">official report</a>.</p>
        </div>

        <div className="start-quiz-button-container-threat-landscape">
          <button className="next-button-threat-landscape" onClick={handleNextClick}>Home</button>
        </div>
        
      </section>
      <Footer />
    </div>
  );
};

export default ThreatLandscape;
