import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported

const PublicWifi = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module6/public-wifi-part-two'); // Navigate to the next page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module5/home-network'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Safe Use of Public Wi-Fi</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add logo overlay */}
              <video
                ref={videoRef}
                src="/assets/videos/SafeWiFiPractices.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>What is Public Wi-Fi?</h3>
            <p>Public Wi-Fi is a wireless network that anyone can connect to, typically provided in places like cafés, airports, hotels, and libraries.</p>
          </div>

          <div className="highlighted-section2">
            <h3>Common Risks</h3>
            <p>
              <strong>Man-in-the-Middle Attacks:</strong><br /> <br />
              Attackers intercept communication between your device and the Wi-Fi network.
            </p>
            <p>
              <strong>Unsecured Networks:</strong><br /> <br />
              Many public Wi-Fi networks lack proper encryption, making data transmission vulnerable.
            </p>
            <p>
              <strong>Malware Distribution:</strong><br /> <br />
              Hackers can use unsecured networks to spread malware to connected devices.
            </p>
            <p>
              <strong>Evil Twin Attacks:</strong><br /> <br />
              Attackers create a fake Wi-Fi network that mimics a legitimate one, tricking users into connecting to it.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Next</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

     
    </div>
  );
};

export default PublicWifi;
