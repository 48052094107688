import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthProvider'; // Update the import path to AuthProvider
import { firestore } from '../../firebase';
import DOMPurify from 'dompurify'; // Add DOMPurify for sanitization
import './AddReport.css'; // Import the CSS file

// Constants for character limits
const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 250;

const AddReport = () => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [threatType, setThreatType] = useState('Phishing'); // New state for threat type
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [titleWarning, setTitleWarning] = useState(''); // Warning for title limit
  const [descriptionWarning, setDescriptionWarning] = useState(''); // Warning for description limit

  // Handle input validation for title
  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    setTitle(inputValue); // Update the state with user input
    if (inputValue.length > MAX_TITLE_LENGTH) {
      setTitleWarning(`Title cannot exceed ${MAX_TITLE_LENGTH} characters.`);
    } else {
      setTitleWarning(''); // Clear the warning if within limit
    }
  };

  // Handle input validation for description
  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue); // Update the state with user input
    if (inputValue.length > MAX_DESCRIPTION_LENGTH) {
      setDescriptionWarning(`Description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters.`);
    } else {
      setDescriptionWarning(''); // Clear the warning if within limit
    }
  };

  const createReport = async (title, description, threatType) => {
    if (!currentUser) {
      setError('You must be logged in to report a threat.');
      return;
    }

    try {
      const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
      const userProfile = userDoc.data();
      let managerId = currentUser.uid;

      if (userProfile && userProfile.profile.accountType === 'standard') {
        managerId = userProfile.profile.managerId; // Use managerId for standard users
      }

      // Sanitize the inputs before sending to Firestore
      const sanitizedTitle = DOMPurify.sanitize(title);
      const sanitizedDescription = DOMPurify.sanitize(description);

      const reportRef = collection(firestore, 'threatReports');
      await addDoc(reportRef, {
        reportedBy: currentUser.uid,
        reportedByEmail: currentUser.email,
        managerId: managerId,
        title: sanitizedTitle,
        description: sanitizedDescription,
        threatType, // Add the selected threat type
        timestamp: serverTimestamp(),
        comments: []
      });

      setSuccess('Report successfully submitted.');
      setTitle('');
      setDescription('');
      setThreatType('Phishing'); // Reset to default
    } catch (err) {
      setError('Failed to submit the report. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.length > MAX_TITLE_LENGTH || description.length > MAX_DESCRIPTION_LENGTH) {
      setError('Please ensure the title and description meet the character limits.');
      return;
    }
    setError(null);
    setSuccess(null);
    createReport(title, description, threatType);
  };

  return (
    <div className="add-report-container">
      <h1>Report a Threat</h1>
      <form onSubmit={handleSubmit} className="add-report-form">
        <div className="form-group">
          <label>Title (max {MAX_TITLE_LENGTH} characters)</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            maxLength={MAX_TITLE_LENGTH} // Enforce maximum character length
            required
          />
          {titleWarning && <p className="warning-text2">{titleWarning}</p>}
        </div>
        <div className="form-group">
          <label>Description (max {MAX_DESCRIPTION_LENGTH} characters)</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            maxLength={MAX_DESCRIPTION_LENGTH} // Enforce maximum character length
            required
          />
          {descriptionWarning && <p className="warning-text2">{descriptionWarning}</p>}
        </div>
        <div className="form-group">
          <label>Threat Type</label>
          <select
            value={threatType}
            onChange={(e) => setThreatType(e.target.value)}
            required
          >
            <option value="Phishing">Phishing</option>
            <option value="Spear Phishing">Spear Phishing</option>
            <option value="Malware">Malware</option>
            <option value="Ransomware">Ransomware</option>
            <option value="Smishing">Smishing</option>
            <option value="Vishing">Vishing</option>
            <option value="Social Engineering">Social Engineering</option>
            <option value="Insider Threat">Insider Threat</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {error && <p className="error-text">{error}</p>}
        {success && <p className="success-text">{success}</p>}
        <button type="submit" className="submit-button22">Submit</button>
      </form>
    </div>
  );
};

export default AddReport;
