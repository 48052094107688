import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DataBackup = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module7/data-backup-part-two'); // Navigate to the next page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi-part-two'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Backing Up Your Data</h2>
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                src="/assets/videos/DataBackup.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <p>
              Aya's files are locked and the Ransomware demands £500 worth of Bitcoin to unlock them. It is never advised to pay the ransom. You follow the <a href="/beginnerscourseparttwo/module7/remove-malware-guide2" className="link-text" target="_blank" rel="noopener noreferrer">How to Get Rid of Malware Guide.</a> Luckily, automatic backups had been set to daily for the PC, so you restored the PC back to the previous day when the Ransomware hadn't compromised the PC. Aya did lose one day's worth of coursework, but she is glad that she didn't lose more.
            </p>
          </div>

          <div className="highlighted-section2">
            <h3>What is Data Backup?</h3>  <br />
            <p>
              Data backup is the process of creating copies of your data to protect it against loss, corruption, or accidental deletion. These copies can be restored in the event of a data loss incident.  <br />  <br />
            </p>
            <h3>Why Backup Your Data?</h3>  <br />
            <p>
              <strong>Protection Against Data Loss:</strong><br /> 
              Prevents loss due to hardware failure, theft, or accidental deletion.  <br />  <br />
            </p>
            <p>
              <strong>Data Recovery:</strong><br />
              Ensures you can recover your files after a malware attack or system crash.  <br />  <br />
            </p>
            <p>
              <strong>Business Continuity:</strong><br />
              Helps maintain operations and services in case of data-related disasters.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Next</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

      
    </div>
  );
};

export default DataBackup;
