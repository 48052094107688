import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../page5/SafeBrowsingGame.css';


const scenarios = [
  // (Your scenarios remain the same)
  {
    text: "What is Two-Factor Authentication (2FA)?"
    ,
    options: [
        { text: "A method to reset your password via email.", isCorrect: false },
        { text: "A type of malware that steals your information.", isCorrect: false },
        { text: "A security layer requiring a password and another factor.", isCorrect: true },
        { text: "A technique for encrypting data during transmission.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. 2FA is not related to password resets.",
        "Incorrect. 2FA is not a type of malware.",
        "Correct! 2FA requires a password and another piece of information to enhance security.",
        "Incorrect. 2FA is not a data encryption technique."
    ]
},
{
    text: "Why is Two-Factor Authentication important?",
    options: [
        { text: "It makes logging in quicker and easier.", isCorrect: false },
        { text: "It eliminates the need to use passwords.", isCorrect: false },
        { text: "It adds an extra defense layer against hackers.", isCorrect: true },
        { text: "It helps you remember your passwords easily.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. 2FA may add a step to the login process for added security.",
        "Incorrect. 2FA does not eliminate the need for passwords.",
        "Correct! 2FA adds an extra layer of security, making it harder for hackers to access your account.",
        "Incorrect. 2FA doesn't help with remembering passwords."
    ]
},
{
    text: "What does 2FA require in addition to your password?",
    options: [
        { text: "Your national insurance number or ID.", isCorrect: false },
        { text: "A second factor, like a code from your phone.", isCorrect: true },
        { text: "Your date of birth or other personal info.", isCorrect: false },
        { text: "Your mother's maiden name or a secret question.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. 2FA requires something only you have immediately.",
        "Correct! 2FA requires a second piece of information, like a code sent to your phone.",
        "Incorrect. 2FA requires something only you have immediately.",
        "Incorrect. 2FA requires something only you have immediately."
    ]
},
{
    text: "How often should you update your 2FA methods?",
    options: [
        { text: "Only when you change your password.", isCorrect: false },
        { text: "Never, once set up, it doesn't need updating.", isCorrect: false },
        { text: "Regularly, to ensure protection and adapt to new features.", isCorrect: true },
        { text: "Just once when you first set it up.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. 2FA methods should be updated regularly.",
        "Incorrect. 2FA methods should be updated regularly.",
        "Correct! Regularly updating your 2FA methods ensures ongoing security.",
        "Incorrect. 2FA methods should be updated regularly."
    ]
},
{
    text: "What is a benefit of 2FA?",
    options: [
        { text: "It speeds up your login process significantly.", isCorrect: false },
        { text: "It guarantees your account will never be hacked.", isCorrect: false },
        { text: "It eliminates the need to use passwords.", isCorrect: false },
        { text: "It provides assurance that your accounts are more secure.", isCorrect: true }
    ],
    feedback: [
        "Incorrect. 2FA may add a step to the login process.",
        "Incorrect. While 2FA greatly enhances security, it cannot guarantee that an account will never be hacked.",
        "Incorrect. 2FA does not eliminate the need for passwords.",
        "Correct! 2FA provides greater assurance that your accounts are more secure."
    ]
},
{
    text: "How can you encourage others to use 2FA?",
    options: [
        { text: "Tell them 2FA is unnecessary.", isCorrect: false },
        { text: "Tell them to use the same password everywhere.", isCorrect: false },
        { text: "Advise them to ignore security updates.", isCorrect: false },
        { text: "Positively Encourage family and friends to use 2FA.", isCorrect: true }
    ],
    feedback: [
        "Incorrect. 2FA is important for security.",
        "Incorrect. Using the same password everywhere is not secure.",
        "Incorrect. Ignoring security updates is not a good practice.",
        "Correct! Positively Encourage family and friends to use 2FA."
    ]
},
{
    text: "What should you do if you lose access to your 2FA device?",
    options: [
        { text: "Disable 2FA immediately for safety.", isCorrect: false },
        { text: "Create a new account right away.", isCorrect: false },
        { text: "Ignore it and hope for the best.", isCorrect: false },
        { text: "Use the backup codes provided by 2FA.", isCorrect: true }
    ],
    feedback: [
        "Incorrect. Disabling 2FA should be a last resort.",
        "Incorrect. Creating a new account is not necessary.",
        "Incorrect. Ignoring the issue can lead to security risks.",
        "Correct! Backup codes are provided for such situations."
    ]
},
{
    text: "How do you update your 2FA settings?",
    options: [
        { text: "Follow platform-specific instructions.", isCorrect: true },
        { text: "Guess the new settings randomly.", isCorrect: false },
        { text: "Ask a friend to do it for you.", isCorrect: false },
        { text: "Use the same settings on all platforms.", isCorrect: false }
    ],
    feedback: [
        "Correct! Always follow platform-specific instructions to update your 2FA settings.",
        "Incorrect. You should follow specific instructions, not guess.",
        "Incorrect. It's important to understand and manage your own 2FA settings.",
        "Incorrect. Different platforms may have different security requirements."
    ]
}

];

const TwoFactorAuthQuiz = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null); // To track selected option
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false); // State to control star animation

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login'); // Redirect to login if the user is not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleOptionClick = (isCorrect, feedback, index) => {
    if (selectedOption === null) { // Only allow selection if no option has been selected yet
      setSelectedOption(index); // Set the selected option
      setFeedback({ message: feedback, isCorrect });
      if (isCorrect) {
        setScore(score + 1);
      }
      setTimeout(() => {
        setFeedback(null);
        setSelectedOption(null); // Reset selected option for the next question
        setCurrentScenario(currentScenario + 1);
      }, 3000); // Show feedback for 3 seconds
    }
  };

  useEffect(() => {
    if (currentScenario >= scenarios.length && score >= 6) {
      // If the user passed the quiz with 75% or more correct answers, update the profile
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        updateDoc(userDocRef, {
          'progress.course1.module4': true // Update Firestore to mark module4 as completed for course1
        }).then(() => {
          setShowStar(true); // Trigger the star animation
          console.log('User profile updated with quiz completion.');
        }).catch(error => {
          console.error('Error updating user profile: ', error);
        });
      }
    }
  }, [currentScenario, score, user]);

  const resetGame = () => {
    setCurrentScenario(0);
    setScore(0);
    setFeedback(null);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page5/safe-browsing'); // Replace with the actual next page route
  };

  const goBack = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth-part-two'); // Replace with the actual previous page route
  };

  if (currentScenario >= scenarios.length) {
    return (
      
      
      <div className="safe-browsing-game-container-unique11">
     
        {showStar && <div className="falling-star"></div>} {/* Star animation */}
        <h2>Congratulations you completed the task! Ravi now has a good understanding of two-factor authentication and he is now more cyber secure.</h2>
        <p>You scored {score} out of {scenarios.length}</p>
        <button onClick={resetGame}>Play Again</button>
        <button onClick={goToNext}>Next</button>
      </div>
    );
  }

  const { text, options, feedback: scenarioFeedback } = scenarios[currentScenario];

  return (
    
    <div className="safe-browsing-game-container-unique11">
    <button className="back-arrow-button-unique11" onClick={goBack}>
    ←
  </button>
  
      
      <h2>{text}</h2>
      {options.map((option, index) => (
        <button
          key={index}
          className={
            selectedOption === index
              ? (feedback && feedback.isCorrect ? 'correct-unique11' : 'incorrect-unique11')
              : ''
          }
          onClick={() => handleOptionClick(option.isCorrect, scenarioFeedback[index], index)}
          disabled={selectedOption !== null} // Disable all buttons once an option is selected
        >
          {option.text}
        </button>
      ))}
      {feedback && <p className={`feedback-unique11 ${feedback.isCorrect ? 'correct-feedback-unique11' : 'incorrect-feedback-unique11'}`}>{feedback.message}</p>}
    </div>
   
    
    
  );
};

export default TwoFactorAuthQuiz;
