import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const Malware = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module3/malware-quiz'); // Navigate to the quiz page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/cyber-threats'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Malware</h2>
      </div>

      <div className="video-container" style={{ position: 'relative' }}>
        <LogoOverlay /> {/* Add logo overlay component */}
        <video
          ref={videoRef}
          src="/assets/videos/Malware.mp4"
          width="100%"
          onEnded={handleVideoEnd}
          onError={(e) => console.error("Video Error:", e)}
          controls
          controlsList="nodownload noremoteplayback noplaybackrate" // Disable download, remote playback, and speed controls
          disablePictureInPicture
          playsInline
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="bordered-content">
              <h3>
                Malware Removal Guide:&nbsp;
                <a
                  href="/beginnerscourseparttwo/module3/remove-malware-guide"
                  className="link-text"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How to Get Rid of Malware Guide
                </a>
              </h3>
              <p><br /></p>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>Next Steps</h3>
            <p>
              Great job! You successfully removed the malware from that PC. However, to ensure it hasn't spread across the network, you need to check the other computers. Fortunately, the school is small, with only five computers. You install antivirus software on each one, run comprehensive scans, and advise the staff to monitor for any unusual activity. Additionally, you recommend that the school train staff in IT best practices to maintain system security.
            </p>
          </div>
          <div className="highlighted-section2">
            <h3>Investigation</h3>
            <p>
              To prevent future malware infections, it's crucial to understand how the malware initially infiltrated the PC. After questioning the staff and checking recent users, you discover that a student named Peter had plugged his USB drive into the computer to access his homework. Unfortunately, the USB drive was infected with malware. Peter had unknowingly downloaded malware embedded in a music file from an untrustworthy website offering free music downloads. You recommend the staff advise Peter and his parents to remove the malware, install antivirus software on his home PC, and only download music from official websites.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Start Quiz</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

    </div>
  );
};

export default Malware;
