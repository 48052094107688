import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';  // Adjust this if firebase is in a different folder
import { useAuth } from '../../contexts/AuthProvider';  // Adjust if needed based on the location of AuthProvider
import { useNavigate } from 'react-router-dom'; // Import useNavigate to programmatically go back

import './GroupedUsers.css';

const GroupedUsers = () => {
  const [groupedData, setGroupedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtering managers
  const [searchTerm, setSearchTerm] = useState(''); // For storing search term
  const { profile } = useAuth(); // Get profile from AuthProvider to check for admin rights
  const navigate = useNavigate(); // Use navigate for back button functionality

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'users'));
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });

        // Group users by manager
        const groupedByManager = {};
        users.forEach((user) => {
          const managerId = user.profile?.managerId;
          if (managerId) {
            if (!groupedByManager[managerId]) {
              groupedByManager[managerId] = [];
            }
            groupedByManager[managerId].push(user.profile?.name);
          }
        });

        // Format data to make it easier for rendering
        const groupedArray = Object.entries(groupedByManager).map(([managerId, employees]) => {
          const manager = users.find(user => user.id === managerId);
          return { manager: manager?.profile?.name, employees };
        });

        setGroupedData(groupedArray);
        setFilteredData(groupedArray); // Initially display all managers
      } catch (error) {
        console.error('Error fetching grouped users:', error);
      }
    };

    fetchData();
  }, []);

  // Search handler to filter managers by name
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = groupedData.filter(group =>
      group.manager?.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filtered);
  };

  // Navigate back to the admin dashboard
  const handleBackClick = () => {
    navigate('/admindashboard');
  };

  return (
    <div className="grouped-users">
      <h1>Grouped Users</h1>

      {/* Back button */}
      <button onClick={handleBackClick} className="back-button-group">Back to Admin Dashboard</button>

      {/* Search input */}
      <input
        type="text"
        placeholder="Search by manager's name"
        className="search-input"
        value={searchTerm}
        onChange={handleSearch}
      />

      {filteredData.length > 0 ? (
        <div className="grouped-users-container">
          {filteredData.map((group, index) => (
            <div key={index} className="manager-box">
              <h2>{group.manager}</h2>
              <ul>
                {group.employees.map((employee, i) => (
                  <li key={i}>{employee}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <p>No grouped users available.</p>
      )}
    </div>
  );
};

export default GroupedUsers;
