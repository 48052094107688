import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const Vishing = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const goToQuiz = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduletwo/vishing-quiz'); // Navigate to the quiz page
    } else {
      setShowWarning(true); // Show warning if the video is not fully watched
    }
  };
  const goBack = () => {
    navigate('/businessacademy/moduletwo/smishing'); // Navigate to the previous page
  };
 

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <h2>Vishing</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/VishingModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <h2>Signs of Vishing</h2>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Unsolicited Calls</h3> <br />
            <p>Receiving unexpected calls from unknown or blocked numbers.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Urgent or Threatening Language</h3> <br />
            <p>The caller may create a sense of urgency or use scare tactics, claiming that immediate action is required to avoid severe consequences.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Request for Sensitive Information</h3> <br />
            <p>The caller asks for personal or financial information, such as passwords, social security numbers, or credit card details.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Impersonation</h3> <br />
            <p>The caller pretends to be a representative of a trusted organisation, such as a bank, government agency, or tech support.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Verification Codes</h3> <br />
            <p>The caller requests verification codes sent via SMS or email, often claiming they need it to verify your identity.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Pressure to Act Quickly</h3> <br />
            <p>The caller insists on immediate action, pressuring you to provide information or perform specific actions without giving you time to think.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Too Good to Be True Offers</h3> <br />
            <p>The caller promises prizes, winnings, or offers that seem too good to be true, aiming to lure you into providing information.</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Prevent Vishing:</h3> <br />
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li><strong>Verify Caller Identity:</strong> If you receive an unexpected call asking for sensitive information, hang up and contact the organisation directly using a known and trusted phone number to verify the request.</li>
              <  br/> 
              <li><strong>Do Not Share Personal Information:</strong> Avoid sharing personal or financial information over the phone unless you are certain of the caller's identity and legitimacy.</li>
              <  br/> 
              <li><strong>Be Skeptical of Unsolicited Calls:</strong> Be cautious of unsolicited calls, especially those requesting immediate action or sensitive information.</li>
              <  br/> 
              <li><strong>Use Call Blocking Features:</strong> Utilise call-blocking features on your phone to reduce the number of unwanted calls.</li>
              <  br/> 
              <li><strong>Educate Yourself and Others:</strong> Stay informed about vishing tactics and educate family members, friends, and colleagues about the signs and risks.</li>
              <  br/> 
              <li><strong>Report Suspicious Calls:</strong> Report vishing attempts to your phone carrier, the organisation being impersonated, and relevant authorities such as Action Fraud in the UK.</li>
              <  br/> 
              <li><strong>Be Aware of Caller ID Spoofing:</strong> Understand that attackers can spoof caller IDs to make it appear as if the call is coming from a legitimate source. Always verify the caller's identity independently.</li>
              <  br/> 
              <li><strong>Stay Calm and Think Critically:</strong> If you receive a suspicious call, remain calm and think critically. Do not be pressured into making hasty decisions.</li>
            </ul>
          </div>
        </div>

        <button className="start-quiz-button" onClick={goToQuiz}>Next</button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </section>

      
    </div>
  );
};

export default Vishing;
