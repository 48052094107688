import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; 
import '../ModuleOne/WhatIsCyberSecurity.css'; 

const RecognizingQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null));
  const [showStar, setShowStar] = useState(false); 
  const auth = getAuth();

  const questions = [
    {
      question: "What is phishing?",
      options: [
        "A legitimate way to gather data from individuals",
        "A cyber threat involving fraudulent emails or websites",
        "A method to secure data and store data",
        "A type of firewall"
      ],
      correctAnswer: "A cyber threat involving fraudulent emails or websites"
    },
    {
      question: "What is spear phishing?",
      options: [
        "Phishing attempts via SMS messages",
        "Phishing attempts via voice calls",
        "Targeted attacks directed at specific individuals or organisations",
        "Generic attacks targeted at random people in order to gain sensitive information"
      ],
      correctAnswer: "Targeted attacks directed at specific individuals or organisations"
    },
    {
      question: "How can you identify a phishing email?",
      options: [
        "By its professional language",
        "By checking for suspicious email addresses and URLs",
        "By the sender's logo",
        "By its fast loading time"
      ],
      correctAnswer: "By checking for suspicious email addresses and URLs"
    },
    {
      question: "What should you do if you receive an unexpected attachment?",
      options: [
        "Open it immediately",
        "Ignore it",
        "Verify it before clicking",
        "Forward it to others"
      ],
      correctAnswer: "Verify it before clicking"
    },
    {
      question: "How can you avoid phishing attempts?",
      options: [
        "By clicking on all links",
        "By verifying the authenticity of the email or message",
        "By downloading attachments from unknown sources",
        "By ignoring all emails, this will eliminate all risks"
      ],
      correctAnswer: "By verifying the authenticity of the email or message"
    },
    {
      question: "What is vishing?",
      options: [
        "Phishing attempts via SMS messages",
        "Phishing attempts via voice calls",
        "Phishing attempts via social media",
        "Phishing attempts via emails"
      ],
      correctAnswer: "Phishing attempts via voice calls"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      // Update the user's progress in Firebase under the correct structure
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module2_quiz1': true,
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null));
    setFeedback(Array(6).fill(null));
    setCorrectAnswers(Array(6).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/moduletwo/smishing'); // Replace this with the actual link when available
  };

 

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Recognising Phishing Attempts Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 6</p>
              {score >= 4 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of recognising phishing attempts.</p>
              ) : (
                <p>You need to score at least 4 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        
      </section>
    </div>
  );
};

export default RecognizingQuiz;
