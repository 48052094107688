// src/pages/Forbidden.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Forbidden.css';  // Optional: Create this CSS file for custom styles

const Forbidden = () => {
  return (
    <div className="forbidden-container">
      <h1>403 - Forbidden</h1>
      <p>You don't have permission to access this page.</p>
      <Link to="/" className="back-home-link">Go Back to Home</Link>
    </div>
  );
};

export default Forbidden;
