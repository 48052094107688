import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const PhishingTaskTwo = () => {
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'legitimate');
  };

  const goToQuiz = () => {
    navigate('/businessacademy/moduletwo/spear-phishing-task'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/phishing-tasks'); // Navigate to the previous page
  };

 

  return (
    <div className="cybersecurity-basics-container">
          <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Phishing</h2>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
          
          </section>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if the email is legitimate or illegitimate.</span></strong></p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> support@innovatetechsolutions.com</p>
              <br />
              <p><strong>Subject:</strong> Scheduled Maintenance Notification</p>
              <br />
              <p>Hi David,</p>
              <br />
              <p>
                We wanted to inform you of a scheduled maintenance window for our systems. The maintenance will take place on Saturday, August 12th, from 2:00 AM to 4:00 AM. During this time, access to your Innovate Tech Solutions account may be temporarily unavailable.
              </p>
              <br />
              <p>
                If you have any questions or require assistance, please contact our IT support team at support@innovatetechsolutions.com.
              </p>
              <br />
              <p>Thank you for your understanding.</p>
              <br />
              <p>Sincerely,</p>
              <p>Innovate Tech Solutions IT Support</p>
              <br />
            </div>
            <div className="answer-buttons">
              <button onClick={() => handleAnswer('legitimate')}>Legitimate</button>
              <button onClick={() => handleAnswer('illegitimate')}>Illegitimate</button>
            </div>
            {isAnswered && (
              <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
                {isCorrect ? 'Well done! This is a legitimate email.' : 'Wrong answer.'}
              </div>
            )}
          </div>

          {isAnswered && (
            <div className="email-legitimate">
              <h4>Why is this email legitimate?</h4>
              <ul>
                <li><strong>Email Address:</strong> The sender's email address (support@innovatetechsolutions.com) is from a recognised domain. However this alone should not be relied on as the email addres may be spoofed. 
                A spoofed email is a fraudulent email that appears to come from a legitimate source but is actually sent by an attacker. It is designed to deceive the recipient into believing it is from a trusted sender, often to steal sensitive information or spread malware.</li>
                <br></br>
                <li><strong>Content:</strong> The email provides clear information about scheduled maintenance, which is a common and legitimate communication from IT departments.</li>
                <br></br>
                <li><strong>Contact Information:</strong> The email provides a legitimate contact email for further questions or assistance (support@innovatetechsolutions.com).</li>
                <br></br>
                <li><strong>No Urgent Threats:</strong> Unlike phishing emails, this email does not create a sense of urgency or threat but simply informs the recipient of a routine maintenance activity.</li>
              </ul>
            </div>
          )}
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>Different Types of Phishing</h3>
            <p>Phishing can take various forms, such as phishing emails, smishing (SMS phishing), and vishing (voice phishing). Signs of phishing include unsolicited messages, urgent requests for personal information, and suspicious links or attachments. You should not respond to the email and mark the sender as junk or spam and delete it.</p>
          </div>
        </div>

        <button className="start-quiz-button" onClick={goToQuiz}>Next</button>
      </section>

     
    </div>
  );
};

export default PhishingTaskTwo;
