import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure the correct path to your Firebase setup
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Ensure this file exists

const HomeNetworkQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(8).fill(null)); // Updated to reflect 8 questions
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(8).fill(null)); // Updated to reflect 8 questions
  const [correctAnswers, setCorrectAnswers] = useState(Array(8).fill(null)); // Updated to reflect 8 questions
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is the first step to secure your home network?",
      options: [
        "Turn off your router completely",
        "Change the default credentials",
        "Install a brand new app",
        "Connect to public Wi-Fi nearby"
      ],
      correctAnswer: "Change the default credentials"
    },
    {
      question: "Why is it important to keep your router's firmware updated?",
      options: [
        "To access exciting new features",
        "To protect against security risks",
        "To change your router's look",
        "To make your network faster"
      ],
      correctAnswer: "To protect against security risks"
    },
    {
      question: "Which type of encryption should you use for your Wi-Fi network?",
      options: [
        "WEP encryption is sufficient",
        "WPA2/WPA3 encryption is preferred",
        "No encryption at all",
        "Public Wi-Fi encryption"
      ],
      correctAnswer: "WPA2/WPA3 encryption is preferred"
    },
    {
      question: "Why should you check which devices are connected to your network?",
      options: [
        "To make the router run faster",
        "To allow strangers to freely use your Wi-Fi",
        "To ensure only authorised devices are connected",
        "To prevent your router from overheating by limiting the number of connected devices"
      ],
      correctAnswer: "To ensure only authorised devices are connected"
    },
    {
      question: "What is a guest network?",
      options: [
        "A network for smart devices",
        "A separate network for guests",
        "A faster gaming network",
        "A network for faster speeds"
      ],
      correctAnswer: "A separate network for guests"
    },
    {
      question: "How can you protect your home network from malware?",
      options: [
        "Install antivirus on your router",
        "Use a reliable VPN service",
        "Ensure your router has the latest updates",
        "Turn off the router often"
      ],
      correctAnswer: "Ensure your router has the latest updates"
    },
    {
      question: "Why should you use a strong, unique password for your Wi-Fi network?",
      options: [
        "To make it easy to remember",
        "To prevent unauthorised access",
        "To share it with friends",
        "To increase signal strength"
      ],
      correctAnswer: "To prevent unauthorised access"
    },
    {
      question: "What is the purpose of changing the default router password?",
      options: [
        "To make the internet faster",
        "To prevent unauthorised access",
        "To improve Wi-Fi signal strength",
        "To save on energy costs"
      ],
      correctAnswer: "To prevent unauthorised access"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (newScore >= 6 && user) { // Updated pass mark to 6/8
      try {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course2.module5_quiz": true, // Update Firestore to mark the quiz of module 5 in course 2 as completed
        });
        console.log("User profile updated with quiz completion.");
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error("Error updating user profile: ", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(8).fill(null)); // Updated to reflect 8 questions
    setFeedback(Array(8).fill(null)); // Updated to reflect 8 questions
    setCorrectAnswers(Array(8).fill(null)); // Updated to reflect 8 questions
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate("/beginnerscourseparttwo/module6/public-wifi"); // Replace this with the actual link when available
  };

  const goBack = () => {
    navigate("/beginnerscourseparttwo/module5/home-network");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Home Network Security Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === "correct" ? "Correct!" : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 8</p>
              {score >= 6 ? (
                <p>Congratulations! You passed the quiz and successfully learned how to protect your home network.</p>
              ) : (
                <p>You need to score at least 6 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 6 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          <button className="back-button" onClick={goBack}>Exit Quiz</button>
        </div>
      </section>
    </div>
  );
};

export default HomeNetworkQuiz;
