// LogoOverlay.js
import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Update path as necessary
import DOMPurify from 'dompurify'; // Optional: Sanitize URLs to avoid XSS
import './LogoOverlay.css';

const LogoOverlay = () => {
  const [logoUrl, setLogoUrl] = useState('');
  const [error, setError] = useState(false); // State for error handling
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (!user) return; // If no user is logged in, skip

      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().profile?.logoURL) {
          // Optional: Sanitize the URL (can use libraries like DOMPurify)
          const sanitizedUrl = DOMPurify.sanitize(userDoc.data().profile.logoURL);
          setLogoUrl(sanitizedUrl);
        }
      } catch (error) {
        console.error('Error fetching logo URL:', error);
        setError(true); // Set error state
      }
    };

    fetchLogoUrl();
  }, [user]);

  if (error) return <div>Error loading logo</div>; // Display error if fetching fails
  if (!logoUrl) return null; // Return nothing if no logo is available

  return (
    <div className="logo-overlay">
      <img
        src={logoUrl}
        alt="Company Logo"
        className="logo-img"
        onError={() => setError(true)} // Handle broken images
      />
    </div>
  );
};

export default LogoOverlay;
