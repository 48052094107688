import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CybersecurityBasics.css'; // Updated path for the CSS file
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Add the logo overlay


const CybersecurityBasics = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0); // For video progress
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToQuiz = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page2/cybersecurity-quiz'); // Navigate to the quiz page without hash
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
    setProgress(100); // Set progress to 100 when the video ends
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page1/introduction'); // Replace with the actual previous page route
  };

  return (
    <div className="introduction-container2">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section" style={{ textAlign: 'center' }}>
        <div className="BasicsTitle">What is Cybersecurity? </div>
      </section>

      <section className="module-section">
        <div className="content-layout">
          <div className="video-container" id="video-container" style={{ position: 'relative' }}>
            <LogoOverlay /> {/* Add the logo overlay */}
            <video
              ref={videoRef}
              src="/assets/videos/PageTwo.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
              disablePictureInPicture // Disable Picture-in-Picture
            >
              Your browser does not support the video tag.
            </video>

            {/* Progress Bar */}
            
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>What is Cybersecurity?</h3>
            <p>Cybersecurity refers to the practice of protecting systems, networks, and programs from digital attacks.</p>
            <br></br>
            <h3>Why is Cybersecurity Important?</h3>
            <p>Cybersecurity is crucial to protect sensitive information, prevent data breaches, and maintain privacy.</p>
          </div>

          <section className="message">
            <div className="quiz-message">
              <h5>Your wrist computer lights up! Quiz incoming!</h5>
            </div>
          </section>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button" onClick={goToQuiz}>
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>
      </section>

     
    </div>
    
  );
};

export default CybersecurityBasics;
