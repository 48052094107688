import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EmailSecurity.css'; // Ensure this file exists in Page3


const EmailSecurityTwo = () => {
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security-three'); // Navigate to the new page
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security'); // Navigate to the CybersecurityBasics.js page
  };

  return (
    <div className="introduction-container2">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section2">
      <section className="module-section" style={{ textAlign: 'center' }}>
              <h2>Email Security</h2>
              </section>
        <div className="module-content2">
          <div className="bordered-container2">
            <div className="task-instruction">
              <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if each email is legitimate or illegitimate.</span></strong></p>
            </div>
          </div>
        </div>

        <div className="email-example-box">
          <h3>Example Email One</h3>
          <div className="email-content">
            <p><strong>From:</strong> support@amaz0n.com</p>
            <br />
            <p><strong>Subject:</strong> Urgent: Update Your Payment Information</p>
            <br />
            <p>Dear Customer,</p>
            <br />
            <p>
              We have noticed unusual activity on your account. Please <strong><span className="tooltip-link"><strong><span style={{ color: 'blue' }}>Cick Here</span></strong><span className="tooltip-text">http://amaz0n-help.com</span></span></strong> to update your payment information immediately to avoid any interruptions in your service.
            </p>
            <br />
            <p>Thank you for your prompt attention to this matter.</p>
            <br />
            <p>Sincerely,</p>
            <p>Amazon Support Team</p>
            <br />
          </div>
          <div className="answer-buttons2">
            <button onClick={() => handleAnswer('legitimate')}>Legitimate</button>
            <button onClick={() => handleAnswer('illegitimate')}>Illegitimate</button>
          </div>
          {isAnswered && (
            <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
              {isCorrect ? 'Well done!' : 'Wrong answer.'}
            </div>
          )}
        </div>

        {isAnswered && (
          <div className="phishing-signs">
            <h4>Signs of a Phishing Attempt:</h4>
            <ul>
              <li><strong>Email Address:</strong> The sender's email address (support@amaz0n.com) contains a slight misspelling.</li>
              <li><strong>Urgency:</strong> The email creates a sense of urgency, pressuring you to act quickly.</li>
              <li><strong>Suspicious Link:</strong> The link provided does not lead to the official Amazon website.</li>
              <li><strong>Generic Greeting:</strong> The email uses a generic greeting like "Dear Customer" instead of your name.</li>
            </ul>
          </div>
        )}

        <button className="next-button" onClick={goToNext}>Next</button>

      
      </section>
    </div>
  );
};

export default EmailSecurityTwo;
