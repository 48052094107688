import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../src/contexts/AuthProvider';
import { firestore } from '../firebase';
import DOMPurify from 'dompurify'; // Import DOMPurify for input sanitization
import './StandardReport.css'; // Import the CSS file

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 250;

const StandardReport = () => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [threatType, setThreatType] = useState('Phishing');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [titleWarning, setTitleWarning] = useState('');
  const [descriptionWarning, setDescriptionWarning] = useState('');
  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > MAX_TITLE_LENGTH) {
      setTitleWarning(`Title cannot exceed ${MAX_TITLE_LENGTH} characters.`);
    } else {
      setTitle(inputValue);
      setTitleWarning('');
    }
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > MAX_DESCRIPTION_LENGTH) {
      setDescriptionWarning(`Description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters.`);
    } else {
      setDescription(inputValue);
      setDescriptionWarning('');
    }
  };

  const createReport = async (title, description, threatType) => {
    if (!currentUser) {
      setError('You must be logged in to report a threat.');
      return;
    }
  
    try {
      console.log("Fetching user document from Firestore...");
      const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
      const userProfile = userDoc.data();
  
      // Check if the user is linked to a manager
      if (!userProfile?.profile?.managerId) {
        setError('You must be linked to a manager to submit a threat report.');
        return;
      }
  
      let managerId = userProfile.profile.managerId;
  
      // Sanitize the inputs before sending to Firestore
      console.log("Sanitizing inputs...");
      const sanitizedTitle = DOMPurify.sanitize(title);
      const sanitizedDescription = DOMPurify.sanitize(description);
  
      console.log("Adding report to Firestore...");
      const reportRef = collection(firestore, 'threatReports');
      await addDoc(reportRef, {
        reportedBy: currentUser.uid,
        reportedByEmail: currentUser.email,
        managerId: managerId,
        title: sanitizedTitle,
        description: sanitizedDescription,
        threatType,
        timestamp: serverTimestamp(),
        comments: []
      });
  
      setSuccess('Report successfully submitted.');
      setTitle('');
      setDescription('');
      setThreatType('Phishing'); // Reset to default
    } catch (err) {
      console.error("Error adding report: ", err);
      setError('Failed to submit the report. Please try again.');
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.length > MAX_TITLE_LENGTH || description.length > MAX_DESCRIPTION_LENGTH) {
      setError('Please ensure the title and description meet the character limits.');
      return;
    }

    setError(null);
    setSuccess(null);
    createReport(title, description, threatType);
  };

  const handleBackClick = () => {
    navigate('/profile');
  };

  return (
    <div className="standard-report-container">
      <button className="back-button" onClick={handleBackClick}>← Back to Profile</button>
      <h1>Report a Threat</h1>
      <form onSubmit={handleSubmit} className="standard-report-form">
        <div className="form-group">
          <label>Title (max {MAX_TITLE_LENGTH} characters)</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            maxLength={MAX_TITLE_LENGTH}
            required
          />
          {titleWarning && <p className="warning-text">{titleWarning}</p>}
        </div>
        <div className="form-group">
          <label>Description (max {MAX_DESCRIPTION_LENGTH} characters)</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            maxLength={MAX_DESCRIPTION_LENGTH}
            required
          />
          {descriptionWarning && <p className="warning-text">{descriptionWarning}</p>}
        </div>
        <div className="form-group">
          <label>Threat Type</label>
          <select
            value={threatType}
            onChange={(e) => setThreatType(e.target.value)}
            required
          >
            <option value="Phishing">Phishing</option>
            <option value="Spear Phishing">Spear Phishing</option>
            <option value="Malware">Malware</option>
            <option value="Ransomware">Ransomware</option>
            <option value="Smishing">Smishing</option>
            <option value="Vishing">Vishing</option>
            <option value="Social Engineering">Social Engineering</option>
            <option value="Insider Threat">Insider Threat</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {error && <p className="error-text">{error}</p>}
        {success && <p className="success-text">{success}</p>}
        <button type="submit" className="submit-button24">Submit</button>
      </form>
    </div>
  );
};

export default StandardReport;
