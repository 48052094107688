import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ManagerPaymentSuccess.css'; // Import the CSS file for styling
import Footer from './Footer/Footer';
import confetti from 'canvas-confetti';

const ManagerPaymentSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching or delay, then set loading to false
    const timer = setTimeout(() => {
      setIsLoading(false);
      // Trigger the confetti animation
      confetti({
        particleCount: 150,
        spread: 70,
        origin: { y: 0.6 },
      });
    }, 1000);

    // Cleanup timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="ManagerPaymentSuccess-loading">Loading...</div>
      ) : (
        <div className="managers-payment-container">
          <div className="managers-payment-header">
            <h1 className="ManagerPaymentSuccess-title">Congratulations!</h1>
            <p className="ManagerPaymentSuccess-text">
              Your payment was successful, your Team Plan Package has been activated. You now have full access to all manager features!
            </p>
            <div className="ManagerPaymentSuccess-button-container">
              <Link to="/managersportal">
                <button className="ManagerPaymentSuccess-button">
                  Go to Your Managers Portal
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ManagerPaymentSuccess;
