import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './EmailSecurity.css'; // Ensure this file exists

const EmailSecurityQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is a common sign that an email might be a phishing attempt?",
      options: [" A greeting with your full name", " A request for personal information", " An email from a known contact"],
      correctAnswer: " A request for personal information"
    },
    {
      question: "Which of the following sender email addresses would be considered suspicious?",
      options: [" support@amazon.com", " updates@apple.com", " customerservice@paypal.com", " helpdesk@paypa1.com" ],
      correctAnswer: " helpdesk@paypa1.com"
    },
    {
      question: "If an email from an unknown sender contains numerous spelling and grammatical errors, it is likely to be:",
      options: [" Written by someone in a hurry", " From a friend who isn't good at writing", " A phishing attempt trying to trick you", " Sent from a mobile device with autocorrect issues"],
      correctAnswer: " A phishing attempt trying to trick you"
    },
    {
      question: "What should you do if an email says your account will be closed unless you click a link and update your information",
      options: [" Click the link and update your information", " Reply to the email for more information", " Go to the official site and log in and check there"],
      correctAnswer: " Go to the official site and log in and check there"
    },
    {
      question: "What should you do if you receive an unexpected email with an attachment?",
      options: [" Forward the email to a friend", " Verify the sender", " Open the attachment to see what it is"],
      correctAnswer: " Verify the sender"
    },
    {
      question: "What should you do if an email says you've won a contest you never entered?",
      options: [" Mark the email as spam and delete it", " Reply asking for more details", " Celebrate and provide the requested information", " Share the email with friends"],
      correctAnswer: " Mark the email as spam and delete it"
    },
    {
      question: "How can you verify if a suspicious email from your bank is real?",
      options: [" Click on the link in the email", " Call the phone number provided in the email", " Log in to your bank account using your usual method and check for messages", " Reply to the email asking for confirmation"],
      correctAnswer: " Log in to your bank account using your usual method and check for messages"
    },
    {
      question: "What is a red flag that an email may be a scam?",
      options: [" Greeting you with your name", " Creating a sense of urgency or fear", " Being sent from a friend"],
      correctAnswer: " Creating a sense of urgency or fear"
    },
    {
      question: "How can you check where a suspicious link in an email leads without clicking on it?",
      options: [" Open the email on a different device", " Copy and paste the link into your browser", " Copy the link and paste it into a URL checker", " Reply to the sender asking for more details"],
      correctAnswer: " Copy the link and paste it into a URL checker"
    },
    {
      question: "What should you do if you think an email is trying to scam you?",
      options: [" Forward it to your friends as a warning", " Ignore it and leave it in your inbox", " Mark it as spam and delete it", " Reply to the sender with a warning"],
      correctAnswer: " Mark it as spam and delete it"
    },
  ];

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = answer === questions[currentQuestionIndex].correctAnswer ? 'correct' : 'incorrect';
      setFeedback(newFeedback);
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    // If the user passed the quiz, update the profile in Firestore
    if (newScore >= 8 && user) { // Assuming a passing score of 8 or more
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module7': true // Update Firestore to mark module7 as completed for course1
        });
        console.log('User profile updated with quiz completion.');
        setShowStar(true); // Trigger the star animation
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setShowResults(false);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page8/finishers-page');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security-four');
  };

  return (
    <div className="cybersecurity-basics-container">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button> <br></br>
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Email Security Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{questions[currentQuestionIndex].question}</p>
                {questions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label>
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      {option}
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : 'Incorrect'}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < questions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              {score >= 8 ? (
                <p>Congratulations! You passed the quiz.</p>
              ) : (
                <p>You need to score at least 8 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 8 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default EmailSecurityQuiz;
