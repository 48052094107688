import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDocs, getDoc, query, collection, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import DOMPurify from 'dompurify'; // For data sanitization
import './Progress.css';

const Progress = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(''); // State for error messages
  const auth = getAuth();

  useEffect(() => {
    const fetchEmployeeData = async (employeeIds) => {
      try {
        // Use a single query to fetch multiple employee records
        const employeesQuerySnapshot = await getDocs(
          query(collection(firestore, 'users'), where('__name__', 'in', employeeIds))
        );
        const employeesData = employeesQuerySnapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
        setEmployees(employeesData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        setError('Unable to load employee data. Please try again.');
        setLoading(false);
      }
    };

    const fetchManagerData = async (user) => {
      try {
        const managerDocRef = doc(firestore, 'users', user.uid);
        const managerDocSnap = await getDoc(managerDocRef);
        if (managerDocSnap.exists()) {
          const managerData = managerDocSnap.data();
          if (managerData.profile?.accountType !== 'manager') {
            setError('Access denied. Only managers can view this page.');
            setLoading(false);
            return;
          }

          if (managerData.employees && managerData.employees.length > 0) {
            await fetchEmployeeData(managerData.employees);
          } else {
            setError('No employees found.');
            setLoading(false);
          }
        } else {
          setError('No manager data found.');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching manager data:', error);
        setError('Unable to load data. Please try again later.');
        setLoading(false);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchManagerData(user);
      } else {
        setError('You must be logged in to view this page.');
        setLoading(false);
      }
    });
  }, [auth]);

  const checkCourseCompletion = (progress, totalModules) => {
    if (progress) {
      return Object.values(progress).filter(Boolean).length === totalModules;
    }
    return false;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="progress-container">
      <h1 className="progress-title">Employee Progress</h1>
      <div className="progress-grid">
        {employees.length > 0 ? (
          employees.map((employee, index) => (
            <div key={index} className="employee-card">
              <h2 className="employee-name">
                {DOMPurify.sanitize(employee.profile.name)}
              </h2>
              <div className="course-progress">
                <p className={checkCourseCompletion(employee.progress?.course1, 8) ? 'completed' : 'incomplete'}>
                  Course 1: {checkCourseCompletion(employee.progress?.course1, 8) ? 'Completed' : 'Incomplete'}
                </p>
                <p className={checkCourseCompletion(employee.progress?.course2, 10) ? 'completed' : 'incomplete'}>
                  Course 2: {checkCourseCompletion(employee.progress?.course2, 10) ? 'Completed' : 'Incomplete'}
                </p>
                <p className={checkCourseCompletion(employee.progress?.course3, 14) ? 'completed' : 'incomplete'}>
                  Course 3: {checkCourseCompletion(employee.progress?.course3, 14) ? 'Completed' : 'Incomplete'}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No employees found.</p>
        )}
      </div>
    </div>
  );
};

export default Progress;
