import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const SpearPhishingTask = () => {
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const goToQuiz = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduletwo/spear-phishing-task-two'); // Navigate to the next task
    } else {
      setShowWarning(true); // Show warning if the video is not fully watched
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/phishing-tasks-two'); // Navigate to the previous page
  };



  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
          <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Spear Phishing</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add the logo overlay */}
              <video
                ref={videoRef}
                src="/assets/videos/SpearPhishingModuleTwo.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
            <h3>Your Task</h3>
            <p>After watching the video, decide if the email is legitimate or illegitimate.</p>
          </section>

          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if the email is legitimate or illegitimate.</span></strong></p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> John@innovatetechsolutions.com</p>
              <br />
              <p><strong>Subject:</strong> Urgent: Confidential Request</p>
              <br />
              <p>Hi David,</p>
              <br />
              <p>
                I need you to urgently do a bank transfer of £5,000 to our new supplier. Please find the details attached. Ensure this is completed within the next hour as it is critical for our ongoing project. Do not discuss this with anyone as it is confidential.
              </p>
              <br />
              <p>Thank you for your prompt attention to this matter.</p>
              <br />
              <p>Sincerely,</p>
              <p>John</p>
              <p>CEO, Innovate Tech Solutions</p>
              <br />
            </div>
            <div className="answer-buttons">
              <button onClick={() => handleAnswer('legitimate')}>Legitimate</button>
              <button onClick={() => handleAnswer('illegitimate')}>Illegitimate</button>
            </div>
            {isAnswered && (
              <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
                {isCorrect ? 'Well done! This is a spear phishing email.' : 'Wrong answer.'}
              </div>
            )}
          </div>

          {isAnswered && (
            <div className="email-legitimate">
              <h4>Why is this email a spear phishing attempt?</h4>
              <ul>
                <li><strong>Email Address:</strong> While the email may appear to be from the CEO, it's important to carefully verify the actual email address and domain. However, don't rely solely on this check, as the email could be spoofed or compromised like this one. It's essential to consider other factors to get a complete picture and ensure the email's authenticity.</li>
                <br />
                <li><strong>Urgency:</strong> The email creates a false sense of urgency, pressuring the recipient to act quickly without verification.</li>
                <br />
                <li><strong>Confidentiality:</strong> Requesting confidentiality can be a tactic to prevent the recipient from discussing the request with others, thus avoiding detection.</li>
                <br />
                <li><strong>Unusual Request:</strong> Sudden requests for wire transfers or sensitive information are typical indicators of spear phishing.</li>
                <br />
                <li>All the factors above strongly suggest this email is illegitimate</li>
                <br />
                <li><strong>Remember:</strong> If you are uncertain, always follow company procedures.</li>
              </ul>
            </div>
          )}
        </div>

        <button className="start-quiz-button" onClick={goToQuiz}>Next Task</button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </section>

      
    </div>
  );
};

export default SpearPhishingTask;
