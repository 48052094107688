import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const SocialEngineering = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      goToQuiz(); // Navigate to the quiz page
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/moduletwo/social-engineering-quiz'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/vishing'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <h2>Social Engineering</h2>
        <br></br>
      </div>

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/SocialEngineeringModuleTwo.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
        
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Common Tactics</h3><br></br>
          <ul>
            <li><strong style={{ color: 'salmon' }}>Pretexting:</strong> Creating a fabricated scenario to obtain information.</li>
            <  br/> 
            <li><strong style={{ color: 'salmon' }}>Baiting:</strong> Offering something enticing to gain access to sensitive information.</li>
            <  br/> 
            <li><strong style={{ color: 'salmon' }}>Tailgating:</strong> Gaining physical access to restricted areas by following authorised personnel.</li>
            <  br/> 
            <li><strong style={{ color: 'salmon' }}>Quid Pro Quo:</strong> Offering a service or benefit in exchange for information.</li>
          </ul>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>How to Identify Social Engineering</h3><br></br>
          <ul>
            <li>Be wary of unsolicited requests for sensitive information.</li>
            <  br/> 
            <li>Recognise unusual or inappropriate questions from strangers or new contacts.</li>
            <  br/> 
            <li>Notice if someone is trying to gain your trust too quickly.</li>
          </ul>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>How to Avoid Social Engineering</h3><br></br>
          <ul>
            <li>Verify the identity of the requester before providing any information.</li>
            <  br/> 
            <li>Do not share personal or sensitive information with unknown individuals.</li>
            <  br/> 
            <li>Report any suspicious interactions to the security team.</li>
          </ul>
        </div>
      </div>

      <div className="start-quiz-button-container">
        <button className="start-quiz-button" onClick={handleNextClick}>
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </div>

    
    </div>
  );
};

export default SocialEngineering;
