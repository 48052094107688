import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EmailSecurity.css'; // Ensure this file exists in Page3

const EmailSecurityThree = () => {
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'legitimate');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security-four'); // Navigate to the new page
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security-two'); // Navigate to the CybersecurityBasics.js page
  };

  return (
    <div className="introduction-container2">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section2">
      <section className="module-section" style={{ textAlign: 'center' }}>
              <h2>Email Security</h2>
              </section>
        <div className="module-content2">
          <div className="bordered-container2">
            <div className="task-instruction">
              <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if each email is legitimate or illegitimate.</span></strong></p>
            </div>
          </div>
        </div>

        <div className="email-example-box">
          <h3>Example Email Two</h3>
          <div className="email-content">
            <p><strong>From:</strong> support@amazon.com</p>
            <br></br>
            <p><strong>Subject:</strong> Order Confirmation #123-4567890-1234567</p>
            <br></br>
            <p>Dear John Davies,</p>
            <br></br>
            <p>
              Thank you for your order. Your order #123-4567890-1234567 has been confirmed. You can <span className="tooltip-link"><strong><span style={{ color: 'blue' }}>Cick Here</span></strong><span className="tooltip-text">https://www.amazon.co.uk/</span></span> to view your order details.
            </p>
            <br></br>
            <p>Thank you for shopping with us.</p>
            <br></br>
            <p>Sincerely,</p>
            <p>Amazon Support Team</p>
            <br></br>
          </div>
          <div className="answer-buttons">
            <button onClick={() => handleAnswer('legitimate')}>Legitimate</button>
            <button onClick={() => handleAnswer('illegitimate')}>Illegitimate</button>
          </div>
          {isAnswered && (
            <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
              {isCorrect ? 'Well done!' : 'Wrong answer.'}
            </div>
          )}
        </div>

        {isAnswered && (
          <div className="phishing-signs">
            <h4>Signs of a Legitimate Email:</h4>
            <ul>
              <li><strong>Email Address:</strong> The sender's email address (support@amazon.com) is correctly spelled and matches the official domain.</li>
              <li><strong>Personal Greeting:</strong> The email uses a personal greeting like "Dear John Davies" instead of a generic one.</li>
              <li><strong>Link Destination:</strong> The link provided leads to the official Amazon website or order details page.</li>
              <li><strong>Order Specific Information:</strong> The email contains specific details about your order, such as the order number.</li>
            </ul>
          </div>
        )}

        <button className="next-button" onClick={goToNext}>Next</button>

       
      </section>
    </div>
  );
};

export default EmailSecurityThree;
