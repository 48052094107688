import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './PasswordGame.css';

const questions = [
  {
    question: "Ravi asked what the recommended length for a strong password is, pick the corect response.",
    options: ["8-10 characters", "10-12 characters", "12-16 characters", "16-20 characters"],
    answer: "12-16 characters"
  },
  {
    question: "Show Ravi which one of the following is an example of using complexity in a password.",
    options: ["password123", "123456789", "Blu3D00rW@y86", "abcdefg"],
    answer: "Blu3D00rW@y86"
  },
  {
    question: "What should Ravi avoid using in his password?",
    options: ["Special characters", "Common words and patterns", "Uppercase and lowercase letters", "Numbers"],
    answer: "Common words and patterns"
  },
  {
    question: "Show Ravi which one of the following is a method to create randomness in passwords.",
    options: ["Using personal information", "Using common phrases", "Using unrelated words", "Using sequential numbers"],
    answer: "Using unrelated words"
  },
  {
    question: "What is an example of a passphrase?",
    options: ["password123", "BlueHorse$7Coffee*", "12345678", "abcdefg"],
    answer: "BlueHorse$7Coffee*"
  },
  {
    question: "Which of these is a strong password?",
    options: ["password", "qwerty", "Blu3D00rW@y86", "123456"],
    answer: "Blu3D00rW@y86"
  },
  {
    question: "Tell Ravi why is it important to use a unique password for each account.",
    options: ["For convenience", "To prevent multiple account breaches", "To remember easily", "To look cool"],
    answer: "To prevent multiple account breaches"
  },
  {
    question: "Explain a benefit of using a password manager",
    options: ["Stores all your passwords securely", "Shares your passwords with friends", "Makes passwords shorter", "Automatically logs you out"],
    answer: "Stores all your passwords securely"
  },
  {
    question: "Tell Ravi what two-factor authentication is.",
    options: ["Using the same password for two accounts", "A method to store passwords", "An additional layer of security", "A way to share passwords"],
    answer: "An additional layer of security"
  },
  {
    question: "Show Ravi Which of these passwords is more secure.",
    options: ["sunshine", "123456", "letmein", "C0mpl3x!ty2024"],
    answer: "C0mpl3x!ty2024"
  },
  {
    question: "Why should Ravi avoid using personal information in his passwords?",
    options: ["It's easy to remember", "It can be easily guessed", "It looks professional", "It makes the password longer"],
    answer: "It can be easily guessed"
  },
  {
    question: "What should Ravi do if he suspects his password has been compromised?",
    options: ["Ignore it", "Change it immediately", "Share it with friends", "Use it on more accounts"],
    answer: "Change it immediately"
  }
];

const PasswordGame = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const [selectedOption, setSelectedOption] = useState(null); // Track the selected option

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login'); // Redirect to login if the user is not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleAnswerOptionClick = (option) => {
    if (selectedOption === null) { // Only allow selection if no option has been selected yet
      setSelectedOption(option); // Lock in the selected option

      if (option === questions[currentQuestion].answer) {
        setScore(score + 1);
        setFeedback('Correct!');
        setCorrectAnswer('');
      } else {
        setFeedback('Incorrect!');
        setCorrectAnswer(`The correct answer is: ${questions[currentQuestion].answer}`);
      }
      setTimeout(() => {
        setFeedback('');
        setCorrectAnswer('');
        setSelectedOption(null); // Reset the selected option for the next question
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
          setCurrentQuestion(nextQuestion);
        } else {
          setShowScore(true);
          const finalScore = score + (option === questions[currentQuestion].answer ? 1 : 0);
          if (finalScore >= 9) {
            setCanProceed(true);

            // If the user passed the quiz, update the profile in Firestore
            if (user) {
              const userDocRef = doc(firestore, 'users', user.uid);
              updateDoc(userDocRef, {
                'progress.course1.module3': true // Update Firestore to mark module3 as completed for course1
              }).then(() => {
                setShowStar(true); // Trigger the star animation
                console.log('User profile updated with quiz completion.');
              }).catch(error => {
                console.error('Error updating user profile: ', error);
              });
            }
          }
        }
      }, 3000); // Wait for 3 seconds before moving to the next question
    }
  };

  const resetGame = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setCanProceed(false);
    setShowStar(false); // Reset the star animation
    setSelectedOption(null); // Reset the selected option
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth'); // Navigate to the new page
  };

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-three'); // Navigate to the previous page
  };

  return (
    <div className="password-game-container-unique">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
  <br></br>
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
     
      {showScore ? (
        <div className="score-section-unique">
          <div className="score-feedback-unique">
            {canProceed ? (
              <>
                <p>Well done! You successfully taught Ravi the importance of strong passwords.</p>
                <button className="next-button-unique" onClick={goToNext}>Next</button>
              </>
            ) : (
              <>
                <p>You didn't score high enough to pass. Please try again.</p>
                <button onClick={resetGame}>Retake the Test</button>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="question-section-unique">
            <div className="question-count-unique">
              <span>Question {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text-unique">{questions[currentQuestion].question}</div>
          </div>
          <div className="answer-section-unique">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswerOptionClick(option)}
                disabled={selectedOption !== null} // Disable all buttons once an option is selected
                className={selectedOption === option ? (option === questions[currentQuestion].answer ? 'correct' : 'incorrect') : ''}
              >
                {option}
              </button>
            ))}
          </div>
          {feedback && (
            <div className={`feedback-unique ${feedback === 'Correct!' ? 'correct' : 'incorrect'}`}>
              {feedback}
              {correctAnswer && <p>{correctAnswer}</p>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PasswordGame;
