import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SafeBrowsingDropDragGame.css';

const terms = [
  { id: 1, text: "Ensure websites use HTTPS", category: "good" },
  { id: 2, text: "Click on suspicious links", category: "bad" },
  { id: 3, text: "Enable two-factor authentication", category: "good" },
  { id: 4, text: "Use weak passwords", category: "bad" },
  { id: 5, text: "Verify sender's address", category: "good" },
  { id: 6, text: "Share personal information publicly", category: "bad" },
  { id: 7, text: "Update software regularly", category: "good" },
  { id: 8, text: "Ignore security warnings", category: "bad" },
  { id: 9, text: "Instead of clicking on links, go directly to the official site", category: "good" },
  { id: 10, text: "Click on unknown email attachments", category: "bad" },
  { id: 11, text: "Use unique passwords for different accounts", category: "good" },
  { id: 12, text: "Download movies from unofficial sites", category: "bad" }
];

const SafeBrowsingDropDragGame = () => {
  const [draggedTerm, setDraggedTerm] = useState(null);
  const [sortedTerms, setSortedTerms] = useState({ good: [], bad: [] });
  const navigate = useNavigate();

  const handleDragStart = (term) => {
    setDraggedTerm(term);
  };

  const handleDrop = (category) => {
    if (draggedTerm && draggedTerm.category === category) {
      setSortedTerms((prev) => ({
        ...prev,
        [category]: [...prev[category], draggedTerm],
      }));
    }
    setDraggedTerm(null);
  };

  const resetGame = () => {
    setSortedTerms({ good: [], bad: [] });
    setDraggedTerm(null);
  };

  const goToQuiz = () => {
    navigate('/beginnerscourse/page5/safe-browsing-game'); // Corrected route for the quiz page
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing-part-two'); // Replace with the actual previous page route
  };

  const isAllSortedCorrectly = sortedTerms.good.length + sortedTerms.bad.length === terms.length;

  return (
    <div className="sorting-game-container">
      <button className="back-arrow-button" onClick={goBack}>
        ←
      </button> {/* Back button moved to top left */} <br></br>
      <h2>Drag and Drop Sorting Game</h2>
      <div className="terms-container">
        {terms
          .filter((term) => !sortedTerms.good.includes(term) && !sortedTerms.bad.includes(term))
          .map((term) => (
            <div
              key={term.id}
              className="term"
              draggable
              onDragStart={() => handleDragStart(term)}
            >
              {term.text}
            </div>
          ))}
      </div>
      <div className="categories-container">
        <div
          className="category good"
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop("good")}
        >
          <h3>Good</h3>
          {sortedTerms.good.map((term) => (
            <div key={term.id} className="term good-term">
              {term.text}
            </div>
          ))}
        </div>
        <div
          className="category bad"
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop("bad")}
        >
          <h3>Bad</h3>
          {sortedTerms.bad.map((term) => (
            <div key={term.id} className="term bad-term">
              {term.text}
            </div>
          ))}
        </div>
      </div>
      {isAllSortedCorrectly && (
        <div className="game-complete">
          <p>Congratulations! You've sorted all the terms correctly.</p>
        
          <button className="next-button" onClick={goToQuiz}>Next</button>
        </div>
      )}
    </div>
   
  );
};

export default SafeBrowsingDropDragGame;
