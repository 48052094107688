import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { firestore } from '../firebase';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import './ThreatStats.css';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const ThreatStats = () => {
  const [reportData, setReportData] = useState({ labels: [], datasets: [] });
  const [pieData, setPieData] = useState({ labels: [], datasets: [] });
  const [monthlyData, setMonthlyData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Error state

  // Helper function to convert local date to UTC
  const convertToUTC = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  };

  useEffect(() => {
    const fetchReports = async () => {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      // Convert all date ranges to UTC to align with Firestore's timestamp storage
      const currentMonthStart = convertToUTC(new Date(today.getFullYear(), today.getMonth(), 1));
      const lastMonthStart = convertToUTC(new Date(today.getFullYear(), today.getMonth() - 1, 1));
      const twoMonthsAgoStart = convertToUTC(new Date(today.getFullYear(), today.getMonth() - 2, 1));
      const threeMonthsAgoStart = convertToUTC(new Date(today.getFullYear(), today.getMonth() - 3, 1));

      try {
        // Query Firestore for reports from the last 3 months using UTC dates
        const q = query(
          collection(firestore, 'threatReports'),
          where('timestamp', '>=', threeMonthsAgoStart),
          orderBy('timestamp', 'asc'),
          limit(100) // Limiting the number of returned documents
        );
        const querySnapshot = await getDocs(q);

        // Aggregate the threats by type for the last 30 days
        const overallThreatCountsLast30Days = {
          Phishing: 0,
          'Spear Phishing': 0,
          Malware: 0,
          Ransomware: 0,
          Smishing: 0,
          Vishing: 0,
          'Social Engineering': 0,
          'Insider Threat': 0,
        };

        const monthlyCounts = {
          currentMonth: { ...overallThreatCountsLast30Days },
          lastMonth: { ...overallThreatCountsLast30Days },
          twoMonthsAgo: { ...overallThreatCountsLast30Days },
        };

        querySnapshot.forEach((doc) => {
          const report = doc.data();
          const reportDate = report.timestamp.toDate();

          const sanitizedThreatType = sanitizeInput(report.threatType);

          if (sanitizedThreatType && overallThreatCountsLast30Days[sanitizedThreatType] !== undefined) {
            overallThreatCountsLast30Days[sanitizedThreatType] += 1;
          }

          // Assign report to the correct month for the last 3 months
          let monthKey = null;
          if (reportDate >= currentMonthStart) {
            monthKey = 'currentMonth';
          } else if (reportDate >= lastMonthStart) {
            monthKey = 'lastMonth';
          } else if (reportDate >= twoMonthsAgoStart) {
            monthKey = 'twoMonthsAgo';
          }

          if (monthKey && sanitizedThreatType && monthlyCounts[monthKey][sanitizedThreatType] !== undefined) {
            monthlyCounts[monthKey][sanitizedThreatType] += 1;
          }
        });

        // Prepare the data for the bar chart for the last 30 days
        setReportData({
          labels: Object.keys(overallThreatCountsLast30Days),
          datasets: [
            {
              label: 'Number of Threats',
              data: Object.values(overallThreatCountsLast30Days),
              backgroundColor: Object.keys(overallThreatCountsLast30Days).map((label) => getColor(label)),
            },
          ],
        });

        // Prepare data for the pie chart
        setPieData({
          labels: Object.keys(overallThreatCountsLast30Days),
          datasets: [
            {
              data: Object.values(overallThreatCountsLast30Days),
              backgroundColor: Object.keys(overallThreatCountsLast30Days).map((label) => getColor(label)),
            },
          ],
        });

        // Prepare monthly data for the last three months
        setMonthlyData([
          {
            month: today.toLocaleString('default', { month: 'long', year: 'numeric' }),
            data: generateMonthlyDataset(monthlyCounts.currentMonth),
          },
          {
            month: lastMonthStart.toLocaleString('default', { month: 'long', year: 'numeric' }),
            data: generateMonthlyDataset(monthlyCounts.lastMonth),
          },
          {
            month: twoMonthsAgoStart.toLocaleString('default', { month: 'long', year: 'numeric' }),
            data: generateMonthlyDataset(monthlyCounts.twoMonthsAgo),
          },
        ]);

      } catch (error) {
        console.error('Error fetching reports:', error);
        setErrorMessage('Error fetching reports. Please try again later.'); // Display user-friendly error message
      }
    };

    fetchReports();
  }, []);

  // Function to sanitize user input (threatType)
  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  // Function to get color based on threat type (ensuring consistency between charts)
  const getColor = (threatType) => {
    const colors = {
      Phishing: '#FF6384',
      'Spear Phishing': '#36A2EB',
      Malware: '#FFCE56',
      Ransomware: '#FF9F40',
      Smishing: '#4BC0C0',
      Vishing: '#9966FF',
      'Social Engineering': '#4CAF50',
      'Insider Threat': '#FFC107',
    };
    return colors[threatType] || '#000000';
  };

  // Function to generate a dataset for a specific month
  const generateMonthlyDataset = (monthlyCounts) => {
    const labels = Object.keys(monthlyCounts);
    const data = labels.map((label) => monthlyCounts[label]);
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: labels.map((label) => getColor(label)),
        },
      ],
    };
  };

  return (
    <div className="ThreatStats-container">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="ThreatStats-chart-container">
        <h2 className="ThreatStats-title">Threats Over the Last 30 Days</h2>
        <Bar
          data={reportData}
          options={{
            responsive: true,
            maintainAspectRatio: false, // Ensures the chart respects container dimensions
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Number of Threats',
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Threat Type', // Label for the X-axis
                },
                ticks: {
                  autoSkip: false, // Ensure all labels are shown
                },
              },
            },
            plugins: {
              legend: {
                display: false, // No legend needed if each bar is labeled
              },
            },
          }}
        />
      </div>

      <div className="ThreatStats-pie-container">
        <h2>Threat Type Distribution of the Past 30 Days</h2>
        <Pie
          data={pieData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'right',
              },
            },
          }}
        />
      </div>
     
      <hr className="ThreatStats-divider" />
      <div className="ThreatStats-monthly-charts">
        <h2>Threats for the latest three months (Graphs will be overwritten over three months old)</h2>
        {monthlyData.map((monthData, index) => (
          <div key={index} className="ThreatStats-chart-container">
            <h2>Threats in {sanitizeInput(monthData.month)}</h2>
            <Bar
              data={monthData.data}
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Number of Threats',
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: 'Threat Type',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThreatStats;
