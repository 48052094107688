import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import '../Page3/RecognizingThreatsPartTwo.css'; // Ensure this file exists in Page3


const EmailSecurity = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page7/email-security-two'); // Navigate to the next page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety-part-two'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <div className="BasicsTitle">Email Security</div>
        </section>

        <div className="module-content">
          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay /> {/* Add the logo overlay */}
            <video
              ref={videoRef}
              src="/assets/videos/PageSeven.mp4"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error('Video Error:', e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" // Disables unwanted controls
              disablePictureInPicture // Disable Picture-in-Picture
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="bordered-container">
          <p>
            You start by discussing with Dorothy the importance of securing her email account.
            To help her manage the challenge of remembering multiple complex passwords, you introduce her to a password manager,
            which allows her to create and store unique, strong passwords for each of her accounts. Additionally,
            you guide her in enabling two-factor authentication (2FA), adding an extra layer of security to her email
            by requiring a secondary form of verification. By taking these steps, Dorothy significantly enhances the security
            of her email and other online accounts, safeguarding her digital identity.
          </p>
        </div>

        <div className="button-container">
          <button className="next-button" onClick={goToNext}>Next</button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>

        
      </section>
    </div>
    
  );
};

export default EmailSecurity;
