import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CreditProfile = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module8/credit-report-quiz'); // Navigate to the next page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup-part-two'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Credit Profile</h2>
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                src="/assets/videos/CreditProfile.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <p>If Tanya had set up a credit monitoring service, she would have received real-time alerts for new credit applications and changes to her credit report. This would have enabled her to take immediate action and prevent the fraud from occurring. Some companies that offer these services include Experian CreditExpert, Equifax Credit Watch, and TransUnion Credit Monitoring.</p>
          </div>

          <div className="highlighted-section2">
            <h3>Bank and Financial Institution Alerts:</h3>  <br />
            <p>Many banks and credit card companies offer free alerts for new credit activity, including loan applications, as part of their online banking services. Check with your financial institution to see if they offer this feature.</p>
          </div>

          <div className="highlighted-section2">
            <h3>Benefits of Credit Monitoring Alerts</h3>  <br />
            <p>
              <strong>Early Detection:</strong><br />
              Immediate alerts can help you detect fraudulent activity as soon as it occurs, allowing you to take quick action.
            </p>  <br />
            <p>
              <strong>Peace of Mind:</strong><br />
              Knowing that you will be notified of any significant changes to your credit report can provide peace of mind.
            </p>  <br />
            <p>
              <strong>Preventative Measures:</strong><br />
              By being promptly informed, you can prevent further unauthorised activities and limit potential damage to your credit profiles.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Start Quiz</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

      
    </div>
  );
};

export default CreditProfile;
