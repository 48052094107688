import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component
import safeurl from '../../assets/URLchecker.png';
import '../Page3/RecognizingThreats.css'; // Adjusted path

const CheckingURLs = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page5.5/Checking-URLs-quiz'); // Navigate to the next page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing-part-two'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
        <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <div className="BasicsTitle">Verify Links</div>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/PageFiveFive.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
            disablePictureInPicture // Disable Picture-in-Picture
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2>How to Verify a Link</h2>
        </section>

        <div className="module-content">
          <div className="highlighted-section">
            <p>
              Determining the authenticity of a link by sight alone can be challenging, especially when URL shorteners are used. To verify a link, you can hover your cursor over it to see the URL, or right-click and select "Copy Link" to inspect it further. To ensure a link is safe, you can paste it into a URL checker. This tool reveals the full web address, checks for risks, and provides information about the destination site, helping you avoid potentially harmful websites. Below is an example of a URL checked on a URL checker.
            </p>
          </div>
        </div>
        <br />

        <div style={{ textAlign: 'center' }}>
          <img src={safeurl} alt="Safe URL example" style={{ width: '100%', height: '100%' }} />
        </div>

        <br />

        <div className="bordered-container">
          <p>
            The URL checker used above is available at{' '}
            <a
              href="https://radar.cloudflare.com/scan"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'lightblue', textDecoration: 'underline' }}
            >
              Cloudflare Radar
            </a>, other URL checkers are available. The link tested was `http://bit.ly/3TeDQM2`, a shortened URL that doesn't reveal its destination at first glance. However, by using the URL checker, you can see a screenshot of the landing page and the actual domain, which in this case is YouTube, confirming that the link is legitimate. If you were to check a link and saw a Facebook login page in the screenshot, but the domain wasn't `facebook.com`, you'd know the link is fraudulent and designed to steal your credentials. Shortened URLs are often used for legitimate purposes, but they can also be exploited for malicious intent. That's why it's crucial to verify them using a URL checker. Your task is to add a URL checker to your bookmarks or favorites, allowing you to quickly and easily verify links whenever needed.
          </p>
        </div>

        <div className="button-container">
          <button className="next-button" onClick={goToNext}>Start Quiz</button>
          {showWarning && (
            <p className="warning-text">You must watch the video before proceeding.</p>
          )}
        </div>

       
      </section>
    </div>
  );
};

export default CheckingURLs;
