import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const RecognizingCyberThreats = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduletwo/phishing-tasks'); // Navigate to the next page
    } else {
      setShowWarning(true); // Show warning if the video is not fully watched
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/employee-role'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
          <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <h2>Recognising Cyber Threats</h2>
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/RecognisingCyberThreats.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <br /><br />

        <button className="start-quiz-button" onClick={handleNextClick}>
          Next
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </section>

      
    </div>
  );
};

export default RecognizingCyberThreats;
