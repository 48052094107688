import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Ensure this logo overlay component is imported
import '../ModuleTwo/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const Ransomware = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Start Quiz" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourseparttwo/module3/ransomware-quiz'); // Navigate to the quiz page
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/phishing'); // Replace with the actual previous page route
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h2>Ransomware</h2>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add logo overlay */}
              <video
                ref={videoRef}
                src="/assets/videos/Ransomware.mp4"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>

           
          </div>
        </div>

        <div className="module-content">
          <div className="bordered-content">
            <p>
              Follow the same steps as outlined in the 
              <a 
                href="/beginnerscourseparttwo/module3/remove-malware-guide3" 
                className="link-text" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                How to Get Rid of Malware Guide
              </a>, 
              since ransomware is a type of malware. However, unlike typical malware, ransomware encrypts your data, making it inaccessible without a decryption key. But don't worry, not all is lost! Fortunately, the library has a robust backup strategy. They regularly back up their data to external hard drives that are kept offline and separate from the library network, as well as to the cloud. After successfully removing the ransomware, the data is restored to the library network.
            </p>
          </div>

          <div className="bordered-container alt-color">
  <h3>How Ransomware Gets on Your Computer</h3>
  <p>
    Ransomware is a type of malicious software that locks your computer or encrypts your files, demanding a ransom to get them back. Here are some common ways it can get onto your computer:
  </p>
  <br />
  <ul style={{ marginLeft: '20px', paddingLeft: '20px', listStylePosition: 'inside' }}>
    <li style={{ color: 'black' }}>
      <strong>Phishing Emails:</strong> Cybercriminals send emails that look legitimate, tricking you into clicking on a link or opening an attachment. This can install ransomware on your system.
    </li>
    <br />
    <li style={{ color: 'black' }}>
      <strong>Malicious Websites:</strong> Visiting a compromised or fake website can automatically download ransomware onto your computer.
    </li>
    <br />
    <li style={{ color: 'black' }}>
      <strong>Infected Downloads:</strong> Downloading software, games, or other files from untrusted sources can also bring ransomware onto your computer.
    </li>
    <br />
    <li style={{ color: 'black' }}>
      <strong>Exploiting Security Holes:</strong> Outdated software and operating systems with security vulnerabilities can be exploited by hackers to install ransomware.
    </li>
  </ul>
  <p>
    To protect yourself, be cautious of unexpected emails and links, only download from trusted sources, and keep your software up to date.
  </p>
</div>


          <div className="bordered-container">
            <h3>The Importance of Backups</h3>
            <p>
              The practice of maintaining multiple backups is crucial for data availability and recovery. Without these backups, the library would have faced significant data loss and operational disruption. This example highlights the importance of having reliable backup solutions to safeguard against potential data loss from ransomware attacks.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button1" onClick={handleNextClick}>Start Quiz</button>
          {showWarning && (
            <p className="warning-text">You must watch the entire video before proceeding.</p>
          )}
        </div>
      </section>

      
    </div>
  );
};

export default Ransomware;
