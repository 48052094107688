import React from 'react';
import { useNavigate } from 'react-router-dom';

const VerificationNotice = () => {
  const navigate = useNavigate();

  return (
    <div className="verification-notice">
      <h2>Email Verification Required</h2>
      <p>
        A verification email has been sent to your registered email address. Please check your inbox and verify your email to proceed.
      </p>
      <button onClick={() => navigate('/login')}>Go to Login</button>
    </div>
  );
};

export default VerificationNotice;
