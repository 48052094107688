import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const PrivacyQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null)); // Updated array length to 6
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null)); // Updated array length to 6
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null)); // Updated array length to 6
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "What is the main purpose of privacy in a business context?",
      options: [
        "All data should be accessible to all employees to ensure fairness and transparency within the organisation",
        "To protect personal information from unauthorised access and misuse",
        "To increase data storage capacity",
        "To allow unrestricted access to company data"
      ],
      correctAnswer: "To protect personal information from unauthorised access and misuse"
    },
    {
      question: "How does GDPR enhance privacy for individuals?",
      options: [
        "By allowing companies to sell personal data freely",
        "By giving individuals control over their personal data",
        "By reducing the security measures required for data protection",
        "By encouraging data breaches"
      ],
      correctAnswer: "By giving individuals control over their personal data"
    },
    {
      question: "Why is data encryption important for privacy protection?",
      options: [
        "It allows anyone to access data easily",
        "It makes data easily shareable with third parties",
        "It protects sensitive data from unauthorised access",
        "It improves the speed of data processing"
      ],
      correctAnswer: "It protects sensitive data from unauthorised access"
    },
    {
      question: "When should data be encrypted?",
      options: [
        "Only when stored on physical devices",
        "Only during transmission",
        "When stored, during transmission, and when archived",
        "Never, to avoid slowing down the system"
      ],
      correctAnswer: "When stored, during transmission, and when archived"
    },
    {
      question: "What is the impact of protecting privacy on businesses?",
      options: [
        "It increases the risk of legal penalties",
        "It enhances customer trust and the organisation's reputation",
        "It reduces the need for data security measures",
        "It allows more flexible data sharing with external partners"
      ],
      correctAnswer: "It enhances customer trust and the organisation's reputation"
    },
    {
      question: "What is the responsibility of employees in maintaining privacy?",
      options: [
        "To handle personal data carelessly",
        "To sell personal data to external entities for profit",
        "To follow best practices for data privacy at all times",
        "To ignore data privacy regulations as it is too time consuming"
      ],
      correctAnswer: "To follow best practices for data privacy at all times"
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module6_quiz2': true, // Update Firestore to mark the quiz of module 6 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null)); // Reset array length to 6
    setFeedback(Array(6).fill(null)); // Reset array length to 6
    setCorrectAnswers(Array(6).fill(null)); // Reset array length to 6
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/modulesix/compliance'); 
  };

 

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Privacy Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 6</p>
              {score >= 4 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of privacy.</p>
              ) : (
                <p>You need to score at least 4 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default PrivacyQuiz;
