import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure this is the correct path to your Firebase setup
import '../ModuleOne/WhatIsCyberSecurity.css'; // Ensure this file exists

const SecuringWorkDevicesQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(10).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(10).fill(null));
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const auth = getAuth();

  const questions = [
    {
      question: "Why is it important to use strong, unique passwords on work devices?",
      options: [
        "To prevent unauthorised access",
        "To make it easier to remember",
        "Because weak passwords are easier to type",
        "To share it easily with colleagues"
      ],
      correctAnswer: "To prevent unauthorised access"
    },
    {
      question: "What is the purpose of encryption on work devices?",
      options: [
        "To make the device run faster",
        "To protect sensitive data even if the device is lost or stolen",
        "To make it harder to update software",
        "To prevent physical damage to the device"
      ],
      correctAnswer: "To protect sensitive data even if the device is lost or stolen"
    },
    {
      question: "How often should operating systems and applications be updated?",
      options: [
        "Once a year",
        "Whenever convenient",
        "As soon as updates are available",
        "Never, to avoid disruptions"
      ],
      correctAnswer: "As soon as updates are available"
    },
    {
      question: "What is the function of remote wiping on mobile devices?",
      options: [
        "To remove unnecessary apps",
        "To erase data if the device is lost or stolen",
        "To reset the device to factory settings regularly",
        "To change the device's location"
      ],
      correctAnswer: "To erase data if the device is lost or stolen"
    },
    {
      question: "Which of the following is a good physical security practice for work devices?",
      options: [
        "Leaving them in public places when not in use",
        "Storing them securely when not in use",
        "Sharing them with unauthorised persons",
        "Keeping them in the car overnight"
      ],
      correctAnswer: "Storing them securely when not in use"
    },
    {
      question: "Why should work devices be kept up to date with the latest software?",
      options: [
        "To ensure they have the latest games",
        "To protect against the latest security vulnerabilities",
        "To reduce battery consumption",
        "To increase storage space"
      ],
      correctAnswer: "To protect against the latest security vulnerabilities"
    },
    {
      question: "What is a recommended method of securing sensitive data on a device?",
      options: [
        "Using a common password shared across the organisation",
        "Encrypting the data on the device",
        "Avoiding any password protection",
        "Storing the data in a visible folder"
      ],
      correctAnswer: "Encrypting the data on the device"
    },
    {
      question: "Which of these is an example of a strong password?",
      options: [
        "password123",
        "letmein",
        "IltTatWi80d!",
        "123456"
      ],
      correctAnswer: "IltTatWi80d!"
    },
    {
      question: "What should you do if you leave your laptop or computer unattended at work?",
      options: [
        "Lock the device",
        "Put the device in a secure, locked container",
        "Never leave the device unattended, take it with you",
        
      ],
      correctAnswer: "Lock the device"
    },
    {
      question: "What could happen if work devices are not secured properly?",
      options: [
        "The devices will operate faster",
        "Data breaches could occur, leading to legal penalties",
        "Employees will have more flexibility in using the devices",
        "The device could be protected against physical damage"
      ],
      correctAnswer: "Data breaches could occur, leading to legal penalties"
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map(question => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 7) {
      // Update the user's progress in Firebase
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course3.module3_quiz1': true, // Update Firestore to mark the quiz of module 3 in course 3 as completed
          });
          console.log('User progress updated.');
          setShowStar(true); // Trigger the star animation
        }
      } catch (error) {
        console.error('Error updating user progress:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(10).fill(null));
    setFeedback(Array(10).fill(null));
    setCorrectAnswers(Array(10).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false); // Reset the star animation
  };

  const goToNext = () => {
    navigate('/businessacademy/modulefour/incidents'); // Replace this with the actual link when available
  };

 
  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star"></div>} {/* Star animation */}
      <section className="module-section2">
        <h2>Securing Work Devices Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <div className="quiz-question">
                <p>{shuffledQuestions[currentQuestionIndex]?.question}</p>
                {shuffledQuestions[currentQuestionIndex]?.options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
                {feedback[currentQuestionIndex] && (
                  <div className={`feedback ${feedback[currentQuestionIndex]}`}>
                    {feedback[currentQuestionIndex] === 'correct' ? 'Correct!' : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                  </div>
                )}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button className="next-button" onClick={handleNextQuestion}>Next</button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              {score >= 7 ? (
                <p>Congratulations! You passed the quiz and have a solid understanding of securing work devices.</p>
              ) : (
                <p>You need to score at least 7 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 7 ? (
                  <button className="next-button" onClick={goToNext}>Next</button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>Retake Quiz</button>
                )}
              </div>
            </div>
          )}
        </div>
       
      </section>
    </div>
  );
};

export default SecuringWorkDevicesQuiz;
