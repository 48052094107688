import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const CollaborationTools = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      goToQuiz(); // Navigate to the quiz page
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulefive/collaboration-tools-quiz'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/businessacademy/modulefive/remote-work-security'); // Navigate to the previous page
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <div className="cybersecurity-basics-header">
        <br />
        <h2>Securing Collaboration Tools</h2>
      </div>
      <br />

      <div className="module-content">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay /> {/* Add the logo overlay */}
          <video
            ref={videoRef}
            src="/assets/videos/SecuringCollaborationToolsModuleFive.mp4"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate" // Disable specific controls
            disablePictureInPicture
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Access Control</h3> <br />
          <p>
            <ul>
              <li><strong style={{ color: 'salmon' }}>Password-Protect Meetings:</strong> Always use passwords to secure online meetings and prevent unauthorised participants from joining. This is especially important for meetings where sensitive information will be discussed.</li> <br />
              <li><strong style={{ color: 'salmon' }}>Role-Based Permissions:</strong> Set appropriate permissions for participants in online meetings. For example, limit screen sharing to the host or co-host to prevent unwanted content from being shared.</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Ensure Software is Up-to-Date</h3> <br />
          <p>
            <ul>
              <li><strong style={{ color: 'salmon' }}>Regular Updates:</strong> Keep all collaboration tools, such as Zoom, Microsoft Teams, or Slack, up-to-date with the latest security patches and software updates to protect against known vulnerabilities.</li> <br />
              <li><strong style={{ color: 'salmon' }}>Use Approved Tools:</strong> Only use company-approved collaboration tools that meet the organisation's security standards. Avoid using unapproved or non-secure tools for work-related communication.</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Data Security During Collaboration</h3> <br />
          <p>
            <ul>
              <li><strong style={{ color: 'salmon' }}>Secure File Sharing:</strong> When sharing files through collaboration tools, use encrypted services or the built-in secure sharing features to protect sensitive data.</li> <br />
              <li><strong style={{ color: 'salmon' }}>Be Mindful of Recording:</strong> If meetings are recorded, ensure that the recordings are stored securely and accessible only to those who need them. Avoid recording sensitive discussions unless absolutely necessary.</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Be Wary of Phishing Attempts</h3> <br />
          <p>
            <ul>
              <li><strong style={{ color: 'salmon' }}>Verify Links:</strong> Always verify the legitimacy of links shared during meetings or in chat messages. Cybercriminals may use collaboration tools to distribute phishing links or malware.</li> <br />
              <li><strong style={{ color: 'salmon' }}>Secure Invites:</strong> Send meeting invitations through secure channels, and avoid posting links to public forums or social media where they can be accessed by unauthorised individuals.</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="start-quiz-button-container">
        <button
          className="start-quiz-button"
          onClick={handleNextClick}
        >
          Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text">You must watch the entire video before proceeding.</p>
        )}
      </div>

      
    </div>
  );
};

export default CollaborationTools;
