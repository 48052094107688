import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          const userData = userDoc.data();

          // Ensure accountType is a string and check for the 'admin' role
          const roles = userData?.profile?.accountType?.split(',').map(role => role.trim()) || [];
          
          if (roles.includes('admin')) {
            setIsAdmin(true);
          } else {
            navigate('/403'); // Redirect non-admin users to a "403 Forbidden" page
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          navigate('/error'); // Redirect to a general error page on failure
        }
      } else {
        navigate('/login'); // Redirect unauthenticated users to login
      }
      setLoading(false);
    });

    // Cleanup the auth state listener
    return () => unsubscribe();
  }, [auth, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Optionally use a spinner or other loading UI
  }

  return isAdmin ? children : null; // Return children if admin, otherwise null (shouldn't happen due to navigation)
};

export default AdminRoute;
