import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthProvider'; // Update the import path to AuthProvider
import { firestore } from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './ThreatReports.css'; // Import the CSS file

const ThreatReports = () => {
  const { currentUser } = useAuth();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchReports = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const q = query(
            collection(firestore, 'threatReports'),
            where('managerId', '==', currentUser.uid),
            orderBy('timestamp', 'desc')
          );
          const querySnapshot = await getDocs(q);
          const reportsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setReports(reportsData);
        } catch (error) {
          console.error('Error fetching threat reports:', error);
        }
      }
    };

    fetchReports();
  }, [currentUser]);

  const handleDelete = async (reportId) => {
    const confirmed = window.confirm("Are you sure you want to delete this report?");
    if (confirmed) {
      try {
        await deleteDoc(doc(firestore, 'threatReports', reportId));
        setReports(reports.filter(report => report.id !== reportId));
      } catch (error) {
        console.error('Error deleting report:', error);
      }
    }
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className="threat-reports-container">
      <h1>Threat Reports</h1>
      <p>Most Recent First</p>
      <p>(A maximum of 200 reports can be stored, with older reports automatically replaced by newer ones.)</p> <br></br>
      {reports.length > 0 ? (
        <ul className="threat-reports-list">
          {reports.map((report, index) => (
            <li key={index} className="threat-report-item">
              <h2>{report.title}</h2>
              <p className="threat-report-description"><strong>Description:</strong> {report.description}</p>
              <p className="threat-report-type"><strong>Threat Type:</strong> {report.threatType}</p> {/* Display the threat type */}
              <p className="threat-report-email"><strong>Reported by:</strong> {report.reportedByEmail}</p>
              <p className="threat-report-timestamp"><strong>Date:</strong> {new Date(report.timestamp.toDate()).toLocaleString()}</p>
              <button
                className="delete-button"
                onClick={() => handleDelete(report.id)}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No reports available</p>
      )}
    </div>
  );
};

export default ThreatReports;
