import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import Cookies from 'js-cookie';
import CookieConsent from '../components/Cookies/CookieConsent';
import DOMPurify from 'dompurify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to browserLocalPersistence.');

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          try {
            if (user) {
              console.log('User detected:', user);
              await user.reload();

              if (user.emailVerified) {
                setIsEmailVerified(true);

                // Fetch user data from Firestore
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  console.log('Fetched user data from Firestore:', userData); // Debug log

                  // Check if accountType exists in the userData
                  const accountType = userData.accountType || 'FreeUser';
                  console.log('User accountType:', accountType);

                  // Check if consent has been provided
                  const consentHistory = userData.consentHistory;
                  if (!consentHistory || !consentHistory.necessary) {
                    setIsCookieBannerVisible(true);
                  } else {
                    setIsCookieBannerVisible(false);
                  }

                  // Sanitize the user's displayName before using it
                  const sanitizedDisplayName = DOMPurify.sanitize(user.displayName || 'Anonymous');

                  // Set the current user and profile state
                  setCurrentUser(user);
                  setProfile({
                    name: sanitizedDisplayName,
                    accountType: accountType,
                    email: userData.email,
                    ...userData, // Include other user data as needed
                  });
                } else {
                  console.warn(`No user data found in Firestore for UID: ${user.uid}`);
                }
              } else {
                setIsEmailVerified(false);
                console.warn('User email is not verified yet:', user.email);
              }
            } else {
              console.log('No user is currently logged in.');
              setCurrentUser(null);
              setProfile(null);
              setIsEmailVerified(false);
            }
          } catch (error) {
            console.error('Error during user verification process:', error);
          } finally {
            setLoading(false);
          }
        });

        return () => {
          unsubscribe();
        };
      })
      .catch((error) => {
        console.error('Error setting persistence mode:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, profile, isEmailVerified }}>
      {children}

      {isCookieBannerVisible && (
        <CookieConsent setIsCookieBannerVisible={setIsCookieBannerVisible} />
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
