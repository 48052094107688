import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SocialMediaDropDragGame.css';

const terms = [
  { id: 1, text: "Set your profile visibility to friends only", category: "good" },
  { id: 2, text: "Post your home address publicly", category: "bad" },
  { id: 3, text: "Enable two-factor authentication", category: "good" },
  { id: 4, text: "Use weak passwords", category: "bad" },
  { id: 5, text: "Verify sender's address before opening email links", category: "good" },
  { id: 6, text: "Share travel plans in real-time", category: "bad" },
  { id: 7, text: "Update privacy settings to hide personal details", category: "good" },
  { id: 8, text: "Accept friend requests from unknown people", category: "bad" },
  { id: 9, text: "Clicking on links in the comment section", category: "bad" },
  { id: 10, text: "Click on suspicious links", category: "bad" },
  { id: 11, text: "Think twice before you post", category: "good" },
  { id: 12, text: "Share sensitive personal information publicly", category: "bad" }
];

const SocialMediaDropDragGame = () => {
  const [draggedTerm, setDraggedTerm] = useState(null);
  const [sortedTerms, setSortedTerms] = useState({ good: [], bad: [] });
  const navigate = useNavigate();

  const handleDragStart = (term) => {
    setDraggedTerm(term);
  };

  const handleDrop = (category) => {
    if (draggedTerm && draggedTerm.category === category) {
      setSortedTerms((prev) => ({
        ...prev,
        [category]: [...prev[category], draggedTerm],
      }));
    }
    setDraggedTerm(null);
  };

  const resetGame = () => {
    setSortedTerms({ good: [], bad: [] });
    setDraggedTerm(null);
  };

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety-part-two'); // Replace with the actual previous page route
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page6/social-media-safety-quiz'); // Navigate to the new page
  };

  return (
    <div className="drop-drag-game-container">
      <button className="back-arrow-button" onClick={goBack}>
        ←
      </button> {/* Add the back arrow button */} <br></br>
      <h2>Social Media Safety Drag and Drop Game</h2>
      <div className="terms-container">
        {terms
          .filter((term) => !sortedTerms.good.includes(term) && !sortedTerms.bad.includes(term))
          .map((term) => (
            <div
              key={term.id}
              className="term"
              draggable
              onDragStart={() => handleDragStart(term)}
            >
              {term.text}
            </div>
          ))}
      </div>
      <div className="categories-container">
        <div
          className="category good"
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop("good")}
        >
          <h3>Good</h3>
          {sortedTerms.good.map((term) => (
            <div key={term.id} className="term good-term">
              {term.text}
            </div>
          ))}
        </div>
        <div
          className="category bad"
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop("bad")}
        >
          <h3>Bad</h3>
          {sortedTerms.bad.map((term) => (
            <div key={term.id} className="term bad-term">
              {term.text}
            </div>
          ))}
        </div>
      </div>
      {sortedTerms.good.length + sortedTerms.bad.length === terms.length && (
        <div className="game-complete">
          <p>Congratulations! You've sorted all the terms correctly.</p>
          <button onClick={goToNext}>Next</button>
        </div>
      )}
    </div>
  );
};

export default SocialMediaDropDragGame;
