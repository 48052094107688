import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation to get query params
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, deleteUser } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './signUpManager.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Cookies from 'js-cookie';

// Constants for character limits
const MAX_NAME_LENGTH = 100;
const MAX_EMAIL_LENGTH = 100;
const MAX_PASSWORD_LENGTH = 50;

// Function to sanitize user input
const sanitizeInput = (input) => {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/`/g, '&#96;');
};

// Function to validate password strength
const validatePassword = (password) => {
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return strongPasswordRegex.test(password);
};

const SignUpManagerForm = ({ setUser, setProfile }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState('');
  const [isPaymentVerified, setIsPaymentVerified] = useState(false); // To track payment verification status
  const navigate = useNavigate();
  const location = useLocation(); // Get location to access query params
  const auth = getAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to verify the Stripe payment
  const verifyPayment = async (sessionId) => {
    try {
      const response = await fetch(`/verify-payment?session_id=${sessionId}`);
      const data = await response.json();
      if (data.verified) {
        setIsPaymentVerified(true);
      } else {
        setError('Payment verification failed. Please try again.');
        setIsPaymentVerified(false);
      }
    } catch (error) {
      setError('Failed to verify payment.');
      setIsPaymentVerified(false);
    }
  };

  // Handle sign-up submission
  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!isPaymentVerified) {
      setError('You must complete the payment before signing up.');
      return;
    }

    if (!agreeToTerms) {
      setError('You must agree to the User Agreement and Privacy Policy.');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long, and include one uppercase letter, one lowercase letter, one number, and one special character.');
      return;
    }

    if (!executeRecaptcha) {
      setError('ReCAPTCHA verification failed. Please try again.');
      return;
    }

    const sanitizedEmail = sanitizeInput(email);
    const sanitizedPassword = sanitizeInput(password);
    const sanitizedName = sanitizeInput(name);

    // Save user's consent preferences to localStorage if provided
    const userConsent = Cookies.get('userConsent');
    if (userConsent) {
      localStorage.setItem('cookieConsent', userConsent);
    }

    try {
      // Create the user in Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, sanitizedEmail, sanitizedPassword);
      const user = userCredential.user;

      // Update the display name in Firebase
      await updateProfile(user, { displayName: sanitizedName });

      // Send email verification
      await sendEmailVerification(user);

      // Notify the user to check their email
      setError('A verification email has been sent to your email address. Please verify your account. You will be signed out now, please log in again after verifying your email.');

      // Store user profile in Firestore
      const signUpDate = new Date();
      const userDocRef = doc(firestore, 'users', user.uid);
      await setDoc(userDocRef, {
        profile: {
          name: user.displayName,
          email: user.email,
          accountType: 'manager',
          createdAt: signUpDate,
        },
        progress: {
          course1: {
            module1: false,
            module2: false,
          },
        },
        agreedToTerms: true,
        termsAcceptedAt: signUpDate,
      });

      setUser({ name: user.displayName });
      setProfile({ name: user.displayName, accountType: 'manager' });

      setTimeout(async () => {
        await auth.signOut();
        navigate('/login');
      }, 5000);

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('This email address is already in use. Please use a different email address or log in.');
      } else {
        setError('Failed to create an account. Please try again.');
      }
    }
  };

  // Effect to check for payment status when the component mounts
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');

    if (sessionId) {
      verifyPayment(sessionId);
    }
  }, [location.search]);

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="signup-container-Signup-Manager">
      <div className="signup-box-Signup-Manager">
        <button className="back-button-Signup-Manager" onClick={handleBackClick}>Home</button>
        <h1>Manager Sign Up</h1>
        {error && <p className="error-text-Signup-Manager">{error}</p>}
        <form onSubmit={handleSignUp}>
          <div className="input-group-Signup-Manager">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value.slice(0, MAX_NAME_LENGTH))}
              required
            />
          </div>
          <div className="input-group-Signup-Manager">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.slice(0, MAX_EMAIL_LENGTH))}
              required
            />
          </div>
          <div className="input-group-Signup-Manager">
            <label>Password</label>
            <div className="password-container-Signup-Manager">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value.slice(0, MAX_PASSWORD_LENGTH))}
                required
              />
              <i
                className="password-icon-Signup-Manager"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </i>
            </div>
          </div>

          <div className="input-group-Signup-Manager">
            <label>
              <input
                type="checkbox"
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              {' '}
              I agree to the <a href="/user-agreement" target="_blank">User Agreement</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
            </label>
          </div>
          <button type="submit" className="login-button-Signup-Manager" disabled={!agreeToTerms}>
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};

// Wrap SignUpForm with GoogleReCaptchaProvider
const SignUpManager = ({ setUser, setProfile }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf7BWEqAAAAAJQ7Sf9PVKs6tlCocwtZNf_N1_dE">
      <SignUpManagerForm setUser={setUser} setProfile={setProfile} />
    </GoogleReCaptchaProvider>
  );
};

export default SignUpManager;
