import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FinisherPageTwo.css'; // Updated path for the new CSS file

import Confetti from 'react-confetti';

const FinishingPageTwo = () => {
  const navigate = useNavigate();
  const [recycleConfetti, setRecycleConfetti] = useState(true);

  useEffect(() => {
    // Set a timer to stop the confetti recycling after 5 seconds
    const timer = setTimeout(() => {
      setRecycleConfetti(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module8/credit-profile'); 
  };

  return (
    <div className="FinishersPageTwo-introduction-container">
      <Confetti 
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={recycleConfetti} 
        numberOfPieces={2000} 
      />
     <section className="FinishersPageTwo-story-introduction">
  <br />
  <h2>🎉 Congratulations, You Completed CyberShield Intermediate! 🎉</h2>
  <div className="FinishersPageTwo-introduction-content">
    <div className="FinishersPageTwo-introduction-text">
      <p>
        You've now gained valuable knowledge and tools to protect yourself against cyber attacks and strengthen your online security. Stay vigilant and continue safeguarding your digital world.
        <br /><br />
        Now, it's time to advance to the next module: 'Business Academy: Essential Cybersecurity Workplace Skills'. Keep up the great work! 
      </p>
      <button className="FinishersPageTwo-start-button" onClick={() => window.location.href = '/'}>
        🏠 Home
      </button>
    </div>
  </div>
</section>

    </div>
  );
};

export default FinishingPageTwo;
