import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './EmailSecurity.css'; // Ensure this file exists in Page3


const EmailSecurityFour = () => {
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security-quiz'); // Navigate to the new page
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security-three'); // Navigate to the CybersecurityBasics.js page
  };

  return (
    <div className="cybersecurity-basics-container">
           <button className="back-arrow-button" onClick={goBack}>
    ←
  </button>
      <section className="module-section2">
        <section className="module-section" style={{ textAlign: 'center' }}>
              <h2>Email Security</h2>
              </section>
        <div className="module-content2">
          <div className="bordered-container2">
            <div className="task-instruction">
              <p><strong><span style={{ color: 'salmon' }}>Your task is to decide if each email is legitimate or illegitimate.</span></strong></p>
            </div>
          </div>
        </div>

        <div className="email-example-box">
          <h3>Example Email Three</h3>
          <div className="email-content">
            <p><strong>From:</strong> support@paypa1.com</p>
            <p><strong>Subject:</strong> Urgent: Your Account Has Been Suspended</p>
            <br></br>
            <p>Dear Valued Customer,</p>
            <br></br>
            <p>We regreted to inform you that your PayPal account has been temproarily suspended due to suspicious activity. To ensure the security of your account we requires you to verify your identity and update yo ur account informations.</p>
            <br></br>
            <p>Please click the link below to restore your account access</p>
            <br></br> 
            <p>
              <strong><span className="tooltip-link"><strong><span style={{ color: 'blue' }}>Cick Here</span></strong><span className="tooltip-text">http://paypa1.com</span></span></strong> to review your order details.
            </p>
            <br></br>
            <p>Failures completed verification process in 24 hours will resultings in permanent suspension of account.</p>
            <br></br>
            <p>Thank you for your prompt attention to this matter.</p>
            <br></br>
            <p>Best Regards,</p>
            <p>PayPal Security Team</p>
            <br></br>
          </div>
          <div className="answer-buttons">
            <button onClick={() => handleAnswer('legitimate')}>Legitimate</button>
            <button onClick={() => handleAnswer('illegitimate')}>Illegitimate</button>
          </div>
          {isAnswered && (
            <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
              {isCorrect ? 'Well done!' : 'Wrong answer.'}
            </div>
          )}
        </div>

        {isAnswered && (
          <div className="phishing-signs">
            <h4>Signs of a Phishing Attempt:</h4>
            <ul>
              <li><strong>Sender's Email Address: </strong> The sender's email address (support@paypa1.com) contains a slight misspelling of the official domain (paypa1 instead of paypal).</li>
              <li><strong>Urgency:</strong> The email creates a sense of urgency by threatening account suspension if action is not taken immediately.</li>
              <li><strong>Generic Greeting: </strong> The email uses a generic greeting like "Dear Valued Customer" instead of addressing the recipient by name.</li>
              <li><strong>Poor Grammar and Spelling:</strong> The email may contain subtle spelling errors or awkward phrasing that are uncommon in legitimate communications from trusted organisations.</li>
              <li><strong>Unexpected Request:</strong> The email asks for personal information and verification of identity, which is unusual and should be a red flag.</li>
            </ul>
          </div>
        )}

        <button className="next-button" onClick={goToNext}>Next</button>

       
      </section>
    </div>
  );
};

export default EmailSecurityFour;
